import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import TestRemainingTime from '../TestRemainingTime';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import NTAAnswersCountsTooltip from './NTAAnswersCountsTooltip';
import NTASectionInfo from './NTASectionInfo';
import { useNTATest } from './NTATestContext';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const NTASectionTabs = ({ handleTabClick, user = STUDENT_USER_TYPE, refreshKey = 0, onRefreshClick = null }) => {

    const [activePopupId, setActivePopupId] = useState(null);
    const containerRef = useRef(null);
    const eachTabRefs = useRef({});
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const tabsRef = useRef(null);
    const { t } = useTranslation();

    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        getSectionName,
        testCreateType
    } = useNTATest();

    const togglePopup = (testSectionId) => {
        if (activePopupId === testSectionId) {
            return;
        }
        setActivePopupId(testSectionId);
    }

    const scrollTabs = (direction) => {
        if (tabsRef.current) {
            const { current } = tabsRef;
            const scrollAmount = direction === 'left' ? -current.offsetWidth / 3 : current.offsetWidth / 3;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (activePopupId === null) {
            return;
        }
        const targetRef = eachTabRefs.current[activePopupId];
        if (targetRef) {
            const rect = targetRef.getBoundingClientRect();
            setPosition({ top: Math.max(0, rect.top), left: Math.max(rect.left, 0) });
        }
    }, [activePopupId]);

    return (
        <>
            {activePopupId !== null && (
                <NTAAnswersCountsTooltip answerData={null} sectionId={activePopupId} sectionName={getSectionName(testSections.find(testSection => testSection.id === activePopupId))} style={{
                    position: 'absolute',
                    top: position.top,
                    left: position.left,
                }} />)}

            <div className="flex flex-col flex-1 justify-between overflow-x-auto roboto-medium">
                <div className="w-full flex flex-col justify-end gap-2 roboto-medium flex">

                    <div className="w-full flex justify-between items-center pt-1 pl-2">
                        <h3 className="text-lg roboto-bold font-bold">Sections</h3>

                        <div className="w-40 flex justify-start items-center gap-2">
                            {testStatus === "STARTED" && <span className="roboto font-bold">{t('timeLeft')}: </span>}
                            <TestRemainingTime
                                id={testData.id}
                                testData={testData}
                                testStatus={testStatus}
                                setTestStatus={setTestStatus}
                                user={user}
                                refreshKey={refreshKey}
                                timerType="NTA"
                                testCreateType={testCreateType} />
                        </div>
                    </div>

                    {(testData !== null && testSections !== null && (testSections.length === 0 || activeSection !== null)) ?
                        (<div className="w-full relative flex justify-center items-center border border-gray-300 border-solid">

                            <button className="absolute left-0 flex justify-center items-center p-1 hover:text-gray-700 cursor-pointer" onClick={() => scrollTabs('left')}>
                                <ChevronLeftIcon className="w-5 h-5" />
                            </button>

                            <div className="mx-6 flex flex-1 overflow-x-auto scrollbar-hide bg-gray-100 text-gray-700" ref={tabsRef}>
                                {testSections.map((testSection, index) => (
                                    <button className={activeSection.id === testSection.id ? "flex justify-center items-center gap-1 border border-gray-300 border-solid px-2 py-1 whitespace-nowrap bg-[var(--PrimaryColor)] text-white font-semibold"
                                        : "flex justify-center items-center gap-1 border border-gray-300 border-solid bg-transparent px-2 py-1 whitespace-nowrap hover:bg-gray-100 hover:font-semibold"}
                                        key={testSection.id} ref={(el) => (eachTabRefs.current[testSection.id] = el)} >
                                        <span onClick={() => handleTabClick(testSection.id)}>{getSectionName(testSection)}</span>
                                        <span className="instruction_icon" onMouseEnter={() => togglePopup(testSection.id)} onMouseLeave={() => togglePopup(null)}>
                                            {activePopupId === testSection.id &&
                                                <NTAAnswersCountsTooltip answerData={null} sectionId={testSection.id} sectionName={getSectionName(testSection)} />}
                                        </span>
                                    </button>
                                ))}
                            </div>

                            <button className="absolute right-0 flex justify-center items-center p-1 hover:text-gray-700 cursor-pointer" onClick={() => scrollTabs('right')}>
                                <ChevronRightIcon className="w-5 h-5" />
                            </button>

                        </div>) : (
                            <div className="w-full relative flex justify-center items-center border border-gray-300 border-solid">
                                {testStatus === "NOT STARTED" ? (
                                    <span className="text-lg text-red-700 p-4 font-bold roboto">{t('testHasNotStartedYet')}</span>
                                ) : (
                                    <span className="text-lg text-red-700 p-4 font-bold roboto">{t('thereAreNoSectionsToShowInThisTest')}</span>
                                )}
                            </div>
                        )}
                </div>

                {testData !== null && testSections !== null && activeSection !== null &&

                    <NTASectionInfo
                        activeSection={activeSection}
                        user={user}
                        questionsForSection={testQuestions.filter(testQuestion => testQuestion.test_section_id === activeSection.id)}
                        paragraphsForSection={testParagraphs.filter(testParagraph => testParagraph.test_section_id === activeSection.id)}
                    />
                }
                {/* <div className="w-full flex justify-center items-center p-2 bg-[var(--PrimaryColor)]">
                    <div className="w-full flex justify-center items-center">
                        <span className="text-lg roboto-bold font-bold">Language</span>
                    </div>
                </div> */}

                <div className="w-full h-3 bg-[var(--PrimaryColor)]"></div>

            </div>
        </>
    );
}


export default NTASectionTabs;
