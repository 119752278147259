import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SidebarLogo from "../../../Assets/Test.png";
import msgImg from "../../../Assets/TestDevice.png";
import StudentSelectTestPageSidebarItems from "./Sidebar/SidebarItems";

import StudentTestTable from "./StudentTestTable";
import StudentPracticeTestTable from "./StudentPracticeTestTable";
import StudentVirtualTestTable from "./StudentVirtualTestTable";
import { STUDENT_USER_TYPE } from "../../../services/UserService";
import PageHeader from "../../Common/Tailwind/Page/PageHeader";
import PageSidebar from "../../Common/Tailwind/Page/PageSidebar";
import Spinner from "../../Common/Tailwind/Spinner";
import MessagePage from "../../Common/Tailwind/Page/MessagePage";

const StudentSelectTestPage = ({ pathTab = null, user = STUDENT_USER_TYPE }) => {

    const [selectedTab, setSelectedTab] = useState(pathTab === null ? "official" : pathTab);
    const [isTabletView, setIsTabletView] = useState(window.innerWidth > 760);

    const { t } = useTranslation();

    console.log(pathTab, selectedTab)

    const renderMain = () => {

        return (
            <main className="w-screen min-h-screen md:py-2 flex flex-col">
                <div class="flex flex-1 flex-col py-4 mt-4 m:justify-center dark:border-gray-700">
                    {isTabletView ? (
                        <>
                            {(selectedTab === "official") && (
                                <StudentTestTable />
                            )}

                            {(selectedTab === "virtual") && (
                                <StudentVirtualTestTable />
                            )}

                            {(selectedTab === "practice") && (
                                <StudentPracticeTestTable />
                            )}
                        </>
                    ) : (
                        <MessagePage
                            imgsrc={msgImg}
                            title={t('pleaseOpenTestInPCOrTablet')}
                            message1={""}
                            message2={""}
                            message3={""}
                        />
                    )}
                </div>
            </main>
        )
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 760) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <div id="main-page-root" className="relative flex flex-col flex-1 w-full h-full items-center gap-2 sm:gap-3 md:gap-4 text-[var(--textColor)]">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t("perfectYourTechniquesByPracticingWithJEETA")} /> */}

            {/* Page Main */}
            <div className="flex flex-col md:flex-row w-full bg-white overflow-hidden">

                {user === STUDENT_USER_TYPE && (
                    <PageSidebar
                        sidebarLogo={SidebarLogo}
                        sidebarTitle="Tests"
                        sidebarItems={StudentSelectTestPageSidebarItems}
                        user={user}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab} />
                )}

                {/* Main */}
                {renderMain()}
            </div>

        </div>
    )
};

export default StudentSelectTestPage;