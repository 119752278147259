import { useTranslation } from 'react-i18next';
import Latex from 'react-latex';

import { STUDENT_USER_TYPE } from "../../../services/UserService";
import { LockOpenIcon, LockClosedIcon } from '@heroicons/react/24/solid';

const RecordedVideoCard = ({ recordedVideoData, onCardClick, user = STUDENT_USER_TYPE }) => {

    const subjectName = recordedVideoData.topics.length > 0 ? recordedVideoData.topics[0].topic.subject.subject_name : '';
    const approvalStatus = recordedVideoData.approval_status === "approve" ? "APPROVED" : "DENIED";
    const { t } = useTranslation();

    return (

        <div className="group relative border-r border-b border-gray-200 p-4 flex flex-col justify-start items-center gap-1 cursor-pointer"
            // className={`recorded-video-card ${subjectName}`} 
            onClick={() => onCardClick(recordedVideoData)}>

            {/* background */}
            <div className={`absolute top-0 left-0 w-full h-full 
                ${approvalStatus === "APPROVED" ? "bg-white/30 z-0" : "bg-black/40 z-2"}`}></div>

            {/* Displaying Video Info */}
            <div className="relative w-full flex justify-start items-center gap-1 p-2">
                {subjectName && (
                    <span className={`${subjectName === "Physics" ? "bg-blue-500"
                        : subjectName === "Chemistry" ? "bg-red-500"
                            : subjectName === "Mathematics" ? "bg-green-500"
                                : "bg-purple-500"} text-white text-xs font-medium rounded-lg px-2 py-1`}>
                        {t(`${subjectName}`)}
                    </span>
                )}

                <div className="absolute top-2 right-2" onClick={(e) => {
                    e.stopPropagation(); // Prevents the event from bubbling up to the card
                }}>
                    {approvalStatus === "APPROVED" ? (
                        <LockOpenIcon className="w-6 h-6 text-white bg-green-700 rounded-lg p-1" />
                    ) : approvalStatus === "DENIED" ? (
                        <LockClosedIcon className="w-6 h-6 text-white bg-red-700 rounded-lg p-1" />
                    ) : (
                        <LockClosedIcon className="w-6 h-6 text-white bg-blue-700 rounded-lg p-1" />
                    )}
                </div>
            </div >

            {/* Displaying the Video Image if available */}
            {recordedVideoData?.thumbnail_url && (
                <img
                    src={recordedVideoData.thumbnail_url}
                    alt="Video related"
                    // onLoad={handleImageLoad}
                    className="mt-2 aspect-square rounded-lg bg-green-50 object-cover group-hover:opacity-75"
                />
            )}

            {/* Displaying the Video Title */}
            <div className="mt-1 text-sm text-gray-500">
                <Latex>
                    {recordedVideoData.title || ""}
                </Latex>
            </div>

        </div >
    )

}

export default RecordedVideoCard;