import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { securedFetchReadingMaterials } from '../../../services/ReadingMaterialService';
import ReadingMaterialCard from './ReadingMaterialCard';
import ReadingMaterialCardListFilter from './ReadingMaterialCardListFilter';
import ReadingMaterialView from '../ReadingMaterialView/ReadingMaterialView';
import Spinner from '../../Common/Tailwind/Spinner';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

const ReadingMaterialCardList = ({ syllabusFilter = null, user = ADMIN_USER_TYPE, page = 1, setPage }) => {

    const [readingMaterials, setReadingMaterials] = useState([]);
    const [totalReadingMaterialCount, setTotalReadingMaterialCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isPdfViewOpen, setPdfViewOpen] = useState(true);
    const [pdfViewData, setPdfViewData] = useState(null);
    const navigate = useNavigate();

    const makeFilter = (currentPage, externalFilter = null) => {
        const currentSearchFilter = externalFilter ? externalFilter : syllabusFilter;
        const filter = { skip: (currentPage - 1) * 12, limit: 12 }
        if (!currentSearchFilter) {
            return filter;
        }
        if (currentSearchFilter.selectedTopics && currentSearchFilter.selectedTopics.length > 0) {
            filter['topic_ids'] = currentSearchFilter.selectedTopics.map((item) => (item.id));
        }
        else if ((currentSearchFilter.selectedSubjects && currentSearchFilter.selectedSubjects.length > 0) ||
            (currentSearchFilter.selectedGrades && currentSearchFilter.selectedGrades.length > 0)) {
            filter['topic_ids'] = currentSearchFilter.topics.map((item) => (item.id));
        }
        if (currentSearchFilter.selectedTags && currentSearchFilter.selectedTags.length > 0) {
            filter['tag_ids'] = currentSearchFilter.selectedTags.map((item) => (item.id));
        }
        if (currentSearchFilter.selectedTypes && currentSearchFilter.selectedTypes.length > 0) {
            filter['material_types'] = currentSearchFilter.selectedTypes.map((item) => (item.id));
        }
        if (currentSearchFilter.nameSubstring && currentSearchFilter.nameSubstring.length > 0) {
            filter['material_name_substring'] = currentSearchFilter.nameSubstring;
        }
        return filter;
    }

    const renderReadingMaterialCard = (readingMaterialData) => {
        return <ReadingMaterialCard
            readingMaterialData={readingMaterialData}
            user={user}
            onCardClick={onCardClick} />;
    }

    const renderReadingMaterialCards = () => {
        return (
            <>
                {readingMaterials.map(readingMaterialData => (
                    renderReadingMaterialCard(readingMaterialData)
                ))}
            </>
        )
    }

    const fetchData = async (hasFilterChanged = false, externalFilter = null) => {
        let currentPage = page;
        if (hasFilterChanged) {
            setPage(1);
            currentPage = 1;
        }
        setLoading(true);
        const currentSearchFilter = externalFilter ? externalFilter : syllabusFilter;
        const response = await securedFetchReadingMaterials(makeFilter(currentPage, currentSearchFilter), navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        setReadingMaterials(response.data);
        setTotalReadingMaterialCount(response.count);
        setLoading(false);
    };

    const onPageChange = async (changeUnit) => {
        const numberOfPages = Math.ceil(totalReadingMaterialCount / 12);
        if (page + changeUnit < 1 || page + changeUnit > numberOfPages || changeUnit === 0) {
            return;
        }
        setPage(page + changeUnit);
        setLoading(true);
        const response = await securedFetchReadingMaterials(makeFilter(page + changeUnit), navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        setReadingMaterials(response.data);
        setTotalReadingMaterialCount(response.count);
        setLoading(false);
    }

    const displayPageButtons = () => {
        const numberOfButtonsAfterPage = Math.max(0, Math.ceil((totalReadingMaterialCount - page * 12) / 12));
        const startIndex = Math.max(page - 5, 1);
        const endIndex = Math.min(startIndex + 10, page + numberOfButtonsAfterPage);

        return Array.from({ length: endIndex - startIndex + 1 }, (_, index) => (

            <button button key={startIndex + index}
                className={startIndex + index === page ?
                    "flex items-center justify-center w-7 h-7 rounded-full border-none text-white text-sm outline-none bg-[var(--SecondaryColor)] hover:bg-[var(--PrimaryColor)]"
                    : "flex items-center justify-center w-7 h-7 rounded-full border-none text-white text-sm outline-none bg-[var(--SecondaryBkg)] hover:bg-[var(--PrimaryColor)]"}
                onClick={() => onPageChange(startIndex + index - page)} >
                {startIndex + index}
            </button>

        ));
    }

    const onCardClick = (readingMaterialData) => {
        setPdfViewData(readingMaterialData);
        setPdfViewOpen(true);
    }

    const onSubmitFilter = async (externalFilter = null) => {
        await fetchData(true, externalFilter);
    }

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, []);

    const renderCardList = () => {
        return (
            <div className="flex flex-col justify-center items-center gap-2 w-full p-2">

                <ReadingMaterialCardListFilter
                    syllabusFilter={syllabusFilter}
                    onSubmitFilter={onSubmitFilter}
                    user={user} />
                {/* searchFilter={searchFilter} setSearchFilter={setSearchFilter} onSubmitFilter={onSubmitFilter} user={user} allowedTypes={allowedTypes} hiddenFilters={hiddenFilters} refreshKey={refreshKey}  */}

                {loading ? (
                    <Spinner />
                ) : (

                    <div className="w-full flex flex-col justify-center items-center gap-2 mx-auto">

                        {/* Card List Grid */}
                        <div className="-mx-px w-full grid grid-cols-2 border border-gray-200 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
                            {readingMaterials && renderReadingMaterialCards()}
                        </div>

                        {/* Card List Button Slider */}
                        <div className="w-full flex justify-center items-center gap-2 p-2">
                            <button className="bg-transparent text-[var(--PrimaryColor)] hover:text-[var(--SecondaryColor)]"
                                onClick={async () => await onPageChange(-1)} hidden={page === 1}>
                                <ChevronLeftIcon className="w-8 h-8" />
                            </button>

                            {displayPageButtons(totalReadingMaterialCount)}

                            <button className="bg-transparent text-[var(--PrimaryColor)] hover:text-[var(--SecondaryColor)]"
                                onClick={async () => await onPageChange(1)}>
                                <ChevronRightIcon className="w-8 h-8" />
                            </button>
                        </div>
                    </div>)}

            </div>)
    }

    return (
        <>
            {renderCardList()}

            <ReadingMaterialView
                isOpen={isPdfViewOpen}
                onRequestClose={() => setPdfViewOpen(false)}
                readingMaterialData={pdfViewData}
                user={user} />
        </>
    );
};

export default ReadingMaterialCardList;