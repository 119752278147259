import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './NTA.css'

import profileImage from '../../../Assets/Profile_photo.png';

import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE, securedFetchUserById } from '../../../services/UserService';
import { navigateCallbackOptions } from "../../../services/AuthenticationService";

const NTAProfile = ({ user = STUDENT_USER_TYPE }) => {

    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const fetchedUserInfo = await securedFetchUserById(user, navigateCallbackOptions(navigate));
            setUserInfo(fetchedUserInfo);
        })();
    }, []);

    return (
        <div className="flex w-80 flex-shrink-0 gap-2 roboto-medium border border-gray-300 border-solid">
            {userInfo && userInfo.user_data && (
                <>
                    <div className="w-32 object-contain">
                        <img className="profileImg" src={profileImage} alt="profileImg" />
                    </div>

                    <div className="px-1 py-2">
                        <span className="roboto-bold font-bold">{userInfo.user_data.first_name + ' ' + userInfo.user_data.last_name}</span>
                    </div>
                </>
            )}
        </div >
    );
}

export default NTAProfile;
