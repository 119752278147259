import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import { UnauthorizedError } from "../../services/Errors";
import { securedCreateBatch } from "../../services/BatchService";
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';

import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'

import Spinner from '../Common/Tailwind/Spinner';
import Dropdown from '../Common/Tailwind/SelectMenus/Dropdown';
import { CheckIcon } from '@heroicons/react/24/outline';

const GradeBatchCreate = ({ isOpen = false, onRequestClose = null, gradesInput, gradeInput, setBatch, onBatchCreate = null, user = ADMIN_USER_TYPE }) => {

    const [batchName, setBatchName] = useState("");
    const [batchProgramName, setBatchProgramName] = useState("");
    const [batchRank, setBatchRank] = useState(1);
    const [batchStartYear, setBatchStartYear] = useState(new Date().getFullYear());
    const [batchEndYear, setBatchEndYear] = useState(new Date().getFullYear() + 1);
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        (() => {
            setGrades(gradesInput);
            setSelectedGrade(gradeInput);
        })();
    }, [gradesInput]);

    const onTextChange = (setter) => (event) => {
        setter(event.target.value);
    }

    const onNumberChange = (setter, minValue) => (event) => {
        if (event.target.value < minValue) {
            setter(minValue);
        } else {
            setter(event.target.value);
        }
    }

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection ? selection.value : null;
        if (gradeId) {
            setSelectedGrade({ id: gradeId, grade_name: selection.label });
        }
    };

    const handleSubmit = async () => {
        setIsSubmitted(true);
        const data = {
            batch_name: batchName,
            batch_program: batchProgramName,
            batch_rank: batchRank,
            batch_start_year: batchStartYear,
            batch_end_year: batchEndYear,
            batch_grade: selectedGrade.id
        }
        const response = await securedCreateBatch(data, navigateCallbackOptions(navigate));
        if (onBatchCreate) {
            await onBatchCreate({ id: response.batch_id, ...data });
        }
        setIsSubmitted(false);
        onRequestClose();
    }

    const renderBatchCreate = () => {
        return (
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                </div>
                <div className="mt-3 text-center sm:mt-5">

                    <DialogTitle as="h3" className="text-center text-xl font-semibold leading-6 text-[var(--SecondaryColor)]">
                        {t('createNewBatch')}
                    </DialogTitle>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="flex flex-col gap-4 justify-center">

                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div>
                                    <label htmlFor="batch-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                        {t('batchName')}
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="batch-name"
                                            name="batch-name"
                                            type="text"
                                            onChange={onTextChange(setBatchName)}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="rank" className="block text-sm font-semibold leading-6 text-gray-900">
                                        {t('rank')}
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="rank"
                                            name="rank"
                                            type="number"
                                            value={batchRank}
                                            onChange={onNumberChange(setBatchRank, 1)}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="batchYear" className="block text-sm font-semibold leading-6 text-gray-900">
                                        {t('startYear')}
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="batchYear"
                                            name="batchYear"
                                            type="number"
                                            value={batchStartYear}
                                            onChange={onNumberChange(setBatchStartYear, 2020)}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="rank" className="block text-sm font-semibold leading-6 text-gray-900">
                                        {t('grade')}
                                    </label>
                                    <div className="mt-2 z-20">
                                        <Dropdown
                                            list={grades}
                                            selected={selectedGrade}
                                            onSelectionChange={onSelectedGradeChange}
                                            nameField='grade_name'
                                            valueField='id'
                                        // required={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 flex justify-center">
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryBkg)]"
                                >
                                    <span>{t('createCapitalized')}</span>
                                    <PlusCircleIcon className="h-5 w-5" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        )
    }

    return (

        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        transition
                        className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">{t('close')}</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        {isSubmitted ?
                            (
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {t('newBatch')}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {t('newBatchCreatedSuffix')}
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                            : (
                                renderBatchCreate()
                            )}

                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default GradeBatchCreate;