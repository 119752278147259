import React from 'react';

import { TrashIcon } from '@heroicons/react/24/outline'

const VideoPreview = ({ videos, setVideos, videoFileNames }) => {

    if (videos.length < 1) return;

    const onDelete = (video) => (event) => {
        setVideos(videos.filter((e) => e !== video));
    }
    return (
        <ul className="grid gap-x-4 gap-y-8 grid-cols-[repeat(auto-fit,minmax(150px,1fr))]  xl:gap-x-8" value={videos} >
            {videos &&
                videos.map((video, Idx) => {
                    return (
                        <li key={video} className="relative">
                            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                                <button className="absolute inset-0 opacity-0 group-hover:opacity-100 focus:outline-none flex items-center justify-center" onClick={onDelete(video)}>
                                    <TrashIcon className="h-7 w-7 text-[var(--PrimaryColor)]" />
                                </button>
                            </div>

                            {videoFileNames.length > 0 ? (
                                <p>{Idx + 1} {videoFileNames[Idx].name}</p>
                            ) : (
                                <p>{Idx + 1}</p>
                            )}

                        </li>
                    );
                })}
        </ul>
    )
}

export default VideoPreview;
