import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Latex from 'react-latex';
import { Tooltip } from 'react-tooltip'

import Spinner from '../../Common/Tailwind/Spinner';
import { hasQuestionDeleteAuthority, hasQuestionEditAuthority, hasQuestionResponses, hasQuestionMarkFeature, securedDeleteQuestionById } from '../../../services/QuestionService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { FaRegCheckCircle } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im"
import { IoMdStar, IoMdStarOutline } from "react-icons/io";
import { MdOutlineRadioButtonUnchecked, MdPlaylistAdd, MdPlaylistAddCheck } from "react-icons/md";
import { securedUpdateQuestionMetadatas } from '../../../services/UserQuestionService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { getHmacUrl, replaceImagesInObject, truncateString } from '../../../services/CommonService';

const QuestionCard = ({ questionData, displayAnswer = false, testId = null, onQuestionDelete = null, handleQuestionCardClick, user = ADMIN_USER_TYPE }) => {

    const [questionCardData, setQuestionCardData] = useState(questionData);
    const [showPopup, setShowPopup] = useState(false);
    const [isEditQuestionOpen, setEditQuestionOpen] = useState(false);
    const [isWideImage, setWideImage] = useState(null);

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            setQuestionCardData({ ...questionData, images: await replaceImagesInObject(questionData.images, navigate) });
        })();
    }, [questionData]);

    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        const ratio = naturalWidth / naturalHeight;
        if (ratio > 1) {
            setWideImage("wide");
        } else {
            setWideImage("long");
        }
    }

    // const showDifficulty = () => {
    //     if (questionCardData.question_difficulty === null || questionCardData.paragraph_difficulty === null) return '';

    //     const difficulty = questionCardData.question_difficulty.tolpperCase() || questionCardData.paragraph_difficulty[0].toUpperCase();

    //     // Use switch to determine the difficulty level
    //     switch (difficulty) {
    //         case 'E':
    //             return 'easy';
    //         case 'M':
    //             return 'medium';
    //         case 'H':
    //             return 'hard';
    //         default:
    //             return ''; // Handle unexpected values
    //     }
    // };

    const showSubject = () => {
        if (questionCardData.topics.length === 0) return '';

        return questionCardData.topics[0].topic.subject.subject_name.toLowerCase();
    };

    const onQuestionDeleteClick = async () => {

        const response = await securedDeleteQuestionById(questionCardData.type, questionCardData.id);
        if (response === null) {
            return;
        }
        if (onQuestionDelete) {
            onQuestionDelete();
        }

        setShowPopup(false);
    }

    const addQuestionIdToMetadata = (baseData, idToAdd) => {
        if (questionCardData.type === 'standalone') {
            return { ...baseData, question_id: idToAdd };
        }
        return { ...baseData, paragraph_id: idToAdd };
    }

    const handleMarkStar = async (event) => {
        event.stopPropagation();

        const newIsStarMarked = questionCardData.metadata.is_star_marked ? false : true;
        const updateMarkResponse = await securedUpdateQuestionMetadatas(user, questionCardData.type, addQuestionIdToMetadata({ is_star_marked: newIsStarMarked }, questionCardData.id), navigateCallbackOptions(navigate));
        if (updateMarkResponse === null) {
            return;
        }
        const newMetadata = questionCardData.metadata ? { ...questionCardData.metadata, is_star_marked: newIsStarMarked } : null;
        setQuestionCardData({ ...questionCardData, metadata: newMetadata });
    }

    const handleMarkForReview = async (event) => {
        event.stopPropagation();
        const newIsMarkedForReview = questionCardData.metadata.is_review_marked ? false : true;
        const updateMarkResponse = await securedUpdateQuestionMetadatas(user, questionCardData.type, addQuestionIdToMetadata({ is_review_marked: newIsMarkedForReview }, questionCardData.id), navigateCallbackOptions(navigate));
        if (updateMarkResponse === null) {
            return;
        }
        const newMetadata = questionCardData.metadata ? { ...questionCardData.metadata, is_review_marked: newIsMarkedForReview } : null;
        setQuestionCardData({ ...questionCardData, metadata: newMetadata });
    }

    const renderCorrectQuestions = () => {
        if (!hasQuestionResponses(user)) {
            return;
        }

        if (questionCardData.type === "standalone") {
            return (
                <div className="answer-marks flex" >
                    {questionCardData.is_correct === null ? (
                        <></>
                    ) : (
                        questionCardData.is_correct === 1 ? (
                            <div className="mark flex" id="correct">
                                <FaRegCheckCircle className="icon" />
                            </div>
                        ) : (
                            <div className="mark flex" id="incorrect">
                                <ImCancelCircle className="icon" />
                            </div>
                        )
                    )}
                </div >
            );
        }

        return (
            <div className="answer-marks flex">
                {(questionCardData.number_of_questions_total != null && questionCardData.number_of_questions_correct != null) && (
                    <>
                        <div className="mark flex" id="correct"
                            data-tooltip-id="tooltip"
                            data-tooltip-content={`Correctly Answered: ${questionCardData.number_of_questions_correct}`}
                            data-tooltip-place="right">
                            <FaRegCheckCircle className="icon" />
                            <p>{questionCardData.number_of_questions_correct}</p>
                        </div>
                        <div className="mark flex" id="incorrect"
                            data-tooltip-id="tooltip"
                            data-tooltip-content={`Incorrectly Answered: ${questionCardData.number_of_questions_incorrect}`}
                            data-tooltip-place="right">
                            <ImCancelCircle className="icon" />
                            <p>{questionCardData.number_of_questions_incorrect}</p>
                        </div>
                        <div className="mark flex" id="unanswered"
                            data-tooltip-id="tooltip"
                            data-tooltip-content={`Unanswered: ${questionCardData.number_of_questions_total - questionCardData.number_of_questions_incorrect - questionCardData.number_of_questions_correct}`}
                            data-tooltip-place="right">
                            <MdOutlineRadioButtonUnchecked className="icon" />
                            <p>{questionCardData.number_of_questions_total - questionCardData.number_of_questions_incorrect - questionCardData.number_of_questions_correct}</p>
                        </div>
                    </>
                )}
                <Tooltip id="tooltip" />
            </div>
        );
    }

    const getQuestionTextKey = () => {
        if (questionCardData.type === 'standalone') {
            return 'question';
        }
        return 'paragraph';
    }

    if (!questionCardData) {
        return <Spinner />;
    }

    return (
        <div className="group relative border-r border-b border-gray-200 p-4 flex flex-col justify-start items-center gap-1 cursor-pointer"
            onClick={handleQuestionCardClick}>

            {/* Displaying Question Info */}
            <div className="w-full flex justify-start items-center gap-1 p-2">
                {/* Displaying Question ID */}
                {questionCardData.topics.length > 0 && (
                    <span className={`${questionCardData.topics[0].topic.subject.subject_name === "Physics" ? "bg-blue-500"
                        : questionCardData.topics[0].topic.subject.subject_name === "Chemistry" ? "bg-red-500"
                            : questionCardData.topics[0].topic.subject.subject_name === "Mathematics" ? "bg-green-500"
                                : "bg-purple-500"} text-white text-xs font-medium rounded-lg px-2 py-1`}>
                        {t(`${showSubject()}Abbreviation`)}{questionCardData.id}
                    </span>
                )}

                {/* Displaying Difficulty */}
                {questionCardData.question_difficulty && (
                    <span className={`${questionCardData.question_difficulty === "easy" ? "bg-green-400"
                        : questionCardData.question_difficulty === "medium" ? "bg-orange-400"
                            : questionCardData.question_difficulty === "hard" ? "bg-red-400"
                                : "bg-gray-400"} text-white text-xs font-medium rounded-lg px-2 py-1`}>
                        {t(`${questionCardData.question_difficulty.toLowerCase()}`)}
                    </span>
                )}
                {questionCardData.paragraph_difficulty && (
                    <span className={`${questionCardData.paragraph_difficulty === "easy" ? "bg-green-400"
                        : questionCardData.paragraph_difficulty === "medium" ? "bg-orange-400"
                            : questionCardData.paragraph_difficulty === "hard" ? "bg-red-400"
                                : "bg-gray-400"} text-white text-xs font-medium rounded-lg px-2 py-1`}>
                        {t(`${questionCardData.paragraph_difficulty.toLowerCase()}`)}
                    </span>
                )}

                {/* Displaying Saved */}
                {testId === null && hasQuestionMarkFeature(user) && (
                    <div className="flex justify-center items-center gap-1">
                        <>
                            <button className="saved-btn" id="star-btn" onClick={handleMarkStar}>
                                {questionCardData.metadata && questionCardData.metadata.is_star_marked ? (
                                    <IoMdStar className="w-4 h-4" />
                                ) : (
                                    <IoMdStarOutline className="w-4 h-4" />
                                )}
                            </button>

                            <button className="saved-btn" id="review-btn" onClick={handleMarkForReview}>
                                {questionCardData.metadata && questionCardData.metadata.is_review_marked ? (
                                    <MdPlaylistAddCheck className="w-4 h-4" />
                                ) : (
                                    <MdPlaylistAdd className="w-4 h-4" />
                                )}
                            </button>
                        </>
                    </div>
                )}
            </div>

            {/* Displaying the Question Text */}
            {questionCardData[`${getQuestionTextKey()}_text`] && (
                <div className="mt-1 text-sm text-gray-500">
                    <Latex>
                        {questionCardData[`${getQuestionTextKey()}_text`]}
                    </Latex>
                </div>
            )}

            {/* Displaying the Question Image if available */}
            {questionCardData.images.length > 0 && (
                <img
                    src={questionCardData.images[0].image.url}
                    alt="Question related"
                    // onLoad={handleImageLoad}
                    className="mt-2 aspect-square rounded-lg bg-green-50 object-cover group-hover:opacity-75"
                />
            )}

            {/* Displaying Topics */}
            {(questionCardData.topics.length > 0 || questionCardData.tags.length > 0) && (
                <div className="mt-2 flex flex-col justify-center items-center w-full gap-1">

                    <div className="flex justify-center items-center w-full gap-1 overflow-x-auto scrollbar-hide">
                        {questionCardData.topics.map(topic => (
                            <div key={topic.id} className="flex justify-center bg-gray-100 rounded-lg px-1 items-center text-sm font-semibold leading-6 text-blue-500 whitespace-nowrap" >
                                {truncateString(topic.topic.topic_name)}
                            </div>
                        ))}
                    </div>

                    {questionCardData.tags.filter(tag => !tag.tag.is_hidden).map(tag => (
                        <div key={tag.id} className="flex justify-center items-center text-xs font-base leading-6 text-gray-500 whitespace-nowrap" >
                            {truncateString(tag.tag.tag_name)}
                        </div>
                    ))}
                </div>
            )}

            {/* Displaying Answers */}
            {displayAnswer && (
                <div className="answers">
                    {questionCardData.answers.map(answer => (
                        <div key={answer.id} className={`answer ${answer.is_correct ? 'correct' : ''}`}>
                            {answer.answer.answer_text}
                        </div>
                    ))}
                </div>
            )}

            {/* {testId === null && hasQuestionDeleteAuthority(user) && (
                <>
                    <button className="delete-btn" onClick={(event) => {
                        event.stopPropagation();
                        setShowPopup(true);
                    }}>
                        <RiDeleteBin5Line className="icon" />
                    </button>
                </>
            )} */}

            {renderCorrectQuestions()}

        </div>
    );
};

export default QuestionCard;
