import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import { STUDENT_USER_TYPE } from "../../../services/UserService";
import ReadingMaterialPdfViewer from './ReadingMaterialPdfViewer';
import { getHmacUrl } from '../../../services/CommonService';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ReadingMaterialView = ({ isOpen, onRequestClose, readingMaterialData, user = STUDENT_USER_TYPE }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {

        if (!isOpen || !readingMaterialData) {
            return;
        }
        (async () => {
            readingMaterialData.pdf.url = await getHmacUrl(readingMaterialData.pdf.url, navigate);
        })();
    }, [isOpen]);

    if (readingMaterialData === null) {
        return;
    }

    return (
        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-20">

            {/* <Modal overlayClassName="customModal"         className="reading-material-view"         isOpen={isOpen} onRequestClose={onRequestClose} > */}

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        transition
                        className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg md:max-w-4xl lg:max-w-6xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
                            >
                                <span className="sr-only">{t('closeCapitalized')}</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        <ReadingMaterialPdfViewer readingMaterialData={readingMaterialData} user={user} />

                    </DialogPanel>
                </div>
            </div>
            {/* </Modal> */}
        </Dialog>
    )

}

export default ReadingMaterialView;