import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import './NTA.css'
import '../Test.css'

import { STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { TEST_CREATE_TYPE_BASIC, TEST_CREATE_TYPE_PRACTICE, TEST_CREATE_TYPE_VIRTUAL, canShowSectionMarks, fetchRemainingTime, securedAddTestQuestions, securedCreateTestSectionForTestId, securedDeleteTestQuestion, securedDeleteTestSection, securedDeleteTestSubject, securedFetchRemainingTime, securedFetchStudentTestAttempt, securedFetchTestById, securedFetchTestSectionById, securedFetchTestSectionsByTestId, securedFetchTestSubjectById, securedMarkStudentTestAttempt, securedSubmitStudentTestAttempt, securedUpdateTestSectionOrder, securedUpdateTestSubjectOrder, startTest } from '../../../services/TestService';
import { securedFetchQuestionById, securedFetchQuestions, securedFetchQuestionsByIds } from '../../../services/QuestionService';
import { securedFetchSubjects, subjectAbbreviationMap } from '../../../services/SyllabusService';
import { UnauthorizedError } from '../../../services/Errors';
import { AiFillInfoCircle, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import NTAInstructions from './NTAInstructions';
import NTAFullScreenWarning from './NTAFullScreenWarning';
import NTATestHeader from './NTATestHeader';
import NTAProfile from './NTAProfile';
import NTASectionView from './NTASectionView';
import NTASectionTabs from './NTASectionTabs';
import { NTATestContext } from './NTATestContext';
import NTASubmitWarning from './NTASubmitWarning';
import Alert from '../../Common/Alert';
import { BsCloudUpload } from 'react-icons/bs';
import { RiArrowGoBackFill } from 'react-icons/ri';


import { securedFetchTestStudentQuestionActionsByFilter } from '../../../services/TestStudentAction';
import { checkResponseCorrectness } from '../../../services/UserQuestionService';
import { cacheImages } from '../../../services/CommonService';
import Spinner from '../../Common/Tailwind/Spinner';
import Notification from '../../Common/Tailwind/Notification/Notification';

const NTATestView = ({ id, user = STUDENT_USER_TYPE, testCreateType = TEST_CREATE_TYPE_BASIC }) => {

    const [testData, setTestData] = useState(null);
    const [testStatus, setTestStatus] = useState(null);
    const [testSubjects, setTestSubjects] = useState([]);
    const [testSections, setTestSections] = useState([]);
    const [testQuestions, setTestQuestions] = useState([]);
    const [testParagraphs, setTestParagraphs] = useState([]);
    const [questionStyleById, setQuestionStyleById] = useState([]);
    const [testType, setTestType] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isInstructionPopupOpen, setInstructionPopupOpen] = useState(false);
    const [isFullScreenWarningOpen, setFullScreenWarningOpen] = useState(false);
    const [hasAgreedInstructions, setHasAgreedInstructions] = useState(false);
    const [startQuestionIndex, setStartQuestionIndex] = useState(0);
    const [isSubmittedTest, setIsSubmittedTest] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(null);
    const [isFinishedTestAlertOpen, setFinishedTestAlertOpen] = useState(false);
    const [isNotStartedTestAlertOpen, setNotStartedTestAlertOpen] = useState(false);
    const [isTestSubmittedAlertOpen, setTestSubmittedAlertOpen] = useState(false);
    const [isEvaluatedNow, setEvaluatedNow] = useState(false);

    const containerRef = useRef(null);

    const tabsRef = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const getSectionName = (testSection) => {
        const subjectName = subjects.find(subject => subject.id === testSection.subject_id).subject_name;
        return `${t(subjectAbbreviationMap[subjectName])} ${t('SEC')}${testSection.position_in_subject}`;
    }

    const value = {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        questionIndex,
        setQuestionIndex,
        getSectionName,
        testCreateType
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', onFullScreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', onFullScreenChange);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!isEvaluatedNow) {
                return;
            }
            await fetchAndSetResponses(testQuestions);
        })();
    }, [isEvaluatedNow])

    const handleFullScreenEnter = () => {
        if (containerRef.current) {
            if (containerRef.current.requestFullscreen) {
                containerRef.current.requestFullscreen();
            } else if (containerRef.current.mozRequestFullScreen) { /* Firefox */
                containerRef.current.mozRequestFullScreen();
            } else if (containerRef.current.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                containerRef.current.webkitRequestFullscreen();
            } else if (containerRef.current.msRequestFullscreen) { /* IE/Edge */
                containerRef.current.msRequestFullscreen();
            }
        }
    };

    const handleFullScreenExit = async () => {
        if (containerRef.current) {
            if (document.fullscreenElement) {
                await document.exitFullscreen();
            }
        }
    };

    const handleFullScreenOut = () => {
        if (isSubmittedTest || testStatus !== 'STARTED') {
            return;
        }
        setFullScreenWarningOpen(true);
    }

    const onFullScreenChange = () => {
        if (!document.fullscreenElement) {
            handleFullScreenOut();
        }
    }

    const handleReEnterTestFullScreen = () => {
        handleFullScreenEnter();
        setFullScreenWarningOpen(false);
    }

    const handleSubmitTest = async () => {
        const response = await securedSubmitStudentTestAttempt(testCreateType, id, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        setIsSubmittedTest(true);
        navigate(-1);
    }

    const canFetchCorrectAnswer = (remainingTime, testData) => {
        return remainingTime < 0 || ((testCreateType === TEST_CREATE_TYPE_VIRTUAL && testData.virtual_test_submission_timestamp != null) || (testCreateType === TEST_CREATE_TYPE_PRACTICE && testData.practice_test_submission_timestamp != null));
    }

    const handleAgreeInstructions = async () => {
        if (testCreateType === TEST_CREATE_TYPE_BASIC) {
            const response = await securedMarkStudentTestAttempt(id, navigateCallbackOptions(navigate));
            if (response === null) {
                return;
            }
        }
        setHasAgreedInstructions(true);
        setInstructionPopupOpen(false);
        await fetchAndSetResponses(testQuestions);
        handleFullScreenEnter();
    }

    const handleCloseInstructionPage = () => {
        setInstructionPopupOpen(false);

        navigate(-1);
    }

    const fetchAndSetTest = async () => {
        setIsLoading(true);
        let testResponse = await securedFetchTestById(testCreateType, id, navigateCallbackOptions(navigate));
        if (testResponse === null) {
            return;
        }
        if (testCreateType === TEST_CREATE_TYPE_VIRTUAL) {
            testResponse = { ...testResponse.test, student_id: testResponse.student_id, id: testResponse.id, virtual_test_submission_timestamp: testResponse.virtual_test_submission_timestamp, virtual_test_duration_minutes: testResponse.virtual_test_duration_minutes }
        }
        setTestData(testResponse);
        const remainingTimeResponse = await securedFetchRemainingTime(testCreateType, id, navigateCallbackOptions(navigate));
        if (remainingTimeResponse === null) {
            setIsLoading(false);
            return;
        }
        if (remainingTimeResponse.time_remaining === null) {
            clearStates();
            setIsLoading(false);
            return;
        }

        const testQuestionsData = await setStatesFromResponse(testResponse, canFetchCorrectAnswer(remainingTimeResponse.time_remaining, testResponse));
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
            if (subjectsResponse === null) {
                return;
            }
            setSubjects(subjectsResponse.data);
            await fetchAndSetTest();
        })();
    }, [id, refreshKey]);


    useEffect(() => {
        (async () => {
            document.addEventListener('fullscreenchange', onFullScreenChange);
            if (testStatus === 'STARTED') {
                setIsLoading(true);
                if (testCreateType === TEST_CREATE_TYPE_BASIC) {
                    const testAttendanceResponse = await securedFetchStudentTestAttempt(id, navigateCallbackOptions(navigate));
                    if (testAttendanceResponse !== null && testAttendanceResponse.test_submission_timestamp) {
                        setIsSubmittedTest(true);
                        setIsLoading(false);
                        return;
                    }
                    if (testAttendanceResponse === null) {
                        setIsLoading(false);
                        return;
                    }
                    setInstructionPopupOpen(true);
                    setIsLoading(false);
                } else {
                    const testData = await securedFetchTestById(testCreateType, id, navigateCallbackOptions(navigate));
                    if ((testCreateType === TEST_CREATE_TYPE_VIRTUAL && testData.virtual_test_submission_timestamp) || (testCreateType === TEST_CREATE_TYPE_PRACTICE && testData.practice_test_submission_timestamp)) {
                        setIsSubmittedTest(true);
                        setIsLoading(false);
                        return;
                    }
                    setInstructionPopupOpen(true);
                    setIsLoading(false);
                }
            }
            if (testStatus === 'FINISHED') {
                handleFullScreenExit();
                setFinishedTestAlertOpen(true);
                await fetchAndSetResponses(testQuestions);
            }
            return () => {
                document.removeEventListener('fullscreenchange', onFullScreenChange);
            };
        })();
    }, [testStatus]);

    useEffect(() => {
        if (!testSections || testSections.length === 0) {
            return;
        }
        setActiveSection(testSections[0]);
    }, [testSections]);

    const clearStates = () => {
        setTestSubjects([]);
        setTestSections([]);
        setTestQuestions([]);
        setTestParagraphs([]);
    }

    const handleTabClick = (testSectionId, index = 0) => {
        const currentTestSection = testSections.find(testSection => testSection.id === testSectionId) || null;
        setQuestionIndex(null);
        setActiveSection(currentTestSection, () => setQuestionIndex(index));
    };

    const setStatesFromResponse = async (testResponse, fetchCorrectAnswers = false) => {
        const testSubjectsFromResponse = [...testResponse.subjects];
        let testSectionsFromResponse = [];
        testSubjectsFromResponse.forEach((testSubject) => {
            testSectionsFromResponse = [...testSectionsFromResponse, ...testSubject.sections];
        })
        let testQuestionsFromResponse = [];
        let testParagraphsFromResponse = [];

        testSectionsFromResponse.forEach((testSection) => {
            testQuestionsFromResponse = [...testQuestionsFromResponse, ...testSection.test_questions];
            testParagraphsFromResponse = [...testParagraphsFromResponse, ...testSection.test_paragraphs];
        })
        const testQuestionsData = await setTestQuestionsAndParagraphs(testQuestionsFromResponse, testParagraphsFromResponse, fetchCorrectAnswers);
        testResponse.subjects = null;
        testSubjectsFromResponse.forEach((testSubject) => { testSubject.sections = null });
        testSectionsFromResponse.forEach((testSection) => {
            testSection.test_questions = null;
            testSection.test_paragraphs = null;
            testSection.subject_id = testSubjectsFromResponse.find((testSubject) => testSubject.id === testSection.test_subject_id).subject_id;
        });

        testSectionsFromResponse.sort((a, b) => a.subject_id > b.subject_id);

        const subjectPositions = {};

        testSectionsFromResponse.forEach((testSection) => {
            if (!subjectPositions[testSection.subject_id]) {
                subjectPositions[testSection.subject_id] = 1;
            } else {
                subjectPositions[testSection.subject_id] += 1;
            }

            testSection.position_in_subject = subjectPositions[testSection.subject_id]; // Assign the position
        });

        setTestSubjects(testSubjectsFromResponse);
        setTestSections(testSectionsFromResponse);
        cacheImages(testQuestionsData.images);
        setTestType(testResponse.test_type);
        return testQuestionsData;

    };

    const setTestQuestionsAndParagraphs = async (testQuestionsFromResponse, testParagraphsFromResponse, fetchCorrectAnswers = false) => {
        const questionIds = testQuestionsFromResponse.map((testQuestion) => (testQuestion.question_id));
        const paragraphIds = testParagraphsFromResponse.map((testParagraph) => (testParagraph.paragraph_id));
        let extraFilter = null;
        if (fetchCorrectAnswers) {
            extraFilter = { show_correct_answer: true };
        }
        const questionsResponse = await securedFetchQuestionsByIds('standalone', questionIds, extraFilter, navigateCallbackOptions(navigate));
        if (questionsResponse === null) {
            return;
        }
        const paragraphsResponse = await securedFetchQuestionsByIds('paragraph', paragraphIds, extraFilter, navigateCallbackOptions(navigate));
        if (paragraphsResponse === null) {
            return;
        }
        const questions = questionsResponse.data;
        const paragraphs = paragraphsResponse.data;
        let allImages = [];
        testQuestionsFromResponse.forEach((testQuestion) => {
            const matchingQuestion = questions.find((question) => (question.id === testQuestion.question_id));
            testQuestion['question'] = matchingQuestion;
            allImages = [...allImages, ...matchingQuestion.images.map(image => image.image.url)];
        });
        testParagraphsFromResponse.forEach((testParagraph) => {
            const matchingParagraph = paragraphs.find((paragraph) => (paragraph.id === testParagraph.paragraph_id));
            testParagraph['paragraph'] = matchingParagraph;
            allImages = [...allImages, ...matchingParagraph.images.map(image => image.image.url)];
        });
        testQuestionsFromResponse = testQuestionsFromResponse.filter((testQuestion) => (testQuestion.question !== undefined));
        testParagraphsFromResponse = testParagraphsFromResponse.filter((testParagraph) => (testParagraph.paragraph !== undefined));

        testParagraphsFromResponse.forEach((testParagraph, paragraphOrder) => {
            const paragraphQuestions = testParagraph.paragraph.questions.map((paragraphQuestion, questionOrder) => ({ question_id: paragraphQuestion.question.id, test_section_id: testParagraph.test_section_id, question: paragraphQuestion.question, question_order: questionOrder, paragraph_order: paragraphOrder }));
            paragraphQuestions.forEach(paragraphQuestion => {
                paragraphQuestion.question['basicInfo'] = testParagraph.paragraph.paragraph_text;
                paragraphQuestion.question['basicImages'] = testParagraph.paragraph.images;
                paragraphQuestion.question['paragraphId'] = testParagraph.paragraph_id;
                allImages = [...allImages, ...paragraphQuestion.question.images.map(image => image.image.url)];
            });
            const paragraphQuestionIds = paragraphQuestions.map(paragraphQuestion => paragraphQuestion.question_id);
            testQuestionsFromResponse = testQuestionsFromResponse.filter(testQuestion => !paragraphQuestionIds.includes(testQuestion.question_id));
            testQuestionsFromResponse = [...testQuestionsFromResponse, ...paragraphQuestions];
        });
        setTestQuestions(testQuestionsFromResponse);
        setTestParagraphs(testParagraphsFromResponse);

        return { testQuestions: testQuestionsFromResponse, testParagraphs: testParagraphsFromResponse, images: allImages }
    }

    const getQuestionStatus = (testAction) => {
        if (testAction.is_review_marked && testAction.is_saved) {
            return 'REVIEW_AND_SAVE';
        }
        if (testAction.is_review_marked) {
            return 'REVIEW';
        }
        if (testAction.is_saved) {
            return 'SAVE';
        }
        if (testAction.is_cleared) {
            return 'CLEAR';
        }
        if (testAction.is_visited) {
            return 'VISIT';
        }
        return 'NOT_VISIT';
    }

    const fetchAndSetResponses = async (questions) => {
        if (testStatus === 'NOT STARTED' || testStatus === null) {
            return;
        }
        const studentActionsWithoutVisited = await securedFetchTestStudentQuestionActionsByFilter(testCreateType, {
            test_ids: [id], question_ids: questions.map(question => question.question_id), latest_action_only: true, is_visited: false
        }, navigateCallbackOptions(navigate));
        const studentActionsWithVisited = await securedFetchTestStudentQuestionActionsByFilter(testCreateType, {
            test_ids: [id], question_ids: questions.map(question => question.question_id), latest_action_only: true
        }, navigateCallbackOptions(navigate));
        if (studentActionsWithVisited === null || studentActionsWithoutVisited === null) {
            return;
        }
        const studentActions = studentActionsWithVisited.data.map(studentAction => {
            const otherAction = studentActionsWithoutVisited.data.find(studentAction1 => studentAction1.question_id === studentAction.question_id);
            if (!otherAction) {
                return studentAction;
            }
            return otherAction;
        })
        if (studentActions.length === 0) {
            return;
        }
        if (testStatus === 'STARTED') {
            setQuestionStyleById(studentActions.map(studentResponse => ({ question_id: studentResponse.question_id, status: getQuestionStatus(studentResponse) })));
            return;
        }
        setQuestionStyleById(studentActions.map(studentResponse => {
            const checkCorrect = studentResponse.student_question_response === null ? null :
                studentResponse.student_question_response.total_correct === null ? null : checkResponseCorrectness(studentResponse.student_question_response, true);
            const status = checkCorrect === null ? 'NO_RESPONSE' : checkCorrect ? 'CORRECT' : 'INCORRECT';
            return { question_id: studentResponse.question_id, status: status, last_action: studentResponse };
        }));

    }

    const onSectionNext = () => {
        if (testSections.length === 0) {
            return;
        }
        if (activeSection === null) {
            handleTabClick(testSections[0].id);
            return;
        }
        const activeIndex = testSections.findIndex(testSection => testSection.id === activeSection.id);
        if (activeIndex === null || activeIndex === testSections.length - 1) {
            return;
        }
        handleTabClick(testSections[activeIndex + 1].id);
    }

    const onSectionPrevious = () => {
        if (testSections.length === 0) {
            return;
        }
        if (activeSection === null) {
            handleTabClick(testSections[0].id);
            return;
        }
        const activeIndex = testSections.findIndex(testSection => testSection.id === activeSection.id);
        if (activeIndex === null || activeIndex === 0) {
            return;
        }
        handleTabClick(testSections[activeIndex - 1].id, -1);
    }

    const onRefreshTest = () => {
        setRefreshKey(prevKey => prevKey + 1);
    }

    const renderLoadingPart = () => {

    }

    const renderTestHeaders = () => {

        if (!testData || isLoading || isSubmittedTest) {
            return;
        }

        return (
            <>
                {testStatus !== "FINISHED" && !hasAgreedInstructions &&
                    <NTAInstructions
                        isOpen={isInstructionPopupOpen}
                        onRequestClose={handleCloseInstructionPage}
                        handleInstructionAgree={handleAgreeInstructions}
                        infoOnly={hasAgreedInstructions}
                        testData={testData} />
                }

                {!isSubmittedTest && testStatus === 'STARTED' && (
                    <NTAFullScreenWarning
                        isOpen={isFullScreenWarningOpen}
                        onRequestClose={() => setFullScreenWarningOpen(false)}
                        handleSubmitTest={handleSubmitTest}
                        handleReenterFullScreen={handleReEnterTestFullScreen} />)}

                <NTATestHeader user={user} onRefreshClick={onRefreshTest} setEvaluatedNow={setEvaluatedNow} />

                <div className='flex flex-shrink-0 w-full justify-between'>
                    <NTASectionTabs
                        getSectionName={getSectionName}
                        handleTabClick={handleTabClick}
                        user={user}
                        onRefreshClick={onRefreshTest}
                    />
                    <NTAProfile user={user} />
                </div>
            </>
        );
    }

    const renderTestBody = () => {

        if (isLoading || (testStatus !== 'NOT STARTED' && (testSections.length > 0 && activeSection === null))) {
            return (
                <div className="w-screen h-screen bg-black/50 flex items-center justify-center">
                    <Spinner />
                </div>
            );
        }
        if (!testData) {
            return;
        }

        if (isSubmittedTest) {
            return (
                <div className="w-screen h-screen bg-black/50 flex items-center justify-center">
                    <Notification
                        isOpen={isTestSubmittedAlertOpen}
                        onClose={() => { setTestSubmittedAlertOpen(false); navigate(-1) }}
                        type={'global-green'}
                        message={t('youHaveAlreadySubmittedTheTest')}
                        buttonText2={t('goBack')}
                        onButton2Click={() => navigate(-1)} />
                </div>);
        }

        if (testStatus === 'STARTED' && (!hasAgreedInstructions || isFullScreenWarningOpen || isInstructionPopupOpen)) {
            return (
                <div className="w-screen h-screen bg-black/50 flex items-center justify-center">
                </div>
            );
        }

        return (
            <>
                {testStatus === "NOT STARTED" &&
                    <div className="absolute w-full h-full bg-black/50 flex items-center justify-center">
                        <Notification
                            isOpen={isNotStartedTestAlertOpen}
                            onClose={() => { setNotStartedTestAlertOpen(false); navigate(-1) }}
                            type={'red'}
                            message={t('backToTestsList')} />
                    </div>

                    //     <div className="NTATestView roboto-regular flex">
                    //         {/* <h3 className='title'>Keep Clicking Refresh Button</h3> */}
                    //         <button className='btn flex' onClick={() => navigate(-1)}>
                    //             <RiArrowGoBackFill className="icon" /> {t('backToTestsList')}
                    //         </button >
                    //     </div>
                }
                {testStatus !== null && testStatus !== 'NOT STARTED' && (
                    <>
                        <div className="w-full h-full flex items-center justify-center roboto-regular">

                            {activeSection === null ? (
                                <div className="flex flex-col flex-grow h-full justify-start items-center overflow-y-auto">
                                </div>
                            ) : (
                                <NTASectionView
                                    sectionId={activeSection.id}
                                    startQuestionIndex={startQuestionIndex}
                                    user={user}
                                    onSectionNext={onSectionNext}
                                    onSectionPrevious={onSectionPrevious}
                                    handleSubmitTest={handleSubmitTest}
                                    getSectionName={getSectionName}
                                />
                            )}
                        </div>
                    </>
                )}
            </>

        );
    }

    return (
        <NTATestContext.Provider value={value}>
            <div ref={containerRef}
                className="relative w-screen h-screen flex flex-col items-center"
                id='NTA-tests-glass'>
                {renderLoadingPart()}
                {renderTestHeaders()}
                {renderTestBody()}

                {isFinishedTestAlertOpen &&
                    <div className="absolute w-full h-full bg-black/50 flex items-center justify-center">
                        <Notification
                            isOpen={isFinishedTestAlertOpen}
                            onClose={() => setFinishedTestAlertOpen(false)}
                            type={'red'}
                            message={t('testHasFinished')}
                            parentId={'NTA-tests-glass'} />
                    </div>
                }
            </div>

        </NTATestContext.Provider>);
};

export default NTATestView;