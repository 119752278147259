import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Spinner from '../../Common/Tailwind/Spinner';
import { STUDENT_USER_TYPE } from "../../../services/UserService";
import PhysicsIcon from '../../../Assets/PhysicsIcon.png';
import MathIcon from '../../../Assets/MathIcon.png';
import ScienceIcon from '../../../Assets/PhysicsIcon.png';
import ChemistryIcon from '../../../Assets/ChemistryIcon.png';
import BiologyIcon from '../../../Assets/BiologyIcon.png';
import DefaultIcon from '../../../Assets/LearningIcon.png';

const ReadingMaterialCard = ({ readingMaterialData, onCardClick, user = STUDENT_USER_TYPE }) => {

    const subjectName = readingMaterialData.topics.length > 0 ? readingMaterialData.topics[0].topic.subject.subject_name : '';
    const materialType = readingMaterialData.material_type;

    const { t } = useTranslation();

    const subjectIcons = {
        Physics: PhysicsIcon,
        Math: MathIcon,
        Science: ScienceIcon,
        Chemistry: ChemistryIcon,
        Biology: BiologyIcon,
    };

    return (

        <div className="group relative border-r border-b border-gray-200 p-4 flex flex-col justify-start items-center gap-1 cursor-pointer"
            onClick={() => onCardClick(readingMaterialData)}>

            {/* Displaying Question Info */}
            <div className="w-full flex justify-start items-center gap-1 p-2">
                {/* Displaying Question ID */}
                <span className={`${subjectName === "Physics" ? "bg-blue-500"
                    : subjectName === "Chemistry" ? "bg-red-500"
                        : subjectName === "Mathematics" ? "bg-green-500"
                            : "bg-purple-500"} text-white text-xs font-medium rounded-lg px-2 py-1`}>
                    {t(subjectName)}
                </span>
            </div>

            <img
                src={subjectIcons[subjectName] || DefaultIcon}
                alt={`${subjectName} Icon`}
                className="mt-2 aspect-square rounded-lg object-cover group-hover:opacity-75"
            />

            <div className="mt-2 flex flex-col justify-center items-center w-full gap-1">
                <div className="flex justify-center items-center w-full gap-1 overflow-x-auto scrollbar-hide">
                    <div className="flex justify-center bg-gray-100 rounded-lg px-1 items-center text-sm font-semibold leading-6 text-blue-500 whitespace-nowrap" >
                        {readingMaterialData.material_name}
                    </div>
                </div>
            </div>
        </div >
    )

}

export default ReadingMaterialCard;