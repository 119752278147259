import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { TEST_CREATE_TYPE_PRACTICE, securedFetchAttemptedTests, securedFetchTestStudents, securedFetchTests, securedStartTest } from '../../../services/TestService';
import { STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import PracticeTestCreate from '../TestCreate/PracticeTestCreate';
import i18n from '../../../multi-language/i18n';

const StudentPracticeTestTable = ({ user = STUDENT_USER_TYPE }) => {
    const [allTests, setAllTests] = useState([]);
    const [isCreatePracticeTestModalOpen, setCreatePracticeTestModalOpen] = useState(false);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const fetchAllTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_PRACTICE, {}, navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        return allTestsResponse.data;
    };

    useEffect(() => {
        (async () => {
            const allTests = await fetchAllTests();
            if (allTests === null) {
                return;
            }
            setAllTests(allTests);
        })();
    }, []);

    const onTestSelectionChange = (testId) => {
        navigate(`${testId}`);
    };

    const convertDurationFormat = (duration) => {

        const hrs = Math.floor(duration / 60);
        const mins = duration % 60;

        const hrs_str = (hrs < 10 ? "0" : "") + hrs.toString();
        const mins_str = (mins < 10 ? "0" : "") + mins.toString();

        return hrs_str + ":" + mins_str;
    }

    const onStartTest = async (event, test) => {
        event.stopPropagation();
        const response = await securedStartTest(TEST_CREATE_TYPE_PRACTICE, test.id, { test_duration_minutes: test.test_duration_minutes });
        if (response === null) {
            return;
        }
        navigate(`${test.id}`)
    }

    const onPracticeTestCreate = async () => {
        const allTests = await fetchAllTests();
        if (allTests === null) {
            return;
        }
        setAllTests(allTests);
    }


    return (

        <div className="w-full h-full flex flex-col items-center gap-2 px-4 text-[var(--textColor)]">

            <button className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                onClick={() => setCreatePracticeTestModalOpen(true)}>
                {t('createNewPracticeTest')}
            </button>

            <div class="relative flex-1 w-full overflow-x-auto overflow-y-auto scrollbar-thin">

                <PracticeTestCreate isOpen={isCreatePracticeTestModalOpen} onPracticeTestCreate={onPracticeTestCreate} onRequestClose={() => setCreatePracticeTestModalOpen(false)} user={user} />
                <div className="w-full h-full flex flex-col items-center gap-2 px-4 text-[var(--textColor)]">
                    <div class="relative w-full overflow-x-auto overflow-y-auto scrollbar-thin">

                        <table className="w-full text-sm">
                            <thead>
                                <tr className="bg-[var(--SecondaryBkg)] text-[var(--textColor)] text-center align-middle">
                                    <th className="p-1 leading-relaxed">{t('status')}</th>
                                    <th className="p-1 leading-relaxed">{t('name')}</th>
                                    <th className="p-1 leading-relaxed">{t('date')}</th>
                                    <th className="p-1 leading-relaxed">{t('time')}</th>
                                    <th className="p-1 leading-relaxed">{t('duration')}</th>
                                    {/* <th>Remaining Time</th> */}
                                    {/* <th>...</th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {allTests.length > 0 && allTests.map(test => (
                                    <tr key={test.id}
                                        onClick={() => onTestSelectionChange(test.id)}
                                        className="hover:font-semibold hover:bg-gray-100 cursor-pointer">
                                        {test.test_start_time === null ? (
                                            <td className="w-24 flex justify-center items-center text-center py-2 px-4 border-b-2 border-gray-200"
                                                onClick={(event) => onStartTest(event, test)} id={test.id}>
                                                <p className={'bg-green-100 text-green-700 rounded-md px-1 py-1 text-sm font-medium'}>
                                                    {t('startCapital')}
                                                </p>
                                            </td>) : (
                                            <td className="w-24 flex justify-center items-center text-center py-2 px-4 border-b-2 border-gray-200">
                                                <p className={'bg-blue-100 text-blue-700 rounded-md px-1 py-1 text-sm font-medium'}>
                                                    {t('completedCapital')}
                                                </p>
                                            </td>
                                        )}
                                        <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                            {test.test_name}
                                        </td>
                                        <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                            {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('DDslMMslYYYY')) : null}
                                        </td>
                                        <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                            {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('hcommA')) : null}
                                        </td>
                                        <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                            {test.test_duration_minutes ? convertDurationFormat(test.test_duration_minutes) : null}
                                        </td>
                                        {/* <td> </td> */}
                                        {/* <td> </td> */}
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentPracticeTestTable;
