import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from "react-i18next";

import MemberRegisterForm from "./MemberRegisterForm";
import Dropdown from "../Common/Tailwind/SelectMenus/Dropdown";
import { ADMIN_USER_TYPE, securedFetchUsers, securedUpdateUserDataById, securedDeactivateUserById } from "../../services/UserService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { securedFetchSubjects } from "../../services/SyllabusService";
import { UnauthorizedError } from "../../services/Errors";

import Spinner from "../Common/Tailwind/Spinner";
import { ArrowUturnLeftIcon, CloudArrowUpIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";

const TeacherList = ({ user = ADMIN_USER_TYPE }) => {

    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [deletePopupTeacherId, setDeletePopupTeacherId] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [teachers, setTeachers] = useState(null);
    const [selectedSubjectsToEdit, setSelectedSubjectsToEdit] = useState([]);
    const [selectedTeacherId, setSelectedTeacherId] = useState();
    const [isCreateUserOpen, setCreateUserOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const closeDeletePopup = () => {
        setDeletePopupTeacherId(null);
    };

    const onTextChange = (textSetter) => (event) => {
        const { value } = event.target;
        textSetter(value);
    };

    const makeFilter = () => {
        const filter = {};
        if (selectedSubjects.length > 0) {
            filter['subject_ids'] = selectedSubjects.map(subject => (subject.id));
        }
        return filter;
    }

    const toggleEdit = async (teacher) => {
        setEditMode(!editMode);
        setSelectedTeacherId(teacher.id);
        setFullName(teacher.user_data.first_name);
        setEmail(teacher.user_data.email);
        setContactNumber(teacher.user_data.phone_number);
    }

    const handleEdit = async (teacherId) => {
        const user_data = {
            email: email,
            first_name: fullName,
            phone_number: contactNumber
        }
        const newSubjects = selectedSubjectsToEdit.map(subject => ({ subject_id: subject.id }));
        const data = {
            subjects: newSubjects,
            user_data: user_data
        };
        const response = await securedUpdateUserDataById('teacher', teacherId, data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        await onSubmitFilter();
        setEditMode(!editMode);
        setSelectedTeacherId();
    }

    const onTeacherCreate = async () => {
        await onSubmitFilter();
        setCreateUserOpen(false);
    }

    const handleDelete = async (teacherId) => {
        const response = await securedDeactivateUserById('teacher', teacherId, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        const newTeacherList = teachers.map(teacher => {
            if (teacher.id === teacherId) {
                return { ...teacher, user_data: { ...teacher.user_data, is_active: false } };
            }
            return teacher;
        })
        setTeachers(newTeacherList);
        closeDeletePopup();
    }

    const refreshMembersWithFilter = async (externalFilter = null) => {
        setIsLoading(true);

        const teachersResponse = await securedFetchUsers('teachers', externalFilter, navigateCallbackOptions(navigate));
        if (teachersResponse === null) {
            return;
        }
        setTeachers(teachersResponse.data);
        setIsLoading(false);
        return teachersResponse.data;
    }

    const onSelectedSubjectToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedSubjectsFromSelect = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjects(selectedSubjectsFromSelect);
        await refreshMembersWithFilter({ subject_ids: selectedSubjectsFromSelect.map(subject => subject.id) });
    };

    const onSelectedSubjectToEditChange = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedSubjectsFromSelect = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjectsToEdit(selectedSubjectsFromSelect);
    };

    const onSubmitFilter = async () => {
        setIsLoading(true);
        const teachersResponse = await securedFetchUsers('teachers', makeFilter(), navigateCallbackOptions(navigate));
        if (teachersResponse === null) {
            setIsLoading(false);
            return false;
        }
        setTeachers(teachersResponse.data);
        setIsLoading(false);
        return true;
    }

    const fetchSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        return subjectsResponse.data;
    };

    useEffect(() => {
        (async () => {
            const success = await onSubmitFilter();
            if (!success) {
                return;
            }
            const fetchedSubjects = await fetchSubjects();
            if (fetchedSubjects === null) {
                return;
            }
            const parsedSubjects = fetchedSubjects.map((item) => ({ id: item.id, subject_name: item.subject_name }))
            setSubjects(parsedSubjects);
        })();
    }, []);

    const renderFilter = () => {
        if (teachers === null) {
            return;
        }

        return (
            <div className="w-full flex flex-col justify-center items-center p-2 my-2 gap-2 md:gap-4 z-3">

                <h3 className="mt-2 text-lg font-semibold leading-6 text-[var(--SecondaryColor)]">
                    {t('selectSubjects')}
                </h3>

                <div className="mt-2 flex flex-col sm:flex-row items-center justify-center gap-4 md:gap-6 lg:gap-8 w-full">

                    {/* Select Subjects */}
                    <Dropdown
                        list={subjects}
                        selected={selectedSubjects}
                        onSelectionChange={onSelectedSubjectToggle}
                        label={t('subjects')}
                        nameField='subject_name'
                        valueField='id'
                        required={true}
                        multiple={true}
                        // isAddable={true}
                        className="flex items-center justify-center gap-2 w-96"
                    />

                    {/* Create Teacher*/}
                    <button onClick={() => setCreateUserOpen(true)}
                        data-tooltip-id="tooltip-create-new-user"
                        data-tooltip-content={t('registerNewTeacher')}
                        data-tooltip-place="right">
                        <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                    </button>
                </div>

                <MemberRegisterForm
                    isOpen={isCreateUserOpen}
                    onRequestClose={() => (setCreateUserOpen(false))}
                    onMemberCreate={onTeacherCreate}
                    type={{ id: 'teacher', name: t('teacher') }} />

                <Tooltip id="tooltip-create-new-user" />
            </div>
        );
    }

    const renderTable = () => {

        if (teachers === null) {
            return;
        }

        return (
            <div class="relative w-full overflow-x-auto overflow-y-auto scrollbar-thin">
                <table className="w-full text-sm">
                    <thead>
                        <tr className="bg-[var(--PrimaryBkg)] text-[var(--textColor)] text-center align-middle">
                            <th className="p-1 leading-relaxed">{t('name')}</th>
                            <th className="p-1 leading-relaxed">{t('subjects')}</th>
                            <th className="p-1 leading-relaxed">{t('email')}</th>
                            <th className="p-1 leading-relaxed">{t('phone')}</th>
                            <th className="p-1 leading-relaxed">{t('status')}</th>
                            <th className="p-1 leading-relaxed">{t('edit')}</th>
                            <th className="p-1 leading-relaxed">{editMode ? t('cancel') : t('delete')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {teachers.length > 0 && teachers.map(teacher => (
                            <tr key={teacher.id}
                                className="hover:font-semibold hover:bg-gray-100">
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {editMode && teacher.id === selectedTeacherId ?
                                        (<input
                                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                            type="fullName"
                                            name="fullName"
                                            value={fullName}
                                            onChange={onTextChange(setFullName)}
                                            placeholder={teacher.user_data.first_name} />)
                                        :
                                        (<> {teacher.user_data.first_name} </>)
                                    }
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {editMode && teacher.id === selectedTeacherId ?
                                        (<Dropdown
                                            list={subjects}
                                            selected={selectedSubjectsToEdit}
                                            onSelectionChange={onSelectedSubjectToEditChange}
                                            label={''}
                                            nameField='subject_name'
                                            valueField='id'
                                            required={true}
                                            multiple={true}
                                            // isAddable={true}
                                            className="max-w-80"
                                        />)
                                        :
                                        (<>{teacher.subjects.map(subject => subject.subject.subject_name).join(', ')} </>)
                                    }
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {editMode && teacher.id === selectedTeacherId ?
                                        (<input
                                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={onTextChange(setEmail)}
                                            placeholder={teacher.user_data.email} />)
                                        :
                                        (<> {teacher.user_data.email} </>)
                                    }
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {editMode && teacher.id === selectedTeacherId ?
                                        (<input
                                            className="block w-32 mx-auto rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                            type="contactNumber"
                                            name="contactNumber"
                                            value={contactNumber}
                                            onChange={onTextChange(setContactNumber)}
                                            placeholder={teacher.user_data.phone_number} />)
                                        :
                                        (<> {teacher.user_data.phone_number} </>)
                                    }
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {teacher.user_data.is_active ? 'Active' : 'Deactivated'}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {teacher.user_data.is_active && (
                                        editMode && teacher.id === selectedTeacherId ? (
                                            <CloudArrowUpIcon
                                                className="w-4 h-4 cursor-pointer hover:text-[var(--PrimaryColor)]"
                                                onClick={() => handleEdit(teacher.id)}
                                            />
                                        ) : (
                                            <PencilSquareIcon
                                                className="w-4 h-4 cursor-pointer hover:text-[var(--PrimaryColor)]"
                                                onClick={() => toggleEdit(teacher)}
                                            />
                                        )
                                    )}
                                </td>
                                <td className="text-center py-2 px-auto border-b-2 border-gray-200">
                                    {teacher.user_data.is_active &&
                                        ((editMode && teacher.id === selectedTeacherId) ? (
                                            <ArrowUturnLeftIcon
                                                className="w-4 h-4 cursor-pointer hover:text-[var(--tiger-lilly)]"
                                                onClick={() => toggleEdit(teacher)}
                                            />
                                        ) : (
                                            <>
                                                <TrashIcon
                                                    className="w-4 h-4 cursor-pointer hover:text-[var(--tiger-lilly)]"
                                                    onClick={() => setDeletePopupTeacherId(teacher.id)}
                                                />
                                                {deletePopupTeacherId === teacher.id && (
                                                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                                        <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                                                            <p className="text-lg font-semibold text-gray-800 mb-4">
                                                                {t('deactivateTeacherConfirm')}
                                                            </p>
                                                            <div className="flex justify-end space-x-4">
                                                                <button
                                                                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                                                                    onClick={() => handleDelete(teacher.id)}>
                                                                    {t('yes')}
                                                                </button>
                                                                <button
                                                                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                                                                    onClick={closeDeletePopup}>
                                                                    {t('no')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ))
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

    if (isLoading) {
        return (
            <Spinner />
        );
    }

    return (

        <div>

            {/* Filter */}
            {renderFilter()}

            {/* Table */}
            <div class="flex w-full flex-col mb-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">
                {renderTable()}
            </div>

        </div>
    )
};

export default TeacherList;
