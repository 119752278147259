import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import { useTranslation } from "react-i18next";

import MultiGradeBatchFilter from "../Batches/MultiGradeBatchFilter";
import MemberRegisterForm from "./MemberRegisterForm";

import { ADMIN_USER_TYPE, securedFetchUsers, securedUpdateUserDataById, securedDeactivateUserById } from "../../services/UserService";
import { UnauthorizedError } from "../../services/Errors";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { securedFetchBatchesByFilter, securedFetchBatchesByGrade } from "../../services/BatchService";

import Spinner from "../Common/Tailwind/Spinner";
import { ArrowUturnLeftIcon, CloudArrowUpIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import Dropdown from "../Common/Tailwind/SelectMenus/Dropdown";

const StudentList = ({ user = ADMIN_USER_TYPE }) => {

    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [deletePopupStudentId, setDeletePopupStudentId] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [selectedBatchesToEdit, setSelectedBatchesToEdit] = useState([]);
    const [batchesToEdit, setBatchesToEdit] = useState([]);
    const [students, setStudents] = useState(null);
    const [selectedStudentId, setSelectedStudentId] = useState();
    const [isCreateUserOpen, setCreateUserOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const closeDeletePopup = () => {
        setDeletePopupStudentId(null);
    };

    const onTextChange = (textSetter) => (event) => {
        const { value } = event.target;
        textSetter(value);
    };

    const makeFilter = () => {
        const filter = {};
        if (selectedBatches.length > 0) {
            filter['batch_ids'] = selectedBatches.map(batch => (batch.id));
        }
        else {
            filter['batch_ids'] = batches.map(batch => (batch.id));
        }
        return filter;
    }

    const toggleEdit = async (student) => {
        const probableBatches = await securedFetchBatchesByFilter({}, navigateCallbackOptions(navigate));
        const currentBatchIds = student.batches.map(batch => batch.batch_id);
        setEditMode(!editMode);
        setBatchesToEdit(probableBatches.data);
        setSelectedBatchesToEdit(probableBatches.data.filter(batch => currentBatchIds.includes(batch.id)));
        setSelectedStudentId(student.id);
        setFullName(student.user_data.first_name);
        setEmail(student.user_data.email);
        setContactNumber(student.user_data.phone_number);
    }

    const handleEdit = async (studentId) => {
        const user_data = {
            email: email,
            first_name: fullName,
            phone_number: contactNumber
        }
        const newBatches = selectedBatchesToEdit.map(batch => ({ batch_id: batch.id }));
        const data = {
            batches: newBatches,
            user_data: user_data
        };
        const response = await securedUpdateUserDataById('student', studentId, data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        const studentsResponse = await securedFetchUsers('students', makeFilter(), navigateCallbackOptions(navigate));
        if (studentsResponse === null) {
            return;
        }
        const studentsWithCurrentBatches = studentsResponse.data.map(student => ({ ...student, batches: student.batches.filter(batch => batch.end_time === null) }));
        setStudents(studentsWithCurrentBatches);
        setEditMode(!editMode);
        setSelectedStudentId(null);
    }

    const handleDelete = async (studentId) => {
        const response = await securedDeactivateUserById('student', studentId, navigateCallbackOptions(navigate));
        const newStudentList = students.map(student => {
            if (student.id === studentId) {
                return { ...student, user_data: { ...student.user_data, is_active: false } };
            }
            return student;
        })
        setStudents(newStudentList);
        closeDeletePopup();
    }

    const refreshMembersWithFilter = async (externalFilter = null) => {
        setIsLoading(true);

        const studentsResponse = await securedFetchUsers('students', externalFilter, navigateCallbackOptions(navigate));
        if (studentsResponse === null) {
            return;
        }
        const studentsWithCurrentBatches = studentsResponse.data.map(student => ({ ...student, batches: student.batches.filter(batch => batch.end_time === null) }));
        setStudents(studentsWithCurrentBatches);

        setIsLoading(false);
        return studentsWithCurrentBatches;
    }

    const onSelectedBatchToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedBatchesFromSelect = batches.filter(batch => selectedIds.includes(batch.id));
        setSelectedBatches(selectedBatchesFromSelect);
        await refreshMembersWithFilter({ batch_ids: selectedBatchesFromSelect.map(batch => batch.id) });
    };

    const onSelectedBatchToEditToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const newBatchesToEdit = batchesToEdit.filter(batch => selectedIds.includes(batch.id));
        setSelectedBatchesToEdit(newBatchesToEdit);
    };

    const onSubmitFilter = async (externalFilter = null) => {
        setIsLoading(true);
        const filter = externalFilter ? externalFilter : makeFilter();
        const studentsResponse = await securedFetchUsers('students', filter, navigateCallbackOptions(navigate));
        if (studentsResponse === null) {
            setIsLoading(false);
            return false;
        }
        const studentsWithCurrentBatches = studentsResponse.data.map(student => ({ ...student, batches: student.batches.filter(batch => batch.end_time === null) }));
        setStudents(studentsWithCurrentBatches);
        setIsLoading(false);
        return true;
    }

    const onStudentCreate = async () => {
        const success = await onSubmitFilter();
        if (!success) {
            return;
        }
        const studentsResponse = await securedFetchUsers('students', makeFilter(), navigateCallbackOptions(navigate));
        if (studentsResponse === null) {
            return;
        }
        setStudents(studentsResponse.data);
        setCreateUserOpen(false);
    }

    useEffect(() => {
        (async () => {
            await onSubmitFilter({});
        })();
    }, []);

    const renderFilter = () => {
        if (students === null) {
            return;
        }

        return (
            <div className="w-full flex flex-col justify-center items-center p-2 my-2 gap-2 md:gap-4 z-3">

                <MultiGradeBatchFilter
                    grades={grades} batches={batches}
                    setGrades={setGrades} setBatches={setBatches}
                    selectedGrades={selectedGrades} setSelectedGrades={setSelectedGrades}
                    selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches}
                    onSelectedBatchesToggle={onSelectedBatchToggle}
                    setCreateUserOpen={setCreateUserOpen}
                    showTitle={true}
                />

                <MemberRegisterForm isOpen={isCreateUserOpen} onRequestClose={() => (setCreateUserOpen(false))} onMemberCreate={onStudentCreate} type={{ id: 'student', name: t('student') }} />

                <Tooltip id="tooltip-create-new-user" />
            </div>
        );
    }

    const renderTable = () => {

        if (students === null) {
            return;
        }

        return (
            <div class="relative w-full overflow-x-auto overflow-y-auto scrollbar-thin">
                <table className="w-full text-sm">
                    <thead>
                        <tr className="bg-[var(--PrimaryBkg)] text-[var(--textColor)] text-center align-middle">
                            <th className="p-1 leading-relaxed">{t('name')}</th>
                            <th className="p-1 leading-relaxed">{t('batch')}</th>
                            <th className="p-1 leading-relaxed">{t('email')}</th>
                            <th className="p-1 leading-relaxed">{t('phone')}</th>
                            <th className="p-1 leading-relaxed">{t('status')}</th>
                            <th className="p-1 leading-relaxed">{t('edit')}</th>
                            <th className="p-1 leading-relaxed">{editMode ? t('cancel') : t('delete')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {students.length > 0 && students.map(student => (
                            <tr key={student.id}
                                className="hover:font-semibold hover:bg-gray-100">
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {editMode && student.id === selectedStudentId ?
                                        (<input
                                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                            type="fullName"
                                            name="fullName"
                                            value={fullName}
                                            onChange={onTextChange(setFullName)}
                                            placeholder={student.user_data.first_name} />)
                                        :
                                        (<> {student.user_data.first_name} </>)
                                    }
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {editMode && student.id === selectedStudentId ?
                                        (<Dropdown
                                            list={batchesToEdit}
                                            selected={selectedBatchesToEdit}
                                            onSelectionChange={onSelectedBatchToEditToggle}
                                            label={''}
                                            nameField='batch_name'
                                            valueField='id'
                                            required={true}
                                            multiple={true}
                                            // isAddable={true}
                                            className="max-w-80"
                                        />)
                                        :
                                        (<> {student.batches.map(batch => batch.batch.batch_name).join(', ')} </>)
                                    }
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {editMode && student.id === selectedStudentId ?
                                        (<input
                                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={onTextChange(setEmail)}
                                            placeholder={student.user_data.email} />)
                                        :
                                        (<> {student.user_data.email} </>)
                                    }
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {editMode && student.id === selectedStudentId ?
                                        (<input
                                            className="block w-32 mx-autorounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                            type="contactNumber"
                                            name="contactNumber"
                                            value={contactNumber}
                                            onChange={onTextChange(setContactNumber)}
                                            placeholder={student.user_data.phone_number} />)
                                        :
                                        (<> {student.user_data.phone_number} </>)
                                    }
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {student.user_data.is_active ? 'Active' : 'Deactivated'}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {student.user_data.is_active && (
                                        editMode && student.id === selectedStudentId ? (
                                            <CloudArrowUpIcon
                                                className="w-4 h-4 cursor-pointer hover:text-[var(--PrimaryColor)]"
                                                onClick={() => handleEdit(student.id)}
                                            />
                                        ) : (
                                            <PencilSquareIcon
                                                className="w-4 h-4 cursor-pointer hover:text-[var(--PrimaryColor)]"
                                                onClick={() => toggleEdit(student)}
                                            />
                                        )
                                    )}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {student.user_data.is_active &&
                                        ((editMode && student.id === selectedStudentId) ? (
                                            <ArrowUturnLeftIcon
                                                className="w-4 h-4 cursor-pointer hover:text-[var(--tiger-lilly)]"
                                                onClick={() => toggleEdit(student)}
                                            />
                                        ) : (
                                            <>
                                                <TrashIcon
                                                    className="w-4 h-4 cursor-pointer hover:text-[var(--tiger-lilly)]"
                                                    onClick={() => setDeletePopupStudentId(student.id)}
                                                />
                                                {deletePopupStudentId === student.id && (
                                                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                                        <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                                                            <p className="text-lg font-semibold text-gray-800 mb-4">
                                                                {t('deactivateStudentConfirm')}
                                                            </p>
                                                            <div className="flex justify-end space-x-4">
                                                                <button
                                                                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                                                                    onClick={() => handleDelete(student.id)}>
                                                                    {t('yes')}
                                                                </button>
                                                                <button
                                                                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                                                                    onClick={closeDeletePopup}>
                                                                    {t('no')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ))
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

    if (isLoading) {
        return (
            <Spinner />
        );
    }

    return (

        <div>

            {/* Filter */}
            {renderFilter()}

            {/* Table */}
            <div class="flex flex-col mb-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">
                {renderTable()}
            </div>

        </div>

    )
};

export default StudentList;
