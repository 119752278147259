import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PhysicsIcon from '../../../Assets/PhysicsIcon.png';
import MathIcon from '../../../Assets/MathIcon.png';
import ScienceIcon from '../../../Assets/PhysicsIcon.png';
import ChemistryIcon from '../../../Assets/ChemistryIcon.png';
import BiologyIcon from '../../../Assets/BiologyIcon.png';
import DefaultIcon from '../../../Assets/LearningIcon.png';

import { STUDENT_USER_TYPE, TEACHER_USER_TYPE, ADMIN_USER_TYPE } from "../../../services/UserService";
import { hasSlotPinViewAuthority } from '../../../services/TimetableService';
import PINCode from "../../Common/PINCode";
import { createParams, truncateString } from '../../../services/CommonService';
import { convertISTToLocalFull, convertLocalToIST, convertLocalToISTFull } from '../../../services/DateService';

const SlotCard = ({ slotData, slotStatus, showDates = false, user = STUDENT_USER_TYPE }) => {

    const localStartDateTime = convertISTToLocalFull(slotData.start_time);
    const localEndDateTime = convertISTToLocalFull(slotData.end_time);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToSlotId = (slotId) => {
        let pathParams = { selectedTab: 'Video' };
        if (slotData.matching_segment_id) {
            pathParams['segmentId'] = slotData.matching_segment_id;
        }
        navigate(`/${user}/liveclass/slots/${slotId}${createParams(pathParams)}`);
    }

    const subjectIcons = {
        Physics: PhysicsIcon,
        Math: MathIcon,
        Science: ScienceIcon,
        Chemistry: ChemistryIcon,
        Biology: BiologyIcon,
    };

    const slotMatchingSegmentRefinedTitle = (slotData.matching_segment_refined_title == null
        ? null
        : slotData.matching_segment_refined_title);

    return (
        <div className="group relative border-r border-b border-gray-200 p-4 flex flex-col justify-start items-center gap-1 cursor-pointer"
            onClick={() => goToSlotId(slotData.id)}>

            {slotStatus === "FINISHED" &&
                <div className="absolute inset-0 bg-gray-500 z-1 opacity-50"></div>
            }

            {/* Displaying Lecture Info */}
            <div className="w-full flex justify-between items-center gap-1 p-2">

                {/* Lecture Date */}
                {showDates &&
                    <span className="text-white text-xs font-medium rounded-lg px-2 py-1 bg-orange-400">
                        {localStartDateTime.split('T')[0].slice(8, 10)}
                        /
                        {localStartDateTime.split('T')[0].slice(5, 7)}
                        /
                        {localStartDateTime.split('T')[0].slice(2, 4)}
                    </span>
                }

                {/* Lecture Time */}
                <span className="text-white text-xs font-medium rounded-lg px-2 py-1 bg-purple-400">
                    {localStartDateTime.split('T')[1].slice(0, 5)}
                    -
                    {localEndDateTime.split('T')[1].slice(0, 5)}
                </span>
            </div>

            {/* Displaying the Lecture Icon if available */}
            <img
                src={subjectIcons[slotData.subject.subject_name] || DefaultIcon}
                alt="Lecture related"
                // onLoad={handleImageLoad}
                className="my-2 mx-auto size-16 contain shrink-0" />

            {/* Subject Name */}
            {slotData.subject.subject_name && (
                <span className={`${slotData.subject.subject_name === "Physics" ? "bg-blue-500"
                    : slotData.subject.subject_name === "Chemistry" ? "bg-red-500"
                        : slotData.subject.subject_name === "Mathematics" ? "bg-green-500"
                            : "bg-purple-500"} text-white text-xs font-medium rounded-lg px-2 py-1`}>
                    {t(`${slotData.subject.subject_name}`)}
                </span>
            )}

            {/* Teacher Name */}
            {user !== TEACHER_USER_TYPE &&
                <div>
                    <div className="flex justify-center bg-gray-100 rounded-lg px-1 items-center text-xs font-semibold leading-6 text-blue-500 whitespace-nowrap" >
                        {slotData.teacher.user_data.first_name} {slotData.teacher.user_data.last_name}
                    </div>
                </div>
            }

            <div className="-mt-px flex divide-x">
                <div className="-ml-px flex w-0 flex-1">
                    {hasSlotPinViewAuthority(user) && slotData.pin &&
                        <div className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                            <PINCode PIN={slotData.pin} />
                        </div>
                    }
                </div>
            </div>

            {slotMatchingSegmentRefinedTitle !== null &&
                <div className="mt-2 flex flex-col justify-center items-center w-full gap-1">

                    <div className="flex justify-center items-center w-full gap-1 overflow-x-auto scrollbar-hide">
                        <div className="flex justify-center bg-gray-100 rounded-lg px-1 items-center text-sm font-semibold leading-6 text-blue-500 whitespace-nowrap" >
                            {slotMatchingSegmentRefinedTitle}
                        </div>
                    </div>
                </div>
            }

        </div >
    )

}

export default SlotCard;