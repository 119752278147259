import React from 'react';
import produce from 'immer';
import { QUESTION_OPTIONS } from '../../../services/QuestionService';

const RadioAnswerInputList = ({ className = "", label = "", namePrefix = "", answerInputs, setAnswerInputs, selectedAnswer, setSelectedAnswer, required = null, editable = true }) => {

    const onSelectedAnswerChange = (index) => (event) => {
        setSelectedAnswer(index);
    }

    const onAnswerTextChange = (index) => (event) => {
        const newValue = event.target.value;
        setAnswerInputs(produce(draft => {
            draft[index].value = newValue;
        }));
    }

    return (
        <div className={className}>
            <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">{label}</label>

            {answerInputs.map((answerDict, index) => {
                return (
                    <div className="ml-2 flex items-center" key={QUESTION_OPTIONS[index]}>
                        <input className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            type="radio"
                            name={`${namePrefix}-answer`}
                            value={QUESTION_OPTIONS[index]}
                            id={QUESTION_OPTIONS[index]}
                            checked={selectedAnswer === index}
                            onChange={onSelectedAnswerChange(index)}
                            required />
                        <label htmlFor={QUESTION_OPTIONS[index]} className="ml-1 block text-sm font-medium leading-6 text-gray-900">
                            ({QUESTION_OPTIONS[index]})
                            {editable ?
                                <textarea className="optionInput" value={answerDict.value} placeholder={`Answer ${QUESTION_OPTIONS[index]}...`} rows="1" onChange={onAnswerTextChange(index)} required={required} /> :
                                <p className="optionInput"></p>
                            }
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default RadioAnswerInputList