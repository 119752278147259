import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";

import './Video.css';

const VideoUpload = ({ title = 'Videos', videos, setVideos, label, maxSize = 1, setVideoFileNames, showTitle = true }) => {

    const [addEnabled, setAddEnabled] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (maxSize && videos.length === maxSize) {
            setAddEnabled(false);
        } else {
            setAddEnabled(true);
        }
    }, [videos]);

    const onDrop = useCallback((selectedFiles) => {
        const selectedFilesArray = Array.from(selectedFiles);
        setVideos((previousPdfArray) => previousPdfArray.concat(selectedFilesArray));
        setVideoFileNames(selectedFiles);
    }, [setVideos]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.mp4', '.mkv']
        },
        multiple: false,
        maxFiles: 1,
    });

    return (
        <div className="flex items-center justify-start gap-2 w-full">

            {showTitle && (
                <label className="block text-sm font-medium leading-6 text-gray-900">{title}</label>
            )}

            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex gap-4 items-center justify-center">
                {addEnabled && (
                    <div {...getRootProps()} className="block text-sm font-semibold">
                        <input {...getInputProps()} id={`${label.toLowerCase()}-video-upload`} name="videos" />
                        {isDragActive ? (
                            <span className="text-[var(--PrimaryColor)]">{t('dragNdropFileHere')}</span>
                        ) : (
                            <span className="text-[var(--PrimaryColor)]">
                                {t('clickToSelectFiles')}
                            </span>
                        )}
                    </div>
                )}

                {videos.length > 0 &&
                    <div className="block text-sm font-semibold flex flex-col gap-4 items-center justify-center">
                        {videos.length > maxSize + 1 && (
                            <span className="text-[var(--tiger-lilly)]">
                                Can't upload more than {maxSize} video! <br />
                                <span>
                                    Please delete <b>{videos.length - maxSize}</b> of them.
                                </span>
                            </span>
                        )}

                        <span className="text-[var(--SecondaryColor)]">
                            TOTAL {videos.length} VIDEO{videos.length === 1 ? '' : 'S'}
                        </span>
                    </div>
                }
            </div>

        </div >
    )
}

export default VideoUpload;
