import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';

import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { hasGradeFetchAuthority, securedFetchGrades } from '../../../services/BatchService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';

import { BiFilterAlt } from 'react-icons/bi'
import { securedFetchTagsByFilter } from '../../../services/TagService';
import { hasRecordedVideoCreateAuthority } from '../../../services/VideoService';
import { TbLockAccess } from 'react-icons/tb';
import VideoRequests from '../../Requests/VideoRequests';
import TailwindButton from '../../Common/Tailwind/TailwindButton';
import VideoRequestsPopup from '../../Requests/VideoRequestsPopup';
import { FunnelIcon, LockClosedIcon } from '@heroicons/react/24/outline';

const SlotCardListFilter = ({ onSubmitFilter = null, syllabusFilter = null, user = ADMIN_USER_TYPE }) => {

    // { searchFilter = null, setSearchFilter, onSubmitFilter, allowedTypes = null, , hiddenFilters = null, refreshKey = 0 }

    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 780);
    const [queryString, setQueryString] = useState('');
    const [isAccessRequestsOpen, setAccessRequestsOpen] = useState(false);
    const { t } = useTranslation();

    const onFilterClick = async () => {
        const filter = {
            ...syllabusFilter,
            queryString
        }
        if (onSubmitFilter) {
            await onSubmitFilter(filter);
        }
    }

    const renderAccessRequests = () => {
        return <VideoRequests refresh={isAccessRequestsOpen} types={['class_lecture']} user={user} />
    }

    useEffect(() => {
        const handleResize = () => {
            setIsNarrowScreen(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const renderDetailedLectureFilter = () => {
        return (
            <div className="flex flex-col justify-center items-center w-full gap-2 md:gap-4">
                <div className="w-full flex flex-col md:flex-row gap-2 md:gap-4">
                    <div className="flex w-full items-center gap-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                            {t('text')}
                        </label>
                        <input
                            className="block flex-1 rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                            type="text"
                            value={queryString}
                            onChange={e => setQueryString(e.target.value)}
                            placeholder={`${t('searchText')}...`} />
                    </div >
                </div>
            </div>
        )
    }

    const renderFilterButtons = () => {
        return (
            <div className="absolute right-4 flex justify-center items-center gap-4">
                <button className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                    onClick={onFilterClick}>
                    <span>{t('filter')}</span>
                    <FunnelIcon aria-hidden="true" className="h-4 w-4" />
                </button>
                {!hasRecordedVideoCreateAuthority(user) ? (<></>) : (
                    <>
                        <span>{t('or')}</span>
                        <button className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                            onClick={() => setAccessRequestsOpen(true)} >
                            <span>{t('checkRequests')} </span>
                            <LockClosedIcon aria-hidden="true" className="h-4 w-4" />
                        </button>
                    </>
                )}
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-center items-center w-full gap-2 md:gap-4" >

            {/* Filter */}
            {renderDetailedLectureFilter()}

            <div className="relative px-2 py-4 mb-4 w-full flex flex-col md:flex-row items-center gap-2 md:gap-4">
                {/* Filter Button */}
                {renderFilterButtons()}
            </div>

            <VideoRequestsPopup
                isOpen={isAccessRequestsOpen}
                onRequestClose={() => setAccessRequestsOpen(false)}
                refresh={isAccessRequestsOpen}
                types={['class_lecture']}
                user={user} />
        </div>
    );
};

export default SlotCardListFilter;