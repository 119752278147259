import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import allLocales from '@fullcalendar/core/locales-all'
import { useTranslation } from 'react-i18next';
import 'moment/min/locales.min'

//FullCalendar
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { formatDate } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import i18n from '../../multi-language/i18n'; // Import the i18n instance

import '../Common/CalendarStyles.css';

import Logo from "../../Assets/LiveClass.png";

import BatchScheduleCreate from './BatchScheduleCreate';
import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { hasTimetableScheduleCreateAuthority, needsSlotPinAuthentication, securedDeleteSlotById, securedFetchSlotStatus } from '../../services/TimetableService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { updateSearchParams } from '../../services/CommonService';
import { addISTOffset } from '../../services/DateService';

const BatchTimetableFullCalendar = ({ eventsList, batch = null, onScheduleCreate = null, onSlotDelete = null, onSlotEdit = null, onViewTimetable = null, user = ADMIN_USER_TYPE, dateRange, setDateRange, replaceSearchParams = true }) => {

    const calendarRef = useRef(null);
    const [currentEvents, setCurrentEvents] = useState(eventsList);
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [isAddEventModalOpen, setAddEventModalOpen] = useState(false);
    const [selectedEventData, setSelectedEventData] = useState({});
    const [isTabletView, setIsTabletView] = useState(window.innerWidth < 860);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentView, setCurrentView] = useState(searchParams.get('viewType') || (isMobileView ? 'timeGridDay' : 'timeGridWeek'));
    const { t } = useTranslation();

    useEffect(() => {
        if (!calendarRef.current) {
            return;
        }
        queueMicrotask(() => {
            calendarRef.current.getApi().setOption('locale', i18n.language);
        });
    }, [i18n.language]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!calendarRef.current || !dateRange.startDate || !dateRange.endDate) {
            return;
        }
        const calendarApi = calendarRef.current.getApi();
        const view = calendarApi.view;
        const calendarStartDate = moment(view.currentStart).format('YYYY-MM-DD');
        const calendarEndDate = moment(view.currentEnd).subtract(1, 'seconds').format('YYYY-MM-DD');
        changeSearchParams();
        const calculatedCurrentView = calculateCurrentView();
        setCurrentView(calculatedCurrentView);
        if (!dateRange.startDate || !dateRange.endDate ||
            (calendarStartDate === dateRange.startDate && calendarEndDate === dateRange.endDate)) {
            return;
        }

        setTimeout(() => {
            calendarApi.changeView(calculatedCurrentView, dateRange.startDate);
        }, 0);
    }, [dateRange]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 860) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }

            if (window.innerWidth < 600) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        // Set the sidebar state based on the initial window width
        handleResize();
        if (!searchParams.get('viewType')) {
            setCurrentView(calculateCurrentView());
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const changeSearchParams = () => {
        if (!dateRange.startDate || !dateRange.endDate) {
            return;
        }
        if (searchParams.get('startDate') !== dateRange.startDate || searchParams.get('endDate') !== dateRange.endDate) {
            updateSearchParams(setSearchParams, searchParams, { startDate: dateRange.startDate, endDate: dateRange.endDate, viewType: calculateCurrentView() }, replaceSearchParams);
        }
    };

    const handleEvents = async (events) => {
        const currentEventIds = events.map(event => parseInt(event.id));
        setCurrentEvents(eventsList.filter(event => currentEventIds.includes(event.id)));
    }

    const handleEventClick = async (clickInfo) => {
        await handleEventClickById(clickInfo.event.id);
    }

    const handleEventClickById = async (eventId) => {
        navigate(`slots/${eventId}`)
    }

    const handleSlotSelect = (selectInfo) => {
        setAddEventModalOpen(true);
        setSelectedEventData(
            {
                start: selectInfo.start,
                end: selectInfo.end,
                allDay: selectInfo.allDay
            }
        )
    }

    const calculateCurrentView = () => {
        if (!dateRange.startDate) {

            if (isMobileView) {
                return 'timeGridDay';
            } else {
                return 'timeGridWeek';
            }
        }

        const duration = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days');
        if (duration === 0) {
            return 'timeGridDay';
        }
        if (duration <= 7) {
            return 'timeGridWeek';
        }
        return 'dayGridMonth';
    }

    const getInitialView = () => {
        if (!dateRange.startDate) {

            if (isMobileView) {
                setCurrentView('timeGridDay');
                return;
            } else {
                setCurrentView('timeGridWeek');
                return;
            }
        }

        const duration = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days');
        if (duration === 0) {
            setCurrentView('timeGridDay');
            return;
        }
        if (duration <= 7) {
            setCurrentView('timeGridWeek');
            return;
        }
        setCurrentView('dayGridMonth');
        return;
    };

    function CalendarSidebar({ sidebarLogo, sidebarTitle, currentEvents }) {
        return (
            <div className="flex flex-row items-center md:items-start md:flex-col w-full md:w-48 md:py-5 border-b md:border-b-0 md:border-r pb-1 md:pb-0">
                {/* logo */}
                <div className="flex flex-col md:w-full justify-center items-center md:min-h-40">
                    <img className="hidden md:block w-auto h-40" src={sidebarLogo} alt="Logo" />
                    <label className="font-semibold text-[var(--SecondaryColor)] text-xl uppercase px-4 md:px-0 whitespace-nowrap">{sidebarTitle}</label>
                </div>

                <div class="px-2 py-1 rounded-xl md:p-0 md:my-4 flex items-center gap-2 md:w-full md:h-10 font-semibold text-base md:text-lg text-white bg-[var(--PrimaryBkg)] transition-all duration-300 ease-in-out whitespace-nowrap">
                    <div class="hidden md:block w-2 h-full bg-[var(--PrimaryColor)] mr-2"></div>
                    {currentEvents.length} {currentEvents.length > 1 ? t("lectures") : t("lecture")}
                </div>

                <div className="flex flex-col flex-1 w-full h-full gap-4 overflow-y-auto overflow-x-hidden text-[var(--textColor)]">
                    <ul className="w-full flex flex-row md:flex-col justify-start items-start gap-2 leading-[1.5] pl-2 overflow-x-auto [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
                        {currentEvents.map(renderSidebarEvent)}
                    </ul>
                </div>
            </div >
        );
    }

    // a custom render function
    function renderEventContent(eventInfo) {
        return (
            <div className="w-full h-full flex flex-col sm:flex-row justify-center items-center whitespace-nowrap overflow-x-auto overflow-y-auto scrollbar-hide text-[var(--textColor)] text-xs gap-1">
                <span className="text-center">
                    {addISTOffset(moment(eventInfo.event.start)).locale(i18n.language).format(t('hcommA'))}
                </span>

                <span className={`text-center px-1 rounded text-white ${eventInfo.event.title[0] === 'M'
                    ? 'bg-[var(--Mathematics)]'
                    : eventInfo.event.title[0] === 'P'
                        ? 'bg-[var(--Physics)]'
                        : 'bg-[var(--Chemistry)]'
                    }`}
                >
                    {eventInfo.event.title[0]}
                </span>
                {!(currentView === 'dayGridMonth' && isTabletView) &&
                    <i>
                        {eventInfo.event.title.slice(1)}
                    </i>
                }
            </div>
        )
    }

    function renderSidebarEvent(event) {
        return (
            <li key={event.id}
                className="w-full whitespace-nowrap text-xs md:text-sm text-[var(--textColor)] hover:cursor-pointer hover:font-semibold"
                onClick={() => handleEventClickById(event.id)}>
                {isTabletView ? (
                    <b>
                        {moment(event.start)
                            .locale(i18n.language)
                            .format(t('hcommADDslMMslYY'))}
                    </b>
                ) : (
                    <>
                        <b>
                            {moment(event.start)
                                .locale(i18n.language)
                                .format(t('hcommADDMMMMYYYY'))}
                        </b>
                        <br />
                    </>
                )}

                <span className="flex items-center gap-1">
                    <b className={`text-center px-1 rounded-md text-white ${event.subject_name === 'Mathematics'
                        ? 'bg-[var(--Mathematics)]'
                        : event.subject_name === 'Physics'
                            ? 'bg-[var(--Physics)]'
                            : 'bg-[var(--Chemistry)]'
                        }`}
                    >
                        {event.subject_name[0]}
                    </b>
                    <i>{event.teacher_name}</i>
                </span>
            </li>
        );
    }

    const onDatesSet = async (dateInfo) => {
        const newStartDate = moment(dateInfo.view.currentStart).format('YYYY-MM-DD');
        const newEndDate = moment(dateInfo.view.currentEnd).subtract(1, 'seconds').format('YYYY-MM-DD');
        if (newStartDate !== dateRange.startDate || newEndDate !== dateRange.endDate) {
            setDateRange({
                startDate: newStartDate,
                endDate: newEndDate
            })
        }
    }

    if (user === ADMIN_USER_TYPE && !batch.id) {
        return;
    }

    return (

        <div className="flex flex-col md:flex-row w-full bg-white overflow-hidden">
            <CalendarSidebar sidebarLogo={Logo} sidebarTitle="Live Class" currentEvents={currentEvents} />

            <main className="w-screen min-h-screen px-4 py-2">

                <FullCalendar
                    ref={calendarRef}
                    locales={allLocales}
                    locale={i18n.language}
                    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    headerToolbar={{
                        left: 'today,prev,next',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    // view={['dayGridMonth,timeGridWeek,timeGridDay']}
                    height="auto" // This makes the calendar height responsive
                    initialDate={dateRange.startDate || moment().format('YYYY-MM-DD')}
                    initialView={currentView}
                    events={eventsList}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                    eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                    eventOverlap={false}
                    editable={user === ADMIN_USER_TYPE ? true : false}
                    selectable={true}
                    selectMirror={true}
                    selectMinDistance={2}
                    weekends={weekendsVisible}
                    nowIndicator={true}
                    allDaySlot={false}
                    slotMinTime="6:00:00"
                    slotMaxTime="23:00:00"
                    select={handleSlotSelect}
                    duration="00:30:00"
                    dayMaxEventRows={true}
                    dayHeaderContent={(args) => {
                        const day = t(moment(args.date).format('ddd'));
                        const date = moment(args.date).format(t('DDslMM'));

                        if (args.view.type === 'timeGridWeek') {
                            return (
                                <span>
                                    {day}
                                    <br />
                                    {date}
                                </span>
                            );
                        }
                        else { return (<span>{day}</span>) };
                    }}
                    titleFormat={(args) => {
                        // Format the start and end dates
                        const startDate = moment(args.start);
                        const endDate = moment(args.end);
                        const diffDays = endDate.diff(startDate, 'days');

                        if (startDate.format('ddd D/M') === endDate.format('ddd D/M')) {
                            if (isMobileView) {
                                // return startDate.format(t'DD/MM/YY');
                                return startDate.format(t('DDslMMslYY'));
                            } else {
                                // return startDate.format('D MMMM YYYY'); // Return only one date if they are the same
                                return startDate.format(t('DMMMMYYYY'));
                            }
                        } else if (diffDays > 7) {
                            // return startDate.format('MMMM YYYY');
                            return startDate.format(t('MMMMYYYY'));
                        } else {
                            if (startDate.format('YYYY') !== endDate.format('YYYY')) {
                                if (isMobileView) {
                                    // return `${startDate.format('D/M/YY')}-${endDate.format('D/M/YY')}`; // Return the date range if they are different
                                    return `${startDate.format(t('DslMslYY'))}-${endDate.format(t('DslMslYY'))}`;
                                } else {
                                    return `${startDate.format(t('DMYYYY'))}-${endDate.format(t('DMYYYY'))}`; // Return the date range if they are different
                                }
                            } else if (startDate.format('MM') !== endDate.format('MM')) {
                                if (isMobileView) {
                                    return `${startDate.format(t('DslMsl'))}-${endDate.format(t('DslMsl'))} ${endDate.format(t('YY'))}`; // Return the date range if they are different
                                } else {
                                    return `${startDate.format(t('DslMsl'))}-${endDate.format(t('DslMsl'))} ${endDate.format(t('YYYY'))}`; // Return the date range if they are different

                                }
                            } else {
                                if (isMobileView) {
                                    return `${startDate.format(t('D'))}-${endDate.format(t('D'))}/${endDate.format(t('MM'))}/${endDate.format(t('YY'))}`;
                                } else {
                                    return `${startDate.format(t('D'))}-${endDate.format(t('D'))} ${endDate.format(t('SuffixMMMMYYYY'))}`; // Return the date range if they are different
                                }
                            }
                        }
                    }}
                    datesSet={onDatesSet}
                />



                {hasTimetableScheduleCreateAuthority(user) && (
                    <BatchScheduleCreate
                        isOpen={isAddEventModalOpen}
                        onRequestClose={() => (setAddEventModalOpen(false))}
                        startInput={selectedEventData.start}
                        endInput={selectedEventData.end}
                        batchId={batch ? batch.id : null}
                        grade={batch ? batch.batch_grade : null}
                        onScheduleCreate={onScheduleCreate}
                        user={user}
                    />
                )}
            </main>
        </div>
    )
};

export default BatchTimetableFullCalendar;