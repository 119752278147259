import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconSprite from '../../../Assets/Icon-sprite.png';
import { useNTATest } from './NTATestContext';
import NTAInstructions from './NTAInstructions';
import StudentTestReport from '../TestReport/StudentTestReport';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import NTATestPaper from './NTATestPaper';
import NTAAnswersCountsTooltip from './NTAAnswersCountsTooltip';
import { TEST_CREATE_TYPE_BASIC, TEST_CREATE_TYPE_VIRTUAL, securedStartTest } from '../../../services/TestService';
import { ArrowPathIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline';
import Notification from '../../Common/Tailwind/Notification/Notification';

const NTATestHeader = ({ user = STUDENT_USER_TYPE, onRefreshClick = null, setEvaluatedNow }) => {

    const [isTestReportModalOpen, setTestReportModalOpen] = useState(false);
    const [isInstructionModalOpen, setInstructionModalOpen] = useState(false);
    const [isRefreshTestAlertOpen, setRefreshTestAlertOpen] = useState(false);
    const [isPaperModalOpen, setPaperModalOpen] = useState(false);
    const [tooltipPopup, setTooltipPopup] = useState(null);
    const { t } = useTranslation();

    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        hasAgreedInstructions,
        testCreateType
    } = useNTATest();

    const onTestReportFetch = async () => {
        setTestReportModalOpen(true);
    }

    const showTestStatusInProgress = () => {
        return testStatus === 'STARTED';
    }

    const showTestStatusCompleted = () => {
        return testStatus === 'FINISHED';
    }

    const showTestStatusReady = () => {
        return testStatus === 'NOT STARTED';
    }

    const showTestReportComponent = () => {
        return testStatus === 'FINISHED';
    }

    const canRefreshTest = () => {

        if (testStatus === 'NOT STARTED' && testStatus !== null && user === STUDENT_USER_TYPE && testCreateType === TEST_CREATE_TYPE_BASIC) {
            setRefreshTestAlertOpen(true);
            return true;
        }
        return false;
    }

    const onStartTest = async () => {
        const sendTestDuration = testCreateType !== TEST_CREATE_TYPE_VIRTUAL ? testData.test_duration_minutes : testData.virtual_test_duration_minutes;
        const response = await securedStartTest(testCreateType, testData.id, { test_duration_minutes: sendTestDuration });
        if (response === null) {
            return;
        }
        setTestStatus('STARTED');
        onRefreshClick();
    }

    const canStartTest = () => {
        return testStatus === 'NOT STARTED' && testStatus !== null && testCreateType !== TEST_CREATE_TYPE_BASIC;
    }

    return (
        <div className="w-full p-2 bg-black flex justify-between items-center gap-4 flex-shrink-0 roboto-medium">

            {/* Test Name  */}
            <div className="felx gap-1 items-center text-yellow-500 font-semibold">
                <span>{testData.test_name}</span>
                <span
                    className="w-[18px] h-[18px] bg-no-repeat"
                    style={{
                        backgroundImage: `url(${IconSprite})`,
                        backgroundPosition: '-140px -7px',
                    }}
                    onMouseEnter={() => setTooltipPopup(true)}
                    onMouseLeave={() => setTooltipPopup(false)} >
                    {tooltipPopup &&
                        <NTAAnswersCountsTooltip />}
                </span>
            </div>

            {/* Test Refresh */}
            {canStartTest() && (
                <div className="relative flex gap-1 roboto-bold text-white" onClick={onStartTest}>
                    <span>{t('clickToStartTest')}</span>
                    <div className="px-2 py-1 rounded-md bg-red-100 text-red-700">
                        <span>{t('startTestCapital')}</span>
                        <ArrowPathIcon className="w-4 h-4" />
                    </div>
                </div>
            )}

            {canRefreshTest() && (
                <Notification
                    isOpen={isRefreshTestAlertOpen}
                    onClose={() => setRefreshTestAlertOpen(false)}
                    type={'global-red'}
                    message={t('keepClickingRefreshButton')}
                    buttonText2={t('refreshCapital')}
                    onButton2Click={onRefreshClick} />

                // <div className="test-refresh roboto-bold flex" onClick={onRefreshClick}>
                //     <p>{t('keepClickingRefreshButton')}</p>
                //     <p className="msg" id="started">{t('refreshCapital')} <FiRefreshCcw className="icon" /> </p>
                // </div>
            )}

            {/* Test Report */}
            {showTestReportComponent() && (
                <div className="relative flex gap-1 px-2 py-1 rounded-md bg-green-500 text-white roboto-medium items-center cursor-pointer" onClick={onTestReportFetch} >
                    <span className="text-sm">{t('testReportCapital')} </span>
                    <ChartBarSquareIcon className="w-5 h-5" />
                </div>
            )}

            <StudentTestReport
                isOpen={isTestReportModalOpen}
                onRequestClose={() => (setTestReportModalOpen(false))}
                testId={testData.id}
                testName={testData.test_name}
                studentId={testData.student_id || null}
                user={user}
                testCreateType={testCreateType}
                setEvaluatedNow={setEvaluatedNow} />

            {showTestStatusInProgress() && (
                <div className="relative flex gap-1 roboto-bold rounded-md px-2 py-1 bg-red-100 text-red-500">
                    <span className="text-sm font-semibold">{t('inProgressCapital')}</span>
                </div>
            )}

            {showTestStatusCompleted() && (
                <div className="relative flex gap-1 roboto-bold rounded-md px-2 py-1 bg-blue-100 text-blue-500" >
                    <span className="text-sm font-semibold">{t('completedCapital')}</span>
                </div>
            )}

            {showTestStatusReady() && (
                <div className="relative flex gap-1 roboto-bold rounded-md px-2 py-1 bg-green-100 text-green-500" >
                    <span className="text-sm font-semibold">{t('readyCapital')}</span>
                </div>
            )}

            {/* <button className='btn flex' id='submit' >
                INFO <AiFillInfoCircle className="icon" />
            </button> */}

            {/* Test Instruction */}
            {testStatus === "STARTED" &&
                <div className='test-header-popup flex'>
                    <div className="test-instruction flex" onClick={() => setPaperModalOpen(true)}>
                        <span className="instruction_icon"></span>
                        <span>{t('viewPaper')}</span>
                    </div>
                    <NTATestPaper isOpen={isPaperModalOpen} onRequestClose={() => { setPaperModalOpen(false) }} parentId='NTA-tests-glass' />
                    <div className="test-instruction flex" onClick={() => setInstructionModalOpen(true)}>
                        <span className="instruction_icon"></span>
                        <span>{t('viewInstructions')}</span>
                    </div>
                    <NTAInstructions isOpen={isInstructionModalOpen} onRequestClose={() => { setInstructionModalOpen(false) }} infoOnly={true} testData={testData} parentId='NTA-tests-glass' />
                </div>
            }
        </div>
    );
}


export default NTATestHeader;
