import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ImagePreview from '../../../Common/ImagePreview'
import ImageUpload from '../../../Common/ImageUpload'
import QuestionTypeSingleChoice from './QuestionTypeSingleChoice';
import QuestionTypeMultiChoice from './QuestionTypeMultiChoice';
import { QuestionFormContext } from '../QuestionFormContext';
import { QUESTION_DIFFICULTIES, securedPostParagraph } from '../../../../services/QuestionService';
import { ADMIN_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';

import { RiDeleteBin5Line } from 'react-icons/ri'
import { MdAdd } from 'react-icons/md';
import { securedUpdateParagraph } from '../../../../services/QuestionService';
import QuestionTypeSubjective from './QuestionTypeSubjective';
import { replaceImagesInObject } from '../../../../services/CommonService';
import { ArrowUpTrayIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';

const questionReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_QUESTION':
            return [...state, action.payload];
        case 'REMOVE_QUESTION':
            return state.filter((question) => question.id !== action.payload);
        case 'UPDATE_QUESTION':
            return state.map((question) =>
                question.id === action.payload.id ? { ...question, ...action.payload } : question
            );
        case 'RESET':
            return [];
        default:
            return state;
    }
};

const QuestionTypeParagraph = ({ inputParagraphData = { id: null }, isImageBased, topics, tags, onRequestClose = null, onAddQuestion = null, isQuestionDataValid, setIsSaving, user = ADMIN_USER_TYPE }) => {
    const [paragraph, setParagraph] = useState('')
    const [paragraphImages, setParagraphImages] = useState([])
    const [questions, dispatch] = useReducer(questionReducer, []);
    const [difficulty, setDifficulty] = useState(QUESTION_DIFFICULTIES[0]);
    const [questionImageFileNames, setQuestionImageFileNames] = useState([]);

    const navigate = useNavigate();
    const t = useTranslation();

    const resetState = () => {
        setParagraph('');
        setParagraphImages([]);
        dispatch({ type: 'RESET' });
    }

    const getInputQuestionData = (questionId) => {
        if (inputParagraphData.id === null) {
            return { id: null };
        }
        const inputQuestion = inputParagraphData.questions.find(question => question.question_id === questionId);
        if (!inputQuestion) {
            return { id: null };
        }
        return inputQuestion.question;
    }

    useEffect(() => {
        if (inputParagraphData.id === null) {
            return;
        }
        setParagraph(inputParagraphData.paragraph_text);
        dispatch({ type: 'RESET' });
        inputParagraphData.questions.forEach(question => dispatch({ type: 'ADD_QUESTION', payload: { id: question.question_id, type: question.question.question_subtype } }));
        (async () => {
            setParagraphImages((await replaceImagesInObject(inputParagraphData.images, navigate)).map(image => image.image.url), navigate);
        })();

        setDifficulty(QUESTION_DIFFICULTIES.find(difficulty => difficulty.id === inputParagraphData.paragraph_difficulty));

    }, [inputParagraphData.id]);

    const addQuestion = (type) => {
        const newQuestion = {
            id: Math.random().toString(),
            type: type,
            data: {}
        };

        dispatch({ type: 'ADD_QUESTION', payload: newQuestion });
    };

    const removeQuestion = (id) => {
        dispatch({ type: 'REMOVE_QUESTION', payload: id });
    };

    const onTextChange = (inputText) => (event) => {
        const { value } = event.target;
        inputText(value);
    };

    // const onDifficultyChange = (selection) => {
    //     setDifficulty({ id: selection.value, name: selection.label });
    // }

    const handleDifficultyClick = (selection) => {
        setDifficulty({ id: selection.id, name: selection.name });
    }

    const areQuestionsValid = () => {
        return questions.every(question => isSingleQuestionValid(question));
    }

    const isSingleQuestionValid = (question) => {
        if (isImageBased) {
            if (question.type === 'single choice') {
                return question.data.questionImages.length > 0 && question.data.answerInputs.every(str => str.value.trim() !== '');
            }
            if (question.type === 'multi choice') {
                return question.data.questionImages.length > 0 && question.data.answerInputs.every(str => str.value.trim() !== '') && question.data.selectedAnswers.some(selectedAnswer => (selectedAnswer));
            }
            if (question.type === 'numerical') {
                return question.data.questionImages.length > 0 && question.data.answer.min_value != null;
            }
            return question.data.questionImages.length > 0 && question.data.answer.value.length > 0;
        }
        if (question.type === 'single choice') {
            return question.data.question.length > 0 && question.data.answerInputs.every(str => str.value.trim() !== '');
        }
        if (question.type === 'multi choice') {
            return question.data.question.length > 0 && question.data.answerInputs.every(str => str.value.trim() !== '') && question.data.selectedAnswers.some(selectedAnswer => (selectedAnswer));
        }
        if (question.type === 'numerical') {
            return question.data.question.length > 0 && question.data.answer.min_value != null;
        }
        return question.data.question.length > 0 && question.data.answer.value.length > 0;
    }

    const isInternalDataInvalid = () => {
        if (!isImageBased) {
            return !isQuestionDataValid() || !areQuestionsValid() || paragraph.length === 0 || questions.length === 0;
        }
        return !isQuestionDataValid() || !areQuestionsValid() || paragraphImages.length === 0 || questions.length === 0;
    }

    const getParagraphQuestionId = (question) => {
        if (inputParagraphData.id === null) {
            return null;
        }
        const initialQuestion = inputParagraphData.questions.find(paragraphQuestion => paragraphQuestion.question_id === question.id);
        if (!initialQuestion) {
            return null;
        }
        return initialQuestion.id;
    }

    const handleSubmit = async () => {
        if (isInternalDataInvalid()) {
            return;
        }
        setIsSaving(true);

        let questionsWithIds;
        if (inputParagraphData.id !== null) {
            questionsWithIds = questions.map(question => ({ ...question, paragraph_question_id: getParagraphQuestionId(question) }));
        } else {
            questionsWithIds = questions;
        }
        const paragraphData = {
            paragraph: !isImageBased ? paragraph : '',
            questions: questionsWithIds,
            topics: topics,
            tags: tags,
            paragraphImages: paragraphImages,
            difficulty: difficulty.id
        };
        let response;
        if (inputParagraphData.id !== null) {
            response = await securedUpdateParagraph(inputParagraphData.id, paragraphData, navigateCallbackOptions(navigate));
        } else {
            response = await securedPostParagraph(paragraphData, navigateCallbackOptions(navigate));
        }
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onAddQuestion) {
            onAddQuestion('paragraph', response.paragraph_id);
        }
        resetState();
        setIsSaving(false);
        if (onRequestClose != null) {
            onRequestClose();
        }
    };

    const renderSingleQuestionCreate = (question) => {
        if (question.type === 'single choice') {
            return <QuestionTypeSingleChoice inputQuestionData={getInputQuestionData(question.id)} id={question.id} isImageBased={isImageBased} topics={topics} tags={tags} overallDifficulty={difficulty} hasSubmit={false} />;
        }
        if (question.type === 'multi choice') {
            return <QuestionTypeMultiChoice inputQuestionData={getInputQuestionData(question.id)} id={question.id} isImageBased={isImageBased} topics={topics} tags={tags} overallDifficulty={difficulty} hasSubmit={false} />
        }
        return <QuestionTypeSubjective type="numerical" inputQuestionData={getInputQuestionData(question.id)} id={question.id} isImageBased={isImageBased} topics={topics} tags={tags} overallDifficulty={difficulty} hasSubmit={false} />;
    }

    const renderDeleteButton = (question) => {
        if (inputParagraphData.id !== null && inputParagraphData.questions.find(paragraphQuestion => paragraphQuestion.question_id === question.id)) {
            return null;
        }
        return (
            <button
                className="absolute top-0 left-7 w-7 h-7 rounded-lg text-lg bg-red-400 hover:bg-red-500 shadow-md font-semibold text-white flex items-center justify-center"
                onClick={() => removeQuestion(question.id)}>
                <TrashIcon className="w-5 h-5" />
            </button>
        );
    }

    return (

        <div className="flex flex-col items-start sm:items-center justify-start gap-4 w-full">

            <QuestionFormContext.Provider value={{ questions, dispatch }}>

                {/* Paragraph Input */}
                {!isImageBased && (
                    <div className="flex items-center justify-start gap-2 w-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Paragraph</label>
                        <textarea className="w-full h-auto text-base py-1 px-2.5 text-textColor border border-gray-300 rounded-lg leading-5"
                            placeholder="Enter..." onChange={onTextChange(setParagraph)} value={paragraph} rows="4" required />
                    </div>)}

                {/* Image Input */}
                <ImageUpload title='Paragraph Image(s)' images={paragraphImages} selectImages={setParagraphImages} label="paragraph" maxSize={isImageBased ? 1 : null} setImageFileNames={setQuestionImageFileNames} />
                <ImagePreview images={paragraphImages} selectImages={setParagraphImages} imageFileNames={questionImageFileNames} />

                {/* Difficulty Selection */}
                <div className="flex items-center justify-start gap-2 w-full">
                    <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">Difficulty</label>
                    <div className="flex space-x-4 px-1">
                        {QUESTION_DIFFICULTIES.map((difficulty_option) => (
                            <button
                                key={difficulty_option}
                                className={`rounded-md px-3 py-2 text-sm font-medium ${difficulty.id === difficulty_option.id ? 'bg-[var(--PrimaryColor)] text-white' : 'text-gray-500 hover:text-gray-700'} flex`}
                                onClick={() => handleDifficultyClick(difficulty_option)}
                            >
                                {difficulty_option.name}
                            </button>
                        ))}
                    </div>

                </div>
                {/* <SelectionDropdown className="questionSelectionDropdown" name="Difficulty" itemList={QUESTION_DIFFICULTIES} selectedItem={difficulty} onSelectionChange={onDifficultyChange} nameField='name' valueField='id' required={true} /> */}

                {questions.map((question, idx) => (
                    <div className="relative bg-gray-100 px-2 py-4 rounded-lg w-full" key={question.id}>
                        <span className="absolute top-0 left-0 w-7 h-7 rounded-lg text-lg bg-[var(--SecondaryColor)] shadow-md font-semibold text-white flex items-center justify-center">{idx + 1}</span>
                        {renderSingleQuestionCreate(question)}
                        {renderDeleteButton(question)}
                    </div>
                ))}

                <div className="grid grid-cols-3 gap-4">
                    <button className="relative block w-full flex justify-center items-center gap-2 rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
                        onClick={() => addQuestion('single choice')}>
                        <PlusCircleIcon className="h-4 w-4 text-gray-900" />
                        <span className="block text-sm font-semibold text-gray-900">Single Choice Question</span>
                    </button>
                    <button className="relative block w-full flex justify-center items-center gap-2 rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
                        onClick={() => addQuestion('multi choice')}>
                        <PlusCircleIcon className="h-4 w-4 text-gray-900" />
                        <span className="block text-sm font-semibold text-gray-900">Multiple Choice Question</span>
                    </button>
                    <button className="relative block w-full flex justify-center items-center gap-2 rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-gray-400 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
                        onClick={() => addQuestion('numerical')}>
                        <PlusCircleIcon className="h-4 w-4 text-gray-900" />
                        <span className="block text-sm font-semibold text-gray-900">Numerical Question</span>
                    </button>
                </div>

                <div>
                    <button className="mt-2 flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                        id='submit' onClick={handleSubmit}>
                        <span>UPLOAD</span>
                        <ArrowUpTrayIcon aria-hidden="true" className="h-4 w-4 text-white" />
                    </button>
                </div>

            </QuestionFormContext.Provider>

        </div>

    );
};

export default QuestionTypeParagraph;