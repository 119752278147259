import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TestRemainingTime from './TestRemainingTime';
import TestReport from './TestReport/TestReport';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { TbReport } from 'react-icons/tb';
import { FiRefreshCcw } from 'react-icons/fi';
import { ArrowPathIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

const TestHeader = ({ testData, testStatus, setTestStatus, user = ADMIN_USER_TYPE, refreshKey = 0, onRefreshClick = null }) => {

    const [isTestReportModalOpen, setTestReportModalOpen] = useState(false);
    const { t } = useTranslation();

    const onTestReportFetch = async () => {
        setTestReportModalOpen(true);
    }

    const showTestStatusInProgress = () => {
        return testStatus === 'STARTED';
    }

    const showTestStatusCompleted = () => {
        return testStatus === 'FINISHED';
    }

    const showTestStatusReady = () => {
        return user !== STUDENT_USER_TYPE && testStatus === 'NOT STARTED';
    }

    const showTestReportComponent = () => {
        return user !== STUDENT_USER_TYPE && testStatus === 'FINISHED';
    }

    const canRefreshTest = () => {
        return testStatus !== 'STARTED' && testStatus !== null && user === STUDENT_USER_TYPE;
    }


    return (
        <div className="w-full flex justify-start items-center gap-1 p-2">
            {/* Test Status  */}
            {showTestStatusInProgress() ? (
                <span className="bg-red-500 text-white text-xs font-medium rounded-lg px-2 py-1">
                    {t('inProgressCapital')}
                </span>
            ) : showTestStatusCompleted() ? (
                <span className="bg-blue-500 text-white text-xs font-medium rounded-lg px-2 py-1">
                    {t('completedCapital')}
                </span>
            ) : showTestStatusReady() ? (
                <span className="bg-green-500 text-white text-xs font-medium rounded-lg px-2 py-1">
                    {t('readyCapital')}
                </span>
            ) : (<></>)}

            {/* Test Report */}
            {showTestReportComponent() && (
                <>
                    <button className="flex justify-center items-center gap-1 bg-orange-500 text-white text-xs font-medium rounded-lg px-2 py-1"
                        onClick={onTestReportFetch} >
                        <span>Test Report</span>
                        <ClipboardDocumentListIcon className="w-6 h-6" />
                    </button>

                    <TestReport isOpen={isTestReportModalOpen} onRequestClose={() => (setTestReportModalOpen(false))} testId={testData.id} testName={testData.test_name} user={user} />
                </>
            )}

            {/* Test Refresh */}
            {canRefreshTest() && (
                <button className="flex justify-center items-center gap-1 bg-indigo-500 text-white text-xs font-medium rounded-lg px-2 py-1"
                    onClick={onRefreshClick}>
                    <span>refresh</span>
                    <ArrowPathIcon className="w-6 h-6" />
                </button>
            )}

            {/* Test Timer */}
            <TestRemainingTime id={testData.id} testData={testData} testStatus={testStatus} setTestStatus={setTestStatus} user={user} refreshKey={refreshKey} />
        </div >
    );
}


export default TestHeader;
