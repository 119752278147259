import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { securedFetchVideoAccessRequestsByFilter, securedUpdateVideoAccessRequestDecision } from "../../services/VideoService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { useNavigate } from "react-router-dom";
import VideoViewerPopup from "../Files/Video/VideoViewerPopup";
import { ADMIN_USER_TYPE } from "../../services/UserService";
import Spinner from "../Common/Tailwind/Spinner";

const VideoRequests = ({ types = null, refresh = true, user = ADMIN_USER_TYPE }) => {

    const [accessRequests, setAccessRequests] = useState(null);
    const [isVideoViewOpen, setVideoViewOpen] = useState(false);
    const [videoViewData, setVideoViewData] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onDecideRequest = async (decision, request) => {
        const data = { decision: decision };
        const approvedResponse = await securedUpdateVideoAccessRequestDecision(request.id, data, navigateCallbackOptions(navigate));
        if (approvedResponse === null) {
            return;
        }
        setAccessRequests(prev => prev.filter(req => req.id !== request.id));
    }

    const fetchAndSetWaitingRequests = async () => {
        const filter = { latest_request_statuses: ['waiting'], types: types };
        const accessRequestResponse = await securedFetchVideoAccessRequestsByFilter(filter, navigateCallbackOptions(navigate));
        if (accessRequestResponse === null) {
            return;
        }
        setAccessRequests(accessRequestResponse.data);
    }

    const onClickVideoRequest = (request) => {
        setVideoViewData({ title: request.auto_generated_request_message, video_id: request.video_id, video_type: request.video_type });
        setVideoViewOpen(true);
    }

    useEffect(() => {
        (async () => {
            if (!refresh) {
                return;
            }
            await fetchAndSetWaitingRequests();
        })();
    }, [refresh]);

    if (accessRequests === null) {
        return <Spinner />;
    }
    return (
        <div className="mt-10 sm:mx-auto sm:w-full">

            <table className="w-full text-sm">
                <thead>
                    <tr className="bg-[var(--SecondaryBkg)] text-[var(--textColor)] text-center align-middle">
                        <th className="p-1 leading-relaxed">
                            {t('status')}
                        </th>
                        <th className="p-1 leading-relaxed">
                            {t('requestMessage')}
                        </th>
                        <th className="p-1 leading-relaxed">
                            {t('requestedBy')}
                        </th>
                        <th className="p-1 leading-relaxed">
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {accessRequests.length > 0 && accessRequests.map(request => (
                        <tr key={request.id}
                            onClick={() => onClickVideoRequest(request)}
                            className="hover:font-semibold hover:bg-gray-100 cursor-pointer">
                            <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                {request.status === "approved" ? (
                                    <p className={'bg-green-100 text-green-700 rounded-md px-1 py-1 text-sm font-medium'} >
                                        {t('approved')}
                                    </p>
                                ) : (request.status === "pending" ?
                                    <p className={'flex justify-center items-center gap-1 bg-blue-100 text-blue-700 rounded-md px-1 py-1 text-sm font-medium'}>
                                        {t('pending')}
                                    </p>
                                    :
                                    <p className={'bg-red-100 text-red-700 rounded-md px-3 py-2 text-sm font-medium'}>
                                        {t('denied')}
                                    </p>
                                )}
                            </td>
                            <td scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white cursor-pointer">
                                {request.auto_generated_request_message}
                            </td>
                            <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                {request.requester.first_name}
                            </td>
                            <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                {request.status === "pending" && (
                                    <>
                                        <button
                                            type="button"
                                            className="relative inline-flex items-center rounded-l-md
                                            bg-green-700 hover:bg-green-800 px-5 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300
                                            dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800
                                            focus:z-10"
                                            onClick={() => onDecideRequest('approve', request)}
                                        >
                                            {t('approve')}
                                        </button>

                                        <button
                                            type="button"
                                            className="relative -ml-px inline-flex items-center rounded-r-md 
                                            bg-red-700 hover:bg-red-800 px-5 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300
                                            dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800
                                            focus:z-10"
                                            onClick={() => onDecideRequest('reject', request)}
                                        >
                                            {t('deny')}
                                        </button>
                                    </>
                                )}
                                {/* <span className="isolate inline-flex rounded-md shadow-sm">

                                </span> */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <VideoViewerPopup isOpen={isVideoViewOpen} onRequestClose={() => setVideoViewOpen(false)} videoType={videoViewData?.video_type || null} videoId={videoViewData?.video_id || null} user={user} />

        </div >
    )
}

export default VideoRequests;