import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { TEST_CREATE_TYPE_BASIC, TEST_CREATE_TYPE_VIRTUAL, securedCreateTest, securedFetchAttemptedTests, securedFetchTestStudents, securedFetchTests } from '../../../services/TestService';
import { STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import VirtualTestCreatePopup from '../TestCreate/VirtualTestCreatePopup';
import i18n from '../../../multi-language/i18n';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const StudentTestTable = ({ user = STUDENT_USER_TYPE }) => {
    const [finishedTests, setFinishedTests] = useState([]);
    const [upcomingTests, setUpcomingTests] = useState([]);
    const [currentTests, setCurrentTests] = useState([]);
    const [testIdForVirtual, setTestIdForVirtual] = useState(null);
    const [isVirtualTestCreatePopupOpen, setVirtualTestCreatePopupOpen] = useState(false);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const fetchAllTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, { test_status: 'not_started', test_types: ["full", "phase", "topic"] }, navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        return allTestsResponse.data;
    };

    const fetchFinishedTests = async () => {
        const finishedTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, { test_status: 'finished', test_types: ["full", "phase", "topic"] });
        if (finishedTestsResponse === null) {
            return null;
        }

        return finishedTestsResponse.data;
    }

    const fetchCurrentTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, { test_status: 'in_progress', test_types: ["full", "phase", "topic"] }, navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        return allTestsResponse.data;
    }

    useEffect(() => {
        (async () => {
            const fetchedUpcomingTests = await fetchAllTests();
            if (fetchedUpcomingTests === null) {
                return;
            }
            setUpcomingTests(fetchedUpcomingTests);
            const fetchedFinishedTests = await fetchFinishedTests();
            if (fetchedFinishedTests === null) {
                return;
            }
            setFinishedTests(fetchedFinishedTests);
            const fetchedCurrentTests = await fetchCurrentTests();
            if (fetchedCurrentTests === null) {
                return;
            }
            setCurrentTests(fetchedCurrentTests);

        })();
    }, []);

    const onTestSelectionChange = (testId) => {
        navigate(`${testId}`);
    };

    const createVirtualTest = (event, test) => {
        event.stopPropagation();
        setTestIdForVirtual(test.id);
        setVirtualTestCreatePopupOpen(true);
    }


    const convertDurationFormat = (duration) => {

        const hrs = Math.floor(duration / 60);
        const mins = duration % 60;

        const hrs_str = (hrs < 10 ? "0" : "") + hrs.toString();
        const mins_str = (mins < 10 ? "0" : "") + mins.toString();

        return hrs_str + ":" + mins_str;
    }

    const onCreateVirtualTest = () => {
        navigate('/student/virtual-tests');
    }


    return (

        <div className="w-full h-full flex flex-col items-center gap-2 px-4 text-[var(--textColor)]">
            <div class="relative w-full overflow-x-auto overflow-y-auto scrollbar-thin">

                <table className="w-full text-sm">
                    <thead>
                        <tr className="bg-[var(--SecondaryBkg)] text-[var(--textColor)] text-center align-middle">
                            <th className="p-1 leading-relaxed">{t('status')}</th>
                            <th className="p-1 leading-relaxed">{t('name')}</th>
                            <th className="p-1 leading-relaxed">{t('type')}</th>
                            <th className="p-1 leading-relaxed">{t('date')}</th>
                            <th className="p-1 leading-relaxed">{t('time')}</th>
                            <th className="p-1 leading-relaxed">{t('duration')}</th>
                            {/* <th>Start Virtual</th> */}
                            {/* <th>Remaining Time</th> */}
                            {/* <th>...</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {currentTests.length > 0 && currentTests.map(test => (
                            <tr key={test.id}
                                onClick={() => onTestSelectionChange(test.id)}
                                className="hover:font-semibold hover:bg-gray-100 cursor-pointer">
                                <td className="flex justify-center items-center text-center py-2 px-4 border-b-2 border-gray-200">
                                    <span className="w-24 bg-red-100 text-red-700 rounded-md px-3 py-2 text-sm font-medium">
                                        {t('startedCapital')}
                                    </span>
                                </td>
                                <td className="text-left py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_name}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_type.toUpperCase()}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('DDslMMslYYYY')) : null}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('hcommA')) : null}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_duration_minutes ? convertDurationFormat(test.test_duration_minutes) : null}
                                </td>
                                {/* <td> </td> */}
                                {/* <td> </td> */}
                                {/* <td> </td> */}
                            </tr>
                        ))}

                        {upcomingTests.length > 0 && upcomingTests.map(test => (
                            <tr key={test.id}
                                onClick={() => onTestSelectionChange(test.id)}
                                className="hover:font-semibold hover:bg-gray-100 cursor-pointer">
                                <td className="flex justify-center items-center text-center py-2 px-4 border-b-2 border-gray-200">
                                    <div className="w-24 bg-green-100 text-green-700 rounded-md px-1 py-1 text-sm font-medium">
                                        <span>{t('upcomingCapital')}</span>
                                    </div>
                                </td>
                                <td className="text-left py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_name}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_type.toUpperCase()}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('DDslMMslYYYY')) : null}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('hcommA')) : null}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_duration_minutes ? convertDurationFormat(test.test_duration_minutes) : null}
                                </td>
                                {/* <td></td> */}
                                {/* <td></td> */}
                                {/* <td></td> */}
                            </tr>
                        ))}

                        {finishedTests.length > 0 && finishedTests.map(test => (
                            <tr key={test.id}
                                onClick={() => onTestSelectionChange(test.id)}
                                className="hover:font-semibold hover:bg-gray-100 cursor-pointer">

                                <td className="flex justify-center items-center text-center py-2 px-4 border-b-2 border-gray-200">
                                    <div className="w-24 flex justify-center items-center gap-1 bg-blue-100 text-blue-700 rounded-md px-1 py-1 text-sm font-medium">
                                        <span>{t('finishedCapital')}</span>
                                        <ArrowPathIcon className="w-4 h-4 cursor-pointer1"
                                            onClick={(event) => createVirtualTest(event, test)}
                                            data-tooltip-id="test-tooltip"
                                            data-tooltip-content={t('retakeTheTest')}
                                            data-tooltip-place="right" />
                                        <Tooltip id="test-tooltip" />
                                    </div>
                                </td>
                                <td className="text-left py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_name}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_type.toUpperCase()}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('DDslMMslYYYY')) : null}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('hcommA')) : null}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_duration_minutes ? convertDurationFormat(test.test_duration_minutes) : null}
                                </td>
                                {/* <td></td> */}
                                {/* <td></td> */}
                                {/* <td></td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <VirtualTestCreatePopup
                isOpen={isVirtualTestCreatePopupOpen}
                onRequestClose={() => setVirtualTestCreatePopupOpen(false)}
                testId={testIdForVirtual}
                onCreateVirtualTest={onCreateVirtualTest}
                user={user} />

        </div>
    );
}

export default StudentTestTable;
