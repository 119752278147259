import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { useNTATest } from './NTATestContext';
import { TEST_CREATE_TYPE_PRACTICE } from '../../../services/TestService';

const NTASectionInfo = ({ activeSection, questionsForSection, paragraphsForSection, user = STUDENT_USER_TYPE }) => {

    const { testCreateType } = useNTATest();
    const [subType, setSubType] = useState('single choice');
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [numberOfParagraphs, setNumberOfParagraphs] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        setSubType(getQuestionSubtype());
        setNumberOfQuestions(getNumberOfQuestions());
        setNumberOfParagraphs(paragraphsForSection.length);
    }, [questionsForSection, paragraphsForSection])

    const getQuestionSubtype = () => {
        if (questionsForSection.length > 0) {
            return questionsForSection[0].question.question_subtype;
        }
        if (paragraphsForSection.length > 0) {
            return paragraphsForSection[0].paragraph.questions[0].question.question_subtype;
        }
        return null;
    }

    const getNumberOfQuestions = () => {
        if (paragraphsForSection.length > 0) {
            const questionCount = paragraphsForSection.map(testParagraph => testParagraph.paragraph.questions.length);
            return questionCount.reduce((a, v) => a + v, 0);
        }
        if (questionsForSection.length > 0) {
            return questionsForSection.length;
        }
        return 0;
    }

    return (
        <div className="w-full flex justify-between px-2 text-sm roboto-medium">
            <div className="flex flex-1 items-center gap-1 text-transform-capitalize">
                <span className="font-bold ">{t('questionType')}:</span>
                <span className="text-gray-700 capitalize">
                    {testCreateType === TEST_CREATE_TYPE_PRACTICE ?
                        t('mixedPractice') : subType}
                </span>
            </div>

            <div className="flex items-center gap-1 font-bold roboto-medium text-sm">
                <div className="flex items-center gap-1 px-2 py-1 mr-2">
                    <span>{t('fullMarks')}:</span>
                    <span className="text-blue-500">{activeSection.full_marks}</span>
                </div>
                <div className="flex items-center gap-1 px-2 py-1 mr-2">
                    <span>{t('partialMarks')}:</span>
                    <span className="text-green-500">{activeSection.partial_marks}</span>
                </div>
                <div className="flex items-center gap-1 px-2 py-1 mr-2">
                    <span>{t('negativeMarks')}:</span>
                    <span className="text-red-500">{activeSection.negative_marks} </span>
                </div>
            </div>
        </div>
    );
}


export default NTASectionInfo;
