import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Spinner from '../Common/Tailwind/Spinner';
import SlotLectureVideo from './SlotLectureVideo';
import SlotLiveLectureVideo from './SlotLiveLectureVideo';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchZoomMeetingStatusById } from '../../services/ZoomMeetingService';

const SlotCombinedLectureVideo = ({ lectureData, user = ADMIN_USER_TYPE }) => {

    const [meetingStatus, setMeetingStatus] = useState(null);
    const navigate = useNavigate();

    const fetchAndSetMeetingStatus = async () => {
        if (lectureData.class_type !== 'online') {
            setMeetingStatus('waiting');
        }
        else {
            const meetingStatus = await securedFetchZoomMeetingStatusById(lectureData.zoom_meeting_id, navigateCallbackOptions(navigate));
            if (meetingStatus === null) {
                return;
            }
            if (meetingStatus.status === 404) {
                alert("This slot's meeting status was not found!");
                navigate(-1);
                return;
            }
            setMeetingStatus(meetingStatus.status);
        }
    }

    useEffect(() => {
        if (lectureData == null) {
            return;
        }
        (async () => {
            await fetchAndSetMeetingStatus();
        })();
    }, [lectureData]);

    if (lectureData == null || meetingStatus == null) {
        return <Spinner />
    }

    if (lectureData.class_type === 'offline' || (meetingStatus === 'waiting' && lectureData.status === 'finished')) {
        return <SlotLectureVideo lectureData={lectureData} user={user} />
    }

    return (
        <SlotLiveLectureVideo
            lectureData={lectureData}
            meetingStatus={meetingStatus}
            user={user} />
    );
};

export default SlotCombinedLectureVideo;
