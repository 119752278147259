import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import numberToWords from 'number-to-words';

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { useNTATest } from './NTATestContext';
import { TEST_CREATE_TYPE_BASIC, TEST_CREATE_TYPE_PRACTICE } from '../../../services/TestService';

const NTAMarkingScheme = ({ sectionId = null, user = STUDENT_USER_TYPE }) => {

    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        getSectionName,
        testCreateType
    } = useNTATest();

    const [subType, setSubType] = useState('single choice');
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [numberOfParagraphs, setNumberOfParagraphs] = useState(0);
    const [sectionData, setSectionData] = useState(sectionId === null ? activeSection : testSections.find(testSection => testSection.id === sectionId) || null);
    const { t } = useTranslation();
    useEffect(() => {
        if (sectionId === null) {
            return;
        }
        setSectionData(testSections.find(testSection => testSection.id === sectionId) || null);
        const newQuestionsForSection = testQuestions.filter(testQuestion => testQuestion.test_section_id === sectionId);
        const newParagraphsForSection = testParagraphs.filter(testParagraph => testParagraph.test_section_id === sectionId);
        setNumberOfQuestions(newQuestionsForSection.length);
        setNumberOfParagraphs(newParagraphsForSection.length);
        setSubType(getQuestionSubtype(newQuestionsForSection, newParagraphsForSection));
    }, [sectionId]);

    const getQuestionSubtype = (questions, paragraphs) => {
        if (paragraphs.length > 0) {
            return paragraphs[0].paragraph.questions[0].question.question_subtype;
        }
        if (questions.length > 0) {
            return questions[0].question.question_subtype;
        }
        return null;
    }


    const renderSubtypeLines = () => {
        if (testCreateType === TEST_CREATE_TYPE_PRACTICE) {
            return;
        }
        if (subType === 'single choice') {
            return (
                <>
                    <li> {t("eachQuestionHas")} <strong>FOUR</strong> {t("optionsABCD")} <strong>{t("onlyOneOfTheseFourOptionsIsTheCorrectAnswer")}</strong>.</li>
                    <li> {t("forEachQuestion")} {t("chooseTheOptionCorrespondingToTheCorrectAnswer")}.</li>
                </>)
        }
        if (subType === 'multi choice') {
            return (
                <>
                    <li> {t("eachQuestionHas")} <strong>FOUR</strong> {t("optionsABCD")} <strong>{t("oneOrMoreThanOneOfTheseFourOptionsIsTheCorrectAnswer")}</strong>.</li>
                    <li> {t("forEachQuestion")} {t("chooseTheOptionCorrespondingToTheCorrectAnswer")}.</li>
                </>)
        }
        return (
            <>
                <li> {t("theAnswerToEachQuestionIsANumericalValue")} <strong>{t("numericalValue")}</strong>.</li>
                <li> {t("forEachQuestion")} {t("enterTheCorrectNumericalValueOfTheAnswer")}. {t("ifTheNumericalAnswerHasMoreThanTwoDecimalPlacesTruncateRoundOffTheValueToTwoDecimalPlaces")}</li>
            </>)
    }

    const renderFullmarksIfLine = () => {
        if (testCreateType === TEST_CREATE_TYPE_PRACTICE) {
            return t("ifCorrectlyAnswered")
        }
        if (subType === 'single choice') {
            return t("ifTheCorrectOptionIsChosen")
        }
        if (subType === 'multi choice') {
            return t("ifAllTheCorrectOptionsAreChosen")
        }
        return t("ifTheCorrectNumericalValueIsEntered")
    }

    const renderZeroMarksLine = () => {
        if (sectionData.negative_marks === 0) {
            return t("inAllOtherCases")
        }
        if (testCreateType === TEST_CREATE_TYPE_PRACTICE) {
            return t("ifNotAnswered")
        }
        if (subType === 'single choice' || subType === 'multi choice') {
            return t("ifNoneOfTheOptionsIsChosen")
        }
        return t("ifNoNumericalValueIsEntered")
    }

    if (numberOfParagraphs === 0 && numberOfQuestions === 0) {
        return;
    }

    return (
        <div className="flex shrink-0 flex-col p-2 mt-2 mx-4 gap-2 text-sm roboto-regular max-h-[40%] overflow-y-auto border-2 border-black" >
            <h3 className="text-center font-bold roboto-bold">
                {getSectionName(sectionData)}
                ({t("maximumMarks")}: {sectionData.full_marks * (numberOfQuestions)})
            </h3>

            <ul className="pl-4 list-disc text-xs font-serif">
                {numberOfParagraphs === 0 || testCreateType === TEST_CREATE_TYPE_PRACTICE ? (
                    <li> {t("thisSectionContains")} <strong> {numberToWords.toWords(numberOfQuestions).toUpperCase()} ({numberOfQuestions})</strong> {t("questionsSmall")}</li>) :
                    (<li> {t("thisSectionContains")} <strong> {numberToWords.toWords(numberOfParagraphs).toUpperCase()} ({numberOfParagraphs})</strong> {t("paragraphs")} {t("consistingOf")} <strong> {numberToWords.toWords(numberOfQuestions).toUpperCase()} ({numberOfQuestions})</strong> {t("questionsSmall")} {t("inTotal")}</li>)
                }
                {renderSubtypeLines()}
                <li> {t("answerToEachQuestionWillBeEvaluatedAccordingToFollowingMarkingScheme")}:</li>
            </ul>

            <div className="ml-4 leading-normal">
                <div className="flex font-serif justify-start items-start flex-nowrap gap-1 text-xs">
                    <label className="font-bold">{t("fullMarks")}: </label>
                    <span className="font-bold font-sans">{sectionData.full_marks}</span>
                    <span><strong>{t("onlyCapital")}</strong> {renderFullmarksIfLine()}</span>
                </div>
                {subType === 'multi choice' && sectionData.partial_marks !== 0 && (
                    <>
                        <div className="flex font-serif justify-start items-start flex-nowrap gap-2 text-xs">
                            <label className="font-bold">{t("partialMarks")}: </label>
                            <span className="font-bold font-sans">{sectionData.partial_marks * 3}</span>
                            <span>{t("ifAllTheFourOptionsAreCorrectButOnlyThreeOptionsAreChosen")}</span>
                        </div>

                        <div className="flex font-serif justify-start items-start flex-nowrap gap-2 text-xs">
                            <label className="font-bold">{t("partialMarks")}: </label>
                            <span className="font-bold font-sans">{sectionData.partial_marks * 2}</span>
                            <span>{t("ifThreeOrMoreOptionsAreCorrectButOnlyTwoOptionsAreChosenBothOfWhichAreCorrect")}</span>
                        </div>

                        <div className="flex font-serif justify-start items-start flex-nowrap gap-2 text-xs">
                            <label className="font-bold">{t("partialMarks")}: </label>
                            <span className="font-bold font-sans">{sectionData.partial_marks}</span>
                            <span>{t("ifTwoOrMoreOptionsAreCorrectButOnlyOneOptionIsChosenAndItIsACorrectOption")}</span>
                        </div>
                    </>
                )}

                <div className="flex font-serif justify-start items-start flex-nowrap gap-2 text-xs">
                    <label className="font-bold">{t("zeroMarks")}: </label>
                    <span className="font-bold font-sans">0</span>
                    <span>{renderZeroMarksLine()}</span>
                </div>
                {sectionData.negative_marks !== 0 && (
                    <div className="flex font-serif justify-start items-start flex-nowrap gap-2 text-xs">
                        <label className="font-bold">{t("negativeMarks")}: </label>
                        <span className="font-bold font-sans">{sectionData.negative_marks}</span>
                        <span>{t("inAllOtherCases")}</span>
                    </div>)}

            </div>
        </div>
    );
}

export default NTAMarkingScheme;
