import React, { useState, useEffect } from 'react';
import produce from 'immer';

const CheckboxAnswerInputList = ({ className = "", label = "", answerInputs, setAnswerInputs, selectedAnswers, setSelectedAnswers, required = null, editable = true }) => {
    const onAnswerTextChange = (index) => (event) => {
        const newValue = event.target.value;
        setAnswerInputs(produce(draft => {
            draft[index].value = newValue;
        }));
    }

    const onSelectedAnswersChange = (index) => (event) => {
        const newSelectedAnswers = [...selectedAnswers];
        newSelectedAnswers[index] = event.target.checked;
        setSelectedAnswers(newSelectedAnswers);
    }

    return (
        <div className={className}>
            <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">{label}</label>

            {answerInputs.map((answerDict, index) => {
                const answer = String.fromCharCode(65 + index);
                return (
                    <div className="ml-2 flex items-center" key={answer}>
                        <input className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            type="checkbox"
                            name='answer'
                            value={answer}
                            id={answer}
                            checked={selectedAnswers[index]}
                            onChange={onSelectedAnswersChange(index)}
                            required />
                        <label htmlFor={answer} className="ml-1 block text-sm font-medium leading-6 text-gray-900">
                            ({answer})
                            {editable ?
                                <textarea className="optionInput" value={answerDict.value} placeholder={`Answer ${answer}...`} onChange={onAnswerTextChange(index)} rows="1" required={required} /> :
                                <p className="optionInput"></p>
                            }
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export default CheckboxAnswerInputList