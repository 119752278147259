import React from 'react'

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';

import { useParams } from 'react-router-dom';
import ZoomView from '../../Components/Zoom/ZoomView';

const StudentZoom = () => {
    const { slotId } = useParams();
    return (
        <>
            <ZoomView slotId={parseInt(slotId)} user={STUDENT_USER_TYPE} />
        </>
    )
};

export default StudentZoom;