import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { AiFillCloseCircle, AiFillDelete, AiFillExclamationCircle, AiFillPlusCircle, AiOutlineDelete, AiOutlineExclamationCircle, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import AutoTestSubjectCreate from './TestSubject/AutoTestSubjectCreate';
import AutoTestSectionsCreate from './TestSubject/AutoTestSectionsCreate';
import { MdAddCircleOutline } from 'react-icons/md';
import { securedFetchSubjects } from '../../services/SyllabusService';
import { ChevronLeftIcon, ChevronRightIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';


const AutoTestCreate = ({ sectionsData, setSectionsData, subjectsData, setSubjectsData, isEntireSyllabus, subjects, setSubjects, selectedSubjects, selectedMainTopics, selectedTopics }) => {

    const [allowedSubjects, setAllowedSubjects] = useState([]);
    const [activeSubjectId, setActiveSubjectId] = useState(subjectsData.length === 0 ? null : subjectsData[0].subject_id);
    const [isTestSubjectCreateOpen, setTestSubjectCreateOpen] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const tabsRef = useRef(null);

    useEffect(() => {
        (async () => {
            if (subjects.length === 0) {
                const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
                if (subjectsResponse === null) {
                    return;
                }
                setSubjects(subjectsResponse.data);
            }
        })();
    }, []);

    useEffect(() => {
        const includedSubjectIds = getIncludedSubjectIds();
        const usedSubjectIds = subjectsData.map(data => data.subject_id);
        setAllowedSubjects(subjects.filter(subject => (includedSubjectIds.includes(subject.id) && !usedSubjectIds.includes(subject.id))));
    }, [subjects, selectedSubjects, selectedMainTopics, selectedTopics, subjectsData, isEntireSyllabus]);

    const getAllowedSubjectData = (data) => {
        return getIncludedSubjectIds().includes(data.subject_id);
    }

    const getIncludedSubjectIds = () => {
        if (isEntireSyllabus) {
            return subjects.map(subject => subject.id);
        }
        if (selectedSubjects.length === 0 && selectedTopics.length === 0 && selectedMainTopics.length === 0) {
            return subjects.map(subject => subject.id);
        }
        if (selectedTopics.length > 0 || selectedMainTopics.length > 0) {
            return [...selectedTopics, ...selectedMainTopics].map(topic => topic.subject_id);
        }
        return selectedSubjects.map(subject => subject.id);
    }

    const handleTabClick = (e, subjectId) => {
        e.preventDefault();
        setActiveSubjectId(subjectsData.find(data => data.subject_id === subjectId)?.subject_id || null);
    }

    const onAddSubject = (subject) => {
        setSubjectsData(prevSubjectsData => [...prevSubjectsData, { subject_id: subject.id, valid: true }])
        setActiveSubjectId(subject.id);
    }

    const onDeleteSubject = (subjectId) => {

        setSubjectsData(prevSubjectsData => prevSubjectsData.filter(data => data.subject_id !== subjectId));
        setSectionsData(prevSectionsData => prevSectionsData.filter(data => data.subject_id !== subjectId));
        if (activeSubjectId === subjectId) {
            setActiveSubjectId(null);
        }
    }

    const scrollTabs = (e, direction) => {
        e.preventDefault();
        if (tabsRef.current) {
            const { current } = tabsRef;
            const scrollAmount = direction === 'left' ? -current.offsetWidth / 3 : current.offsetWidth / 3;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const renderSubject = () => {
        if (activeSubjectId === null) {
            return (
                <h3 className="mt-2 text-lg font-semibold leading-6 text-[var(--SecondaryColor)]">
                    {t('addSubject')}
                </h3>
            );
        }
        return (
            <div className="relative w-full h-full flex flex-col justify-center items-center color-[var(--textColor)] gap-1">
                <AutoTestSectionsCreate activeSubjectId={activeSubjectId} sectionsData={sectionsData} setSectionsData={setSectionsData} />
            </div>
        )
    }

    return (
        <div className="relative w-full flex flex-col shadow-md">
            <div className="flex justify-between items-center rounded-md overflow-hidden">
                <button className="text-white bg-[var(--SecondaryBkg)] flex justify-center items-center border-0 gap-1"
                    onClick={(e) => scrollTabs(e, 'left')}>
                    <ChevronLeftIcon className="w-7 h-7 p-1" />
                </button>

                {/* Render Subject */}
                <div className="flex flex-1 flex-start items-center overflow-x-auto scrollbar-hide h-7 bg-[var(--SecondaryBkg)]"
                    draggable ref={tabsRef}>
                    {subjectsData.map((subjectData) => (
                        <button
                            className={`text-white flex justify-center items-center border-0 gap-1 h-7 px-2 py-1
                                ${(activeSubjectId !== null && activeSubjectId === subjectData.subject_id) ? 'bg-[var(--SecondaryColor)]' : 'bg-[var(--SecondaryBkg)]'
                                }`}
                            key={subjectData.subject_id}
                            onClick={(e) => handleTabClick(e, subjectData.subject_id)}
                        >
                            {/* Access the subject_name safely */}
                            {subjects.find(subject => subject.id === subjectData.subject_id)?.subject_name}
                            {!getAllowedSubjectData(subjectData) && (<AiFillExclamationCircle
                                className="icon invalid-subject"
                                data-tooltip-id="tooltip"
                                data-tooltip-content={!selectedSubjects.map(subject => subject.id).includes(subjectData.subject_id) ? "Subject not selected" : "No topic chosen"}
                                data-tooltip-place="right"
                            />)}

                            <TrashIcon
                                className="w-6 h-6 p-1 hover:font-semibold hover:text-red-500"
                                onClick={(e) => {
                                    e.stopPropagation(); // Stop click event from bubbling up to the parent button
                                    onDeleteSubject(subjectData.subject_id);
                                }}
                                data-tooltip-id="tooltip-edit-test-subject"
                                data-tooltip-content="Delete the subject"
                                data-tooltip-place="right"
                            />

                        </button>
                    ))}

                    {(subjectsData.length < 3) && (
                        <>
                            <button
                                className={`text-white flex justify-center items-center border-0 gap-1 h-7 px-2 py-1
                                ${activeSubjectId === null ? 'bg-[var(--SecondaryColor)]' : 'bg-[var(--SecondaryBkg)]'}`}

                                onClick={(e) => (
                                    e.preventDefault(),
                                    setTestSubjectCreateOpen(true)
                                )} >
                                <PlusCircleIcon
                                    className="w-7 h-7 p-1 hover:text-[var(--PrimaryColor)]"
                                    data-tooltip-id="tooltip-edit-test-subject"
                                    data-tooltip-content="Add the subject"
                                    data-tooltip-place="right" />
                            </button>
                            <AutoTestSubjectCreate
                                allowedSubjects={allowedSubjects}
                                isOpen={isTestSubjectCreateOpen}
                                onRequestClose={() => setTestSubjectCreateOpen(false)}
                                onAddSubject={onAddSubject} />
                        </>)
                    }

                    <Tooltip id="tooltip-edit-test-subject" />
                </div >

                <button className="text-white bg-[var(--SecondaryBkg)] flex justify-center items-center border-0 gap-1"
                    onClick={(e) => scrollTabs(e, 'right')}>
                    <ChevronRightIcon className="w-7 h-7 p-1" />
                </button>
            </div>

            <div className="relative flex flex-col justify-start items-start w-full h-full border-t-2 rounded-md bg-[var(--whiteColorDeam)]">
                {renderSubject()}
            </div>

        </div>

    )
}

export default AutoTestCreate;
