import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

import '../testReport.css';

import { FiCheckCircle, FiMinusCircle, FiXCircle } from "react-icons/fi";
import { securedFetchSubjects } from '../../../../services/SyllabusService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';
import { FaDownload } from 'react-icons/fa';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../../services/UserService';

const DetailResponses = ({ testId = null, testName = '', data = null, student_wise_data = null, num_students = null, num_questions = null, user = ADMIN_USER_TYPE }) => {

    const [subjects, setSubjects] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
            if (subjectsResponse == null) {
                return;
            }
            setSubjects(subjectsResponse.data);
        })();
    }, []);

    if (!data || num_questions === 0 || subjects.length === 0) {
        return null; // Handle the case when data is not provided
    }

    if (user === ADMIN_USER_TYPE && (num_students === 0 || student_wise_data === null)) {
        return null;
    }

    const getSectionNumber = (index, currentEntry, previousEntry, previousSectionNumber) => {
        if (index === 0) {
            return 1;
        }
        if (currentEntry.subject_id === previousEntry.subject_id) {
            if (previousEntry.section_id === currentEntry.section_id) {
                return previousSectionNumber;
            }
            return previousSectionNumber + 1;
        }
        return 1;
    }

    const getQuestionNumber = (index, currentEntry, previousEntry, previousQuestionNumber) => {
        if (index === 0) {
            return 1;
        }
        if (currentEntry.section_id === previousEntry.section_id) {
            if (previousEntry.question_id === currentEntry.question_id) {
                return previousQuestionNumber;
            }
            return previousQuestionNumber + 1;
        }
        return 1;
    }

    const getUpdatedQuestionsData = (questionsData) => {
        let previousQuestionNumber = -1, previousSectionNumber = -1;
        let newQuestionsData = [];
        for (let i = 0; i < questionsData.length; i++) {
            const sectionNumber = getSectionNumber(i, questionsData[i - 1] || null, questionsData[i], previousSectionNumber);
            const questionNumber = getQuestionNumber(i, questionsData[i - 1] || null, questionsData[i], previousQuestionNumber);
            previousSectionNumber = sectionNumber;
            previousQuestionNumber = questionNumber;
            const subjectName = subjects.find(subject => subject.id === questionsData[i].subject_id).subject_name;
            newQuestionsData = [...newQuestionsData, { ...questionsData[i], label: `${subjectName} SEC ${sectionNumber} Q${questionNumber}` }]
        }
        return newQuestionsData;
    }

    const TimeFormatter = (totalPreciseSeconds) => {
        if (totalPreciseSeconds === null) {
            return '-';
        }
        const totalSeconds = Math.round(totalPreciseSeconds);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedTime;
    }

    const firstStudentId = data[0].student_id;
    const questionsEntries = getUpdatedQuestionsData(data.filter(entry => entry.student_id === firstStudentId));
    const firstQuestionId = data[0].question_id;
    const studentsEntries = data.filter(entry => entry.question_id === firstQuestionId);
    const chartData = Array.from({ length: num_students }, () =>
        Array.from({ length: num_questions }, () => false)
    );
    data.map((entry, index) => {
        const questionIdx = questionsEntries.findIndex(qEntry => qEntry.question_id === entry.question_id);
        const studentIdx = studentsEntries.findIndex(qEntry => qEntry.student_id === entry.student_id);
        if (entry.correctness_value === "correct") {
            chartData[studentIdx][questionIdx] = { correctness: 1, score: parseInt(entry.score), time_spent: TimeFormatter(entry.time_spent_seconds) };
        } else if (entry.correctness_value === "no_response") {
            chartData[studentIdx][questionIdx] = { correctness: -1, score: parseInt(entry.score), time_spent: TimeFormatter(entry.time_spent_seconds) };
        } else {
            chartData[studentIdx][questionIdx] = { correctness: 0, score: parseInt(entry.score), time_spent: TimeFormatter(entry.time_spent_seconds) };
        }
    })

    const csvData = studentsEntries.map((row, rowIndex) => {
        const studentDict = { name: row.student_name, score: student_wise_data[row.student_id].total_score };
        questionsEntries.map((questionEntry, colIndex) => {
            studentDict[questionEntry.label] = chartData[rowIndex][colIndex].score
        });
        return studentDict;
    });

    return (
        <>
            {user !== STUDENT_USER_TYPE &&
                <CSVLink data={csvData} filename={`${testName}-${testId}-Report.csv`.split(' ').join('-')} className='btn flex'>
                    <FaDownload className="icon" />
                </CSVLink>
            }

            {user === STUDENT_USER_TYPE ? (
                <table className="report-table">

                    <thead>
                        <tr>
                            <th>{t('subject')}</th>
                            <th>{t('section')}</th>
                            <th>{t('question')}</th>
                            <th>{t('timeSpent')}</th>
                            <th key='marks' colSpan={2}>
                                {t('marksObtained')}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {questionsEntries.map((questionEntry, index) => (
                            <tr key={index}>
                                <td>{questionEntry.label.split(' ')[0]}</td>
                                <td>{questionEntry.label.split(' ')[1]} {questionEntry.label.split(' ')[2]} </td>
                                <td>{questionEntry.label.split(' ')[3]} </td>
                                <td>{chartData[0][index].time_spent}</td>
                                <td key={index}>
                                    <div className='detail-response-row flex'>
                                        {chartData[0][index].correctness > 0 ?
                                            <FiCheckCircle className="table-icon" id="correct" />
                                            :
                                            (
                                                chartData[0][index].correctness < 0 ?
                                                    <FiMinusCircle className="table-icon" id="no_response" />
                                                    :
                                                    <FiXCircle className="table-icon" id="incorrect" />
                                            )
                                        }
                                    </div>
                                </td>
                                <td>{parseInt(chartData[0][index].score)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            ) : (
                <table className="report-table">

                    <thead>
                        <tr>
                            <th>{t('studentName')}</th>
                            <th key='totalScore'>
                                {t('marksObtained')}
                            </th>
                            <th key='correct'>
                                Correct Answers
                            </th>
                            <th key='incorrect'>
                                Incorrect Answers
                            </th>
                            <th key='no_response'>
                                No Response
                            </th>
                            <th key='partial_response'>
                                Partial Response
                            </th>
                            {questionsEntries.map((questionEntry, index) => (
                                <th key={index} colSpan={3}>
                                    {questionEntry.label}
                                    {/* {questionID} */}
                                </th>
                            ))}

                        </tr >
                    </thead >

                    <tbody>
                        {num_students > 0 && studentsEntries.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{row.student_name}</td>
                                <td key={`${rowIndex}Total`}>
                                    {student_wise_data[row.student_id].total_score}
                                </td>
                                <td key={`${rowIndex}Correct`}>
                                    {student_wise_data[row.student_id].number_of_questions_correct}
                                </td>
                                <td key={`${rowIndex}Incorrect`}>
                                    {student_wise_data[row.student_id].number_of_questions_incorrect}
                                </td>
                                <td key={`${rowIndex}NoResponse`}>
                                    {student_wise_data[row.student_id].number_of_questions_no_response}
                                </td>
                                <td key={`${rowIndex}PartialResponse`}>
                                    {student_wise_data[row.student_id].number_of_questions_partial_response}
                                </td>
                                {questionsEntries.map((_, colIndex) => (
                                    <React.Fragment key={colIndex}>
                                        <td>
                                            <div className='detail-response-row flex'>
                                                {chartData[rowIndex][colIndex].correctness > 0 ?
                                                    <FiCheckCircle className="table-icon" id="correct" />

                                                    :
                                                    (
                                                        chartData[rowIndex][colIndex].correctness < 0 ?
                                                            <FiMinusCircle className="table-icon" id="no_response" />
                                                            :
                                                            <FiXCircle className="table-icon" id="incorrect" />
                                                    )
                                                }
                                            </div>
                                        </td>
                                        <td>{parseInt(chartData[rowIndex][colIndex].score)}</td>
                                        <td>{chartData[rowIndex][colIndex].time_spent}</td>
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>

            )}

        </>
    );
};

export default DetailResponses;