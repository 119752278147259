import React, { useDebugValue, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'
import moment from 'moment';
import { useTranslation } from "react-i18next";

import messageImg from '../../Assets/TestDevice.png'

import TestUpsert from './TestCreate/TestCreate';
import { FULL_TEST_TYPES, TEST_CREATE_TYPE_BASIC, hasTestCreateAuthority, hasTestEditAuthority, securedCreateTest, securedFetchAllTestScoringFormats, securedFetchAllTestSeries, securedFetchTestById, securedFetchTestReport, securedFetchTests, securedStartTest } from '../../services/TestService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import Spinner from '../Common/Tailwind/Spinner';
import MessagePage from '../Common/Tailwind/Page/MessagePage';
import TypeSeriesFilter from './TypeSeriesFilter';
import TestTable from './TestTable';

const TestPage = ({ user = ADMIN_USER_TYPE }) => {
    const [isTabletView, setIsTabletView] = useState(window.innerWidth > 760);
    const [isTestModalOpen, setTestModalOpen] = useState(false);
    const [selectedTestTypes, setSelectedTestTypes] = useState([]);
    const [allTestSeries, setAllTestSeries] = useState([]);
    const [selectedTestSeriesMultiple, setSelectedTestSeriesMultiple] = useState([]);
    const [tests, setTests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTest, setSelectedTest] = useState({});

    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchAndSetAllTestSeries = async () => {
        const allTestSeriesResponse = await securedFetchAllTestSeries(navigateCallbackOptions(navigate));
        if (allTestSeriesResponse === null) {
            return null;
        }
        return allTestSeriesResponse.data;
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 760) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        (async () => {
            await refreshTestsWithFilter();
        })();

        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const makeFilter = (externalFilter = null) => {
        let filter = {}
        if (selectedTestTypes.length > 0) {
            filter['test_types'] = selectedTestTypes.map(testType => (testType.id));
        }
        if (selectedTestSeriesMultiple.length > 0) {
            filter['test_series_ids'] = selectedTestSeriesMultiple.map(testSeries => (testSeries.id));
        }
        if (externalFilter) {
            filter = { ...filter, ...externalFilter };
        }
        if (filter['test_types'] && filter['test_types'].length === 0) {
            delete filter['test_types'];
        }
        if (filter['test_series_ids'] && filter['test_series_ids'].length === 0) {
            delete filter['test_series_ids'];
        }
        return filter;
    };

    const fetchAllTests = async (externalFilter = null) => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, makeFilter(externalFilter), navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        if (!externalFilter.test_status) {
            return allTestsResponse.data;
        }
        return allTestsResponse.data.map((response) => ({ ...response, status: getTestStatusName(externalFilter.test_status) }));
    };

    const fetchAndSetAllTests = async (externalFilter = null) => {
        const inProgressTestsResponse = await fetchAllTests({ ...externalFilter, test_status: 'in_progress' });
        if (inProgressTestsResponse === null) {
            return null;
        }
        const finishedTestsResponse = await fetchAllTests({ ...externalFilter, test_status: 'finished' });
        if (finishedTestsResponse === null) {
            return null;
        }
        const notStartedTestsResponse = await fetchAllTests({ ...externalFilter, test_status: 'not_started' });
        if (notStartedTestsResponse === null) {
            return null;
        }
        const response = [...inProgressTestsResponse, ...finishedTestsResponse, ...notStartedTestsResponse]
        if (!response.find(response => response.id === selectedTest.id)) {
            setSelectedTest({});
        }
        setTests(response);
        return response;
    };

    const refreshTestsWithFilter = async (externalFilter = null) => {
        setIsLoading(true);
        const testSeriesList = await fetchAndSetAllTestSeries();
        if (testSeriesList === null) {
            return null;
        }
        setAllTestSeries(testSeriesList);
        const result = await fetchAndSetAllTests(externalFilter);
        setIsLoading(false);
        return result;
    }

    const onTestTypeSelectionChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedOptionsFromSelect = FULL_TEST_TYPES.filter(testType => selectedIds.includes(testType.id));
        setSelectedTestTypes(selectedOptionsFromSelect);
        await refreshTestsWithFilter({ test_types: selectedOptionsFromSelect.map(testType => testType.id) });
    };

    const onTestSeriesSelectionChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedOptionsFromSelect = allTestSeries.filter(testSeries => selectedIds.includes(testSeries.id));
        setSelectedTestSeriesMultiple(selectedOptionsFromSelect);
        await refreshTestsWithFilter({ test_series_ids: selectedOptionsFromSelect.map(testSeries => testSeries.id) });
    };

    const onTestSelectionChange = (testId) => {
        navigate(`${testId}`);
    };

    const convertDurationFormat = (duration) => {

        const hrs = Math.floor(duration / 60);
        const mins = duration % 60;

        const hrs_str = (hrs < 10 ? "0" : "") + hrs.toString();
        const mins_str = (mins < 10 ? "0" : "") + mins.toString();

        return hrs_str + ":" + mins_str;
    }

    const getTestStatusName = (statusCode) => {
        if (statusCode === 'in_progress') {
            return 'STARTED';
        }
        if (statusCode === 'not_started') {
            return 'NOT-STARTED';
        }
        return 'FINISHED';
    }

    const renderTestMain = () => {
        if (isLoading) {
            return <Spinner />;
        }
        return (
            <main className="w-screen min-h-screen md:py-4 flex flex-col">

                <div class="flex flex-col flex-1 mb-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">
                    {isTabletView ? (
                        <TestTable user={user} tests={tests}
                            onTestSelectionChange={onTestSelectionChange}
                            convertDurationFormat={convertDurationFormat} />
                    ) : (
                        <MessagePage imgsrc={messageImg} title={t('pleaseOpenTestInPCOrTablet')} message1={""} message2={""} message3={""} />
                    )}
                </div>

            </main>
        );
    }

    const onTestAdd = async (testId) => {
        const tests = await fetchAndSetAllTests();
        if (tests === null) {
            return;
        }
    }

    return (
        <div className="relative flex flex-col flex-1 w-full h-full items-center gap-2 sm:gap-3 md:gap-4 text-[var(--textColor)]">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t('simplifyTestManagement')} /> */}

            {/* Page Main */}
            <div className="flex flex-col w-full bg-white overflow-hidden">

                {/* Filter */}
                <div className="w-full flex flex-col justify-center items-center p-2 my-2 gap-2 md:gap-4 z-3">

                    <TypeSeriesFilter
                        showTitle={true}
                        types={FULL_TEST_TYPES} selectedType={selectedTestTypes}
                        series={allTestSeries} selectedSeries={selectedTestSeriesMultiple}
                        // setType={setGrades} setSelectedType={setSelectedGrade}
                        // setSeries={setBatches} setSelectedSeries={setSelectedBatch}
                        onSelectedTypeChange={onTestTypeSelectionChange}
                        onSelectedSeriesChange={onTestSeriesSelectionChange}
                        user={user}
                        useSearchParamter={true}
                        setTestCreateOpen={setTestModalOpen}
                    />

                    {/* Create New Test */}
                    {hasTestCreateAuthority(user) && (
                        <TestUpsert isOpen={isTestModalOpen} onRequestClose={() => (setTestModalOpen(false))} onTestUpsert={onTestAdd} />
                    )}

                    <Tooltip id="tooltip-create-new-test" />

                </div>

                {/* Main */}
                {renderTestMain()}

            </div>

        </div>
    );
}

export default TestPage;
