import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'

import Spinner from '../Common/Tailwind/Spinner';
import TestSubjectDelete from './TestSubject/TestSubjectDelete';
import TestSubjectCreate from './TestSubject/TestSubjectCreate';
import TestSubjectRender from './TestSubject/TestSubjectRender';
import { TestContext } from './TestContext';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { TEST_CREATE_TYPE_BASIC, canCreateTestSubject, securedDeleteTestSubject, securedFetchTestById, securedFetchTestSubjectById, securedUpdateTestSubjects } from '../../services/TestService';
import { securedFetchQuestionsByIds } from '../../services/QuestionService';
import { securedFetchSubjects } from '../../services/SyllabusService';
import { ChevronLeftIcon, ChevronRightIcon, TrashIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

const TestEdit = ({ id, testData = null, testStatus = 'NOT STARTED', user = ADMIN_USER_TYPE, testType }) => {

    const [testSubjects, setTestSubjects] = useState([]);
    const [testSections, setTestSections] = useState([]);
    const [testQuestions, setTestQuestions] = useState([]);
    const [testParagraphs, setTestParagraphs] = useState([]);
    const [isTestSubjectCreateOpen, setTestSubjectCreateOpen] = useState(false);
    const [isTestSubjectDeleteOpen, setTestSubjectDeleteOpen] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [testMetadata, setTestMetadata] = useState({});
    const [activeSubject, setActiveSubject] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const tabsRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            let testResponse = testData && testData.subjects ? testData : null;
            if (testResponse === null) {
                testResponse = await securedFetchTestById(TEST_CREATE_TYPE_BASIC, id, navigateCallbackOptions(navigate));
                if (testResponse === null) {
                    setIsLoading(false);
                    return;
                }
            }
            await setStatesFromResponse(testResponse);
            setMetadataFromResponse(testResponse);
            const subjects = await securedFetchSubjects(navigateCallbackOptions(navigate));
            if (subjects === null) {
                setIsLoading(false);
                return;
            }
            setSubjects(subjects.data);
            setIsLoading(false);
        })();
    }, [id, testStatus]);

    const setMetadataFromResponse = (testResponse) => {
        setTestMetadata({
            id: testResponse.id,
            test_name: testResponse.test_name,
            batches: testResponse.batches,
            test_series_id: testResponse.test_series_id,
            test_duration_minutes: testResponse.test_duration_minutes,
            test_start_time: testResponse.test_start_time
        });
    }

    const setStatesFromResponse = async (testResponse) => {
        const testSubjectsFromResponse = [...testResponse.subjects];
        let testSectionsFromResponse = [];
        testSubjectsFromResponse.forEach((testSubject) => {
            testSectionsFromResponse = [...testSectionsFromResponse, ...testSubject.sections];
        })
        let testQuestionsFromResponse = [];
        let testParagraphsFromResponse = [];

        testSectionsFromResponse.forEach((testSection) => {
            testQuestionsFromResponse = [...testQuestionsFromResponse, ...testSection.test_questions];
            testParagraphsFromResponse = [...testParagraphsFromResponse, ...testSection.test_paragraphs];
        })
        await setTestQuestionsAndParagraphs(testQuestionsFromResponse, testParagraphsFromResponse);
        testResponse.subjects = null;
        testSubjectsFromResponse.forEach((testSubject) => { testSubject.sections = null });
        testSectionsFromResponse.forEach((testSection) => {
            testSection.test_questions = null;
            testSection.test_paragraphs = null;
        });

        setTestSubjects(testSubjectsFromResponse);
        setTestSections(testSectionsFromResponse);
        if (testSubjectsFromResponse.length > 0) {
            setActiveSubject(testSubjectsFromResponse[0]);
        }
    };

    const setTestQuestionsAndParagraphs = async (testQuestionsFromResponse, testParagraphsFromResponse) => {
        const questionIds = testQuestionsFromResponse.map((testQuestion) => (testQuestion.question_id));
        const paragraphIds = testParagraphsFromResponse.map((testParagraph) => (testParagraph.paragraph_id));
        const questionsResponse = await securedFetchQuestionsByIds('standalone', questionIds, { show_correct_answer: user !== STUDENT_USER_TYPE }, navigateCallbackOptions(navigate));
        if (questionsResponse === null) {
            return;
        }
        const paragraphsResponse = await securedFetchQuestionsByIds('paragraph', paragraphIds, { show_correct_answer: user !== STUDENT_USER_TYPE }, navigateCallbackOptions(navigate));
        if (paragraphsResponse === null) {
            return;
        }
        const questions = questionsResponse.data;
        const paragraphs = paragraphsResponse.data;
        testQuestionsFromResponse.forEach((testQuestion) => {
            const matchingQuestion = questions.find((question) => (question.id === testQuestion.question_id));
            testQuestion['question'] = matchingQuestion;
        });
        testParagraphsFromResponse.forEach((testParagraph) => {
            const matchingParagraph = paragraphs.find((paragraph) => (paragraph.id === testParagraph.paragraph_id));
            testParagraph['paragraph'] = matchingParagraph;
        });
        testQuestionsFromResponse = testQuestionsFromResponse.filter((testQuestion) => (testQuestion.question !== undefined));
        testParagraphsFromResponse = testParagraphsFromResponse.filter((testParagraph) => (testParagraph.paragraph !== undefined));
        setTestQuestions(testQuestionsFromResponse);
        setTestParagraphs(testParagraphsFromResponse);
    }

    const addSubject = async (newSubjectId) => {
        const newSubject = await securedFetchTestSubjectById(id, newSubjectId, navigateCallbackOptions(navigate));
        newSubject.sections = null;
        setTestSubjects([...testSubjects, newSubject]);
        setTestSubjectCreateOpen(false);
        setActiveSubject(newSubject);
    };

    const getNextSubjectOrder = () => {
        return testSubjects.length + 1;
    }

    const deleteSubject = async (testSubjectId) => {

        const updatedSubjects = testSubjects.filter((testSubject) => (testSubject.id !== testSubjectId));
        await updateTestSubjectOrder(id, updatedSubjects);
        const orderedUpdatedSubjects = updatedSubjects.map((entry, index) => ({ ...entry, subject_order: index }));
        setTestSubjects(orderedUpdatedSubjects);
        const keptSections = testSections.filter((testSection) => (testSection.test_subject_id !== testSubjectId))
        setTestSections(keptSections);
        setTestQuestions(testQuestions.filter((testQuestion) => (keptSections.some((keptSection) => testQuestion.test_section_id === keptSection.id))));
        setTestParagraphs(testParagraphs.filter((testParagraph) => (keptSections.some((keptSection) => testParagraph.test_section_id === keptSection.id))));
        setTestSubjectDeleteOpen();
        setActiveSubject(null);
    }

    const updateTestSubjectOrder = async (testId, updatedTestSubjects) => {
        const orderList = updatedTestSubjects.map((testSubject, index) => ({ id: testSubject.id, subject_order: index }));
        const response = await securedUpdateTestSubjects(testId, orderList, navigateCallbackOptions(navigate));
    }

    const renderTestSubject = (testType) => {

        if (activeSubject === null) {
            return (
                <h3 className="mt-2 text-lg font-semibold leading-6 text-[var(--SecondaryColor)]">
                    {testStatus === 'NOT STARTED' ? 'Add Subject' : ''}
                </h3>
            );
        }

        if (!subjects || subjects.length === 0) {
            return;
        }

        return (
            <div className="relative w-full h-full flex flex-col justify-center items-center color-[var(--textColor)] gap-1">
                <TestSubjectRender
                    testId={id}
                    testStatus={testStatus}
                    user={user}
                    subjects={subjects}
                    testSubject={activeSubject}
                    testType={testType}
                />
            </div>
        );
    }

    const value = {
        testSubjects,
        setTestSubjects,
        testSections,
        setTestSections,
        testQuestions,
        setTestQuestions,
        testParagraphs,
        setTestParagraphs
    };

    if (!testMetadata.id) {
        return;
    }

    const handleTabClick = (testSubjectId) => {
        if (testSubjectId === null) {
            setActiveSubject(null)
        } else {
            setActiveSubject(testSubjects.find(testSubject => testSubject.subject_id === testSubjectId) || null);
        }
    };

    const scrollTabs = (direction) => {
        if (tabsRef.current) {
            const { current } = tabsRef;
            const scrollAmount = direction === 'left' ? -current.offsetWidth / 3 : current.offsetWidth / 3;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <TestContext.Provider value={value}>
                <div className="relative w-full flex flex-col shadow-md">

                    <div className="flex justify-between items-center rounded-md overflow-hidden">
                        <button className="text-white bg-[var(--SecondaryBkg)] flex justify-center items-center border-0 gap-1"
                            onClick={() => scrollTabs('left')}>
                            <ChevronLeftIcon className="w-7 h-7 p-1" />
                        </button>

                        <div className="flex flex-1 flex-start items-center overflow-x-auto scrollbar-hide h-7 bg-[var(--SecondaryBkg)]"
                            draggable ref={tabsRef}>
                            {/* <Tabs
                                position="start"
                                tabs={testSubjects}
                                selectedTab={activeSubject}
                                setSelectedTab={handleTabClick}
                                renderByTabs={renderTestSubject}
                            /> */}
                            {/* Render Subject */}
                            {testSubjects.map((testSubject) => (
                                <button
                                    className={`text-white flex justify-center items-center border-0 gap-1 h-7 px-2 py-1
                                        ${(activeSubject !== null && activeSubject.id === testSubject.id) ? 'bg-[var(--SecondaryColor)]' : 'bg-[var(--SecondaryBkg)]'
                                        }`}
                                    // className={(activeSubject !== null && activeSubject.id === testSubject.id) ? "tabItem selected" : "tabItem"}
                                    key={testSubject.subject_id}
                                    onClick={() => handleTabClick(testSubject.subject_id)}
                                >
                                    {/* Access the subject_name safely */}
                                    {subjects.find(subject => subject.id === testSubject.subject_id)?.subject_name}

                                    {/* Conditionally render delete button */}
                                    {canCreateTestSubject(testType) && testStatus === 'NOT STARTED' && (
                                        <>
                                            <TrashIcon
                                                className="w-7 h-7 p-1 bg-red-500 hover:text-red-500"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Stop click event from bubbling up to the parent button
                                                    setTestSubjectDeleteOpen(true);
                                                }}
                                                data-tooltip-id="tooltip-edit-test-subject"
                                                data-tooltip-content="Delete the subject"
                                                data-tooltip-place="right"
                                            />
                                            {isTestSubjectDeleteOpen && (
                                                <TestSubjectDelete
                                                    isOpen={isTestSubjectDeleteOpen}
                                                    onRequestClose={() => setTestSubjectDeleteOpen(false)}
                                                    onDeleteSubject={deleteSubject}
                                                    testId={id}
                                                    testSubjectId={testSubject.id} />
                                            )}
                                        </>
                                    )}
                                </button>
                            ))}

                            {/* Create Subject */}
                            {canCreateTestSubject(testType) && testStatus === 'NOT STARTED' && (
                                <>
                                    {(testSubjects.length < 3) &&
                                        <>
                                            <button
                                                className={`text-white flex justify-center items-center border-0 gap-1 h-7 px-2 py-1
                                        ${activeSubject === null ? 'bg-[var(--SecondaryColor)]' : 'bg-[var(--SecondaryBkg)]'}`}
                                                onClick={() => (
                                                    setTestSubjectCreateOpen(true),
                                                    handleTabClick(null)
                                                )} >
                                                <PlusCircleIcon
                                                    className="w-7 h-7 p-1"
                                                    data-tooltip-id="tooltip-edit-test-subject"
                                                    data-tooltip-content="Add the subject"
                                                    data-tooltip-place="right" />
                                            </button>
                                            <TestSubjectCreate
                                                isOpen={isTestSubjectCreateOpen}
                                                onRequestClose={() => setTestSubjectCreateOpen(false)}
                                                onAddSubject={addSubject}
                                                getOrder={() => getNextSubjectOrder()}
                                                testId={id} />
                                        </>
                                    }
                                </>
                            )}
                            <Tooltip id="tooltip-edit-test-subject" />
                        </div >

                        <button className="text-white bg-[var(--SecondaryBkg)] flex justify-center items-center border-0 gap-1"
                            onClick={() => scrollTabs('right')}>
                            <ChevronRightIcon className="w-7 h-7 p-1" />
                        </button>
                    </div>

                    <div className="relative flex flex-col justify-start items-start w-full h-full border-t-2 rounded-md bg-[var(--whiteColorDeam)]">
                        {/* Render Subject */}
                        {renderTestSubject(testType)}
                    </div>

                </div>
            </TestContext.Provider >
        </>
    );
};

export default TestEdit;