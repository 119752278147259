import { Switch } from '@headlessui/react'

const SimpleToggle = ({ enabled, setEnabled, title = "" }) => {
    // const [enabled, setEnabled] = useState(false)

    return (
        <>
            <label htmlFor="image-based-only-check"
                className="block text-sm font-medium leading-6 text-gray-900 mr-2">
                {title}
            </label>

            <Switch
                checked={enabled}
                onChange={setEnabled}
                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[var(--SecondaryColor)] focus:ring-offset-2 data-[checked]:bg-[var(--SecondaryColor)]">
                <span className="sr-only">{title}</span>
                <span
                    aria-hidden="true"
                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5" />
            </Switch>
        </>
    )
}

export default SimpleToggle
