import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import Logo from "../../Assets/LiveClass.png";
import SlotSidebarItems from './Sidebar/SidebarItems';

import SlotInfo from './SlotInfo';
import SlotQuestions from './SlotQuestions';
import SlotCombinedLectureVideo from './SlotCombinedLectureVideo';
import PINCode from '../Common/PINCode';
import SlotTestView from './SlotTest/SlotTestView';
import SlotTestEdit from './SlotTest/SlotTestEdit';
import SlotAttendanceCheck from './SlotAttendanceCheck';
import SlotNotes from './SlotNotes';
import SlotLiveDoubts from './SlotLiveDoubts';
import SlotLiveDoubtClusters from './SlotLiveDoubtClusters';
import { ADMIN_USER_TYPE, securedFetchUsers } from '../../services/UserService';
import { navigateCallbackOptions, returnJsonResponse } from '../../services/AuthenticationService';
import { hasSlotDeleteAuthority, hasSlotTestEditAuthority, hasSlotEditAuthority } from '../../services/TestService';
import { securedDeleteSlotById, securedFetchTimetableSlotById, securedUpdateSlotById, securedFetchLectureHalls, securedDeleteScheduleById, securedUpdateScheduleById, hasSlotPinViewAuthority, needsSlotPinAuthentication, securedFetchSlotStatus, securedDeleteSlotUsers, securedCreateSlotUsers, securedFetchTimetableStudentAttendance, ONLINE_CLASS_TYPE } from '../../services/TimetableService';
import { hasLiveDoubtClusterAuthority } from '../../services/DoubtService';
import { securedFetchBatchesByFilter } from '../../services/BatchService';
import { checkArraysEqual } from '../../services/CommonService';

import { securedFetchTopicsByFilter } from '../../services/SyllabusService';
import { useSearchParams } from 'react-router-dom';
import { securedFetchZoomMeetingById, securedFetchZoomMeetingStatusById } from '../../services/ZoomMeetingService';
import Spinner from '../Common/Tailwind/Spinner';
import PageSidebar from '../Common/Tailwind/Page/PageSidebar';


const SlotView = ({ slotId, user = ADMIN_USER_TYPE }) => {

    const [isAuthorizedToView, setAuthorizedToView] = useState(false);
    const [isPinAuthenticationModalOpen, setPinAuthenticationModalOpen] = useState(false);
    const [lectureData, setLectureData] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedTab, setSelectedTab] = useState(searchParams.get('selectedTab') || 'Info');

    const navigate = useNavigate();
    const { t } = useTranslation();


    useEffect(() => {
        const newSelectedTab = searchParams.get('selectedTab');
        if (!newSelectedTab || newSelectedTab === selectedTab) {
            return;
        }
        setSelectedTab(newSelectedTab);

    }, [searchParams]);

    const tabDict = {
        Info: {
            reloadSlot: true
        },
        Video: {
            reloadSlot: true
        },
        Doubts: {
            reloadSlot: false
        },
        Questions: {
            reloadSlot: false
        }
    };
    tabDict['Live Quiz'] = { reloadSlot: false };

    const handleViewAuth = async () => {
        if (!needsSlotPinAuthentication(user)) {
            setAuthorizedToView(true);
            return true;
        }
        const slotStatus = await securedFetchSlotStatus(slotId, navigateCallbackOptions(navigate));
        if (slotStatus === null || slotStatus.status === 404) {
            alert('Slot does not exist!');
            navigate(-1);
        }
        if (slotStatus.status === 'not_started') {
            alert('Selected lecture has not started yet!');
            navigate(-1);
        }
        if (slotStatus.status === 'finished') {
            setAuthorizedToView(true);
            return true;
        }
        const studentAttendance = await securedFetchTimetableStudentAttendance(slotId, navigateCallbackOptions(navigate));
        if (studentAttendance === null) {
            return false;
        }
        if (!studentAttendance.id) {
            setPinAuthenticationModalOpen(true);
            return false;
        }
        setAuthorizedToView(true);
        return true;
    }

    const onLecturePinAuthenticationSuccess = async () => {
        setAuthorizedToView(true);
        await refreshData();
    }

    const onLecturePinAuthenticationFail = () => {
        navigate(-1);
    }

    function convertDateTimeToDate(dateTimeString) {
        return moment(dateTimeString).format('DD/MM/YYYY');
    }

    function convertDateTimeToTime(dateTimeString) {
        return moment(dateTimeString).format('h:mm A');
    }

    const refreshData = async (initialData = null) => {
        const slotResponse = await securedFetchTimetableSlotById(slotId, navigateCallbackOptions(navigate));
        if (slotResponse === null) {
            return;
        }
        if (slotResponse.status === 404) {
            alert('This slot does not exist!');
            navigate(-1);
            return;
        }
        let currentSlotStatus = lectureData ? lectureData.status : null;
        if (currentSlotStatus !== 'finished') {
            const slotStatus = await securedFetchSlotStatus(slotId, navigateCallbackOptions(navigate));
            if (slotStatus === null) {
                return;
            }
            if (slotStatus.status === 404) {
                alert("This slot's status was not found!");
                navigate(-1);
                return;
            }
            currentSlotStatus = slotStatus.status;
        }
        const lectureDataWithStatus = { ...slotResponse, status: currentSlotStatus };
        setLectureData(lectureDataWithStatus);
    }

    const initialize = async () => {
        const isAuthorized = await handleViewAuth();
        if (!hasSlotEditAuthority(user)) {
            return { auth: isAuthorized };
        }

        return { auth: isAuthorized };
    }

    useEffect(() => {
        (async () => {
            if (!tabDict[selectedTab].reloadSlot && lectureData != null) {
                return;
            }
            let initializedData = null;
            if (lectureData === null) {
                initializedData = await initialize();
                if (initializedData === null && hasSlotDeleteAuthority(user)) {
                    return;
                }
            }
            if (isAuthorizedToView || initializedData.auth) {
                await refreshData(initializedData ? initializedData.data : null)
            };

        })();
    }, [selectedTab]);

    const onRequestCloseAttendanceCheck = () => {
        setPinAuthenticationModalOpen(false);
    }

    console.log(selectedTab);

    const renderMain = () => {
        if (lectureData === null) {
            return <Spinner />;
        }

        return (
            <main className="w-screen min-h-screen md:py-2">
                {selectedTab === 'Info' &&
                    <SlotInfo lectureData={lectureData}
                        setLectureData={setLectureData}
                        slotId={slotId}
                        isAuthorizedToView={isAuthorizedToView}
                        handleViewAuth={handleViewAuth}
                        user={user} />
                }

                {selectedTab == 'Summary' &&
                    <></>
                }

                {selectedTab === 'Doubts' && (
                    hasLiveDoubtClusterAuthority(user) ? (
                        <SlotLiveDoubtClusters lectureData={lectureData} user={user} />
                    ) : (
                        <SlotLiveDoubts lectureData={lectureData} user={user} />
                    ))}

                {selectedTab === 'Notes' &&
                    <SlotNotes user={user} />
                }

                {selectedTab === 'Questions' &&
                    <SlotQuestions lectureData={lectureData} user={user} />
                }

                {selectedTab === 'Video' &&
                    <SlotCombinedLectureVideo lectureData={lectureData} user={user} />
                }

                {selectedTab === 'Live Quiz' && (
                    hasSlotTestEditAuthority(user) ? (
                        <SlotTestEdit lectureData={lectureData} user={user} testType='live' />
                    ) : (
                        <SlotTestView lectureData={lectureData} user={user} testType='live' />
                    ))}
            </main>
        );
    }

    return (
        <div className="relative flex flex-col flex-1 w-full h-full items-center gap-2 sm:gap-3 md:gap-4 text-[var(--textColor)]">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={"Revolutionize Your Batch Management with JEETA!"} /> */}

            {/* Page Main */}
            <div className="flex flex-col md:flex-row w-full bg-white overflow-hidden">
                {needsSlotPinAuthentication(user) && (
                    <SlotAttendanceCheck isOpen={isPinAuthenticationModalOpen} slotId={slotId} onRequestClose={onRequestCloseAttendanceCheck} onLecturePinSuccess={onLecturePinAuthenticationSuccess} onLecturePinFail={onLecturePinAuthenticationFail} />)}

                {/* Sidebar */}
                <PageSidebar sidebarLogo={Logo} sidebarTitle="Batches" sidebarItems={SlotSidebarItems} user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

                {/* Main */}
                {renderMain()}

            </div>

        </div >

    );
}

export default SlotView;
