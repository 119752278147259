import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import './Test.css'

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { TEST_CREATE_TYPE_BASIC, TEST_CREATE_TYPE_PRACTICE, TEST_CREATE_TYPE_VIRTUAL, hasTestCreateAuthority, hasTestEditAuthority, securedFetchRemainingTime, securedMarkStudentTestAttempt, securedStartTest } from '../../services/TestService';
import { useInterval } from '../Hooks/useInteval';

import Timer from '../Common/Timer';

const TestRemainingTime = ({ id, testData, user = ADMIN_USER_TYPE, testStatus, setTestStatus, onTestEnd, refreshKey = 0, timerType = null, testCreateType = TEST_CREATE_TYPE_BASIC }) => {

    const [remainingTime, setRemainingTime] = useState(null);
    const [testDuration, setTestDuration] = useState(testData.test_duration_minutes);
    const [totalTestTimeInSeconds, setTotalTestTimeInSeconds] = useState(null);
    const [syncInterval, setSyncInterval] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setSyncInterval(calculateInterval(remainingTime));
    }, [remainingTime]);

    const calculateInterval = (remainingTime) => {
        if (remainingTime === null) return 1000000;
        if (remainingTime > 60 * 30) {
            return 2 * 60 * 1000;
        } else if (remainingTime > 10 * 60) {
            return 60 * 1000;
        } else if (remainingTime > 1 * 60) {
            return 30 * 1000;
        } else {
            return 10 * 1000;
        }
    };

    useInterval(async () => {
        await incrementalFetchRemainingTime();
    }, syncInterval);

    useInterval(() => {
        decrementTime();
    }, 1000);

    const incrementalFetchRemainingTime = async () => {
        if (remainingTime === null || remainingTime > 0) {
            await fetchRemainingTimeReturnStatus();
            return;
        }
    }

    const setTestStatusFromRemainingTime = async (remainingTimeValue) => {
        let selectedTestStatus = 'NOT STARTED';
        if (remainingTimeValue === null) {
            selectedTestStatus = 'NOT STARTED';
        } else if (remainingTimeValue === 0 || remainingTimeValue === -1) {
            selectedTestStatus = 'FINISHED';
        } else {
            selectedTestStatus = "STARTED";
        }

        if (selectedTestStatus === 'STARTED' && ((testCreateType === TEST_CREATE_TYPE_VIRTUAL && testData.virtual_test_submission_timestamp != null) || (testCreateType === TEST_CREATE_TYPE_PRACTICE && testData.practice_test_submission_timestamp != null))) {
            selectedTestStatus = 'FINISHED';
        }
        setTestStatus(selectedTestStatus);
        return selectedTestStatus;
    }

    const fetchRemainingTimeReturnStatus = async () => {
        const remainingTimeResponse = await securedFetchRemainingTime(testCreateType, id, navigateCallbackOptions(navigate));
        if (remainingTimeResponse === null) {
            return testStatus;
        }
        const fetchedRemainingTime = remainingTimeResponse.time_remaining;
        const status = await setTestStatusFromRemainingTime(fetchedRemainingTime);
        if (fetchedRemainingTime === null) {
            setRemainingTime(-2);
            return status;
        }
        setTestDuration(remainingTimeResponse.total_time_in_seconds / 60);
        setRemainingTime(Math.round(fetchedRemainingTime));
        return status;
    }

    const initialFetchRemainingTime = async () => {
        const status = await fetchRemainingTimeReturnStatus();
        if (status === 'STARTED' && user === STUDENT_USER_TYPE && testCreateType === TEST_CREATE_TYPE_BASIC) {
            const response = await securedMarkStudentTestAttempt(id, navigateCallbackOptions(navigate));
        }
    }

    const onStartTest = async () => {
        const sendTestDuration = testDuration !== null && testDuration !== '' ? testDuration : testCreateType !== TEST_CREATE_TYPE_VIRTUAL ? testData.test_duration_minutes : testData.virtual_test_duration_minutes;
        const response = await securedStartTest(testCreateType, id, { test_duration_minutes: sendTestDuration });
        if (response === null) {
            return;
        }
        await fetchRemainingTimeReturnStatus();
    }

    const onTestTimeEnd = async () => {
        // await initialFetchRemainingTime();
        if (onTestEnd) {
            await onTestEnd();
        }
    }

    const decrementTime = () => {
        if (remainingTime > 0) {
            setRemainingTime(prevTime => prevTime > 0 ? prevTime - 1 : 0);
            return;
        }
    }

    useEffect(() => {
    }, [testDuration]);
    useEffect(() => {
        (async () => {
            await initialFetchRemainingTime();
        })();
    }, [id, refreshKey]);


    useEffect(() => {
        if (testData && testData.test_duration_minutes) {
            setTestDuration(testData.test_duration_minutes);
        }
    }, [testData]);


    useEffect(() => {
        (async () => {
            if (remainingTime === 0 && onTestTimeEnd) {
                setTestStatus('FINISHED');
                await onTestTimeEnd();
            }
        })();
    }, [remainingTime]);

    const renderRemainingTime = () => {
        if (testStatus === "FINISHED") {
            return;
        }

        if (user === STUDENT_USER_TYPE && testStatus === "NOT STARTED") {
            return;
        }

        return (
            <Timer
                editEnable={hasTestEditAuthority(user)}
                status={testStatus}
                setTestStatus={setTestStatus}
                setDuration={setTestDuration}
                start={onStartTest}
                duration={testDuration}
                remainingTime={remainingTime}
                timerType={timerType}
            />
        );
    }

    return (
        <>
            {renderRemainingTime()}
        </>
    );
};

export default TestRemainingTime;