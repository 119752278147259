import moment from "moment";
import 'moment-timezone';

export const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // JS months are 0-based, hence we add one
    let day = date.getDate();

    // if month is less than 10, prepend a '0' to maintain the YYYY-MM-DD format
    if (month < 10) month = '0' + month;

    // Same for day
    if (day < 10) day = '0' + day;

    return `${year}-${month}-${day} 00:00:00`;
};

export const getStartDateOfLastMonth = (date) => {
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    return date;
};

export const getEndDateOfNextMonth = (date) => {
    date.setMonth(date.getMonth() + 2);
    date.setDate(0);
    return date;
};

export const durationStringToSeconds = (duration) => {
    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const secondsAndMilliseconds = parts[2].split('.');
    const seconds = parseInt(secondsAndMilliseconds[0], 10);
    const totalSeconds = (hours * 3600) + (minutes * 60) + seconds; // + milliseconds;
    return totalSeconds;
}

export const getCurrentTimestamp = () => {
    return Math.floor(Date.now() / 1000);
}

export const convertToHumanReadable = (isoTimeString) => {
    return moment(isoTimeString).format('YYYY-MM-DD HH:mm');
}

export const convertUtcToLocalTime = (utcMomentObject) => {
    return utcMomentObject.local();
}

export const changeIndiaTimeToUtcTime = (momentObject) => {
    return momentObject.tz('Asia/Kolkata').utc();
}


export const convertISTToLocalTime = (istDateTimeString) => {
    const istDate = new Date(istDateTimeString);
    const istOffset = 5.5 * 60; // IST is UTC+5:30
    const localOffset = istDate.getTimezoneOffset(); // Local timezone offset in minutes
    console.log(istDate, istOffset, localOffset);
    const localDate = new Date(istDate.getTime() - (istOffset + localOffset) * 60000);
    return localDate;
};

export const convertLocalToIST = (date, time) => {
    const localDateTime = moment(`${date}T${time}`);
    const utcDateTime = localDateTime.utc(); // Convert to UTC first
    const istDateTime = utcDateTime.utcOffset("+05:30"); // Then apply IST offset
    return {
        date: istDateTime.format('YYYY-MM-DD'),
        time: istDateTime.format('HH:mm:ss')
    };
};

export const convertLocalToISTFull = (datetimeString) => {
    const localDateTime = moment(datetimeString);
    const utcDateTime = localDateTime.utc(); // Convert to UTC first
    const istDateTime = utcDateTime.utcOffset("+05:30"); // Then apply IST offset
    return istDateTime.format('YYYY-MM-DDTHH:mm:ss');
}

export const convertISTToLocalFull = (datetimeString) => {
    const istDateTime = moment.tz(datetimeString, "Asia/Kolkata"); // Parse as IST
    const localDateTime = istDateTime.local(); // Convert to local time
    return localDateTime.format('YYYY-MM-DDTHH:mm:ss');
}

export const addISTOffset = (momentObject) => {
    return momentObject
}
