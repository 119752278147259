import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import Spinner from '../../Common/Tailwind/Spinner';
import Dropdown from '../../Common/Tailwind/SelectMenus/Dropdown';
import TopicCreate from '../../Syllabus/TopicCreate';
import TagCreate from '../../Tags/TagCreate';
import PdfUpload from '../../Common/PdfUpload';
import PdfPreview from '../../Common/PdfPreview';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { securedFetchGrades } from '../../../services/BatchService';

import { CheckIcon, XMarkIcon, PlusCircleIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { securedFetchTagsByFilter } from '../../../services/TagService';
import { min } from 'moment';
import { READING_MATERIAL_UPLOAD_TYPES, securedPostReadingMaterial, securedUpdateReadingMaterial, securedUploadPdfList } from '../../../services/ReadingMaterialService';

const ReadingMaterialCreate = ({ isOpen = true, inputReadingMaterialData = { id: null }, onRequestClose = null, onAddReadingMaterial = null, user = ADMIN_USER_TYPE,
    initialSubjects = null, initialSelectedSubject = null, initialGrades = null, initialSelectedGrade = null, initialSelectedTopics = null, initialSelectedTags = null, initialSelectedType = null, allowedTypes = null, hiddenFilters = null }) => {

    const [readingMaterialName, setReadingMaterialName] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [types, setTypes] = useState(allowedTypes ? allowedTypes : READING_MATERIAL_UPLOAD_TYPES);
    const [selectedType, setSelectedType] = useState(allowedTypes ? allowedTypes[0] : READING_MATERIAL_UPLOAD_TYPES[0]);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isImageBased, setImageBased] = useState(true);
    const [isTopicCreateModalOpen, setTopicCreateModalOpen] = useState(false);
    const [isTagCreateModalOpen, setTagCreateModalOpen] = useState(false);
    const [pdfs, setPdfs] = useState([]);
    const [pdfFileNames, setPdfFileNames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const resetFilters = async () => {
        setPdfs([]);
        setPdfFileNames([]);
        setSelectedGrade({});
        setSelectedSubject({});
        const topicsResponse = await fetchAndSetTopics({ subject_ids: subjects.map(subject => subject.id), grades: grades.map(grade => grade.id) });
        if (topicsResponse === null) {
            return;
        }
        setTopics(topicsResponse);
        const tagsResponse = await securedFetchTagsByFilter(null, navigateCallbackOptions(navigate));
        if (tagsResponse === null) {
            return;
        }
        setTags(tagsResponse.data);
        setSelectedTopics([]);
        setReadingMaterialName('');
        setSelectedTags([]);
    }


    const makeTopicFilter = (initialFilter) => {
        const filter = {};
        if (selectedSubject && selectedSubject.id) {
            filter['subject_ids'] = [selectedSubject.id];
        } else {
            filter['subject_ids'] = subjects.map(subject => subject.id);
        }
        if (selectedGrade && selectedGrade.id) {
            filter['grades'] = [selectedGrade.id];
        } else {
            filter['grades'] = grades.map(subject => subject.id);
        }
        return filter;
    }

    const makeReadingMaterialData = (pdfId) => {
        return {
            id: inputReadingMaterialData.id,
            material_name: readingMaterialName,
            material_type: selectedType.id,
            pdf_id: pdfId,
            topics: selectedTopics.map(selectedTopic => ({ topic_id: selectedTopic.id })),
            tags: selectedTags.map(selectedTag => ({ tag_id: selectedTag.id }))
        }
    }

    const isInternalDataInvalid = () => {
        return selectedTopics.length === 0 || readingMaterialName.length === 0;
    }

    const handleSubmit = async () => {
        if (isInternalDataInvalid()) {
            return;
        }
        setIsSaving(true);
        const pdfResponse = await securedUploadPdfList(pdfs, true, navigateCallbackOptions(navigate));
        const pdfId = pdfResponse[0];
        const data = makeReadingMaterialData(pdfId);
        let response;
        if (inputReadingMaterialData.id === null) {
            response = await securedPostReadingMaterial(data, navigateCallbackOptions(navigate));
        } else {
            response = await securedUpdateReadingMaterial(inputReadingMaterialData.id, data, navigateCallbackOptions(navigate));
        }
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onAddReadingMaterial) {
            await onAddReadingMaterial('standalone', inputReadingMaterialData.id !== null ? inputReadingMaterialData.id : response.question_id);
        }
        setIsSaving(false);
        await resetFilters();
        if (onRequestClose != null) {
            onRequestClose();
        }
    }

    const fetchAndSetSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        setSubjects(subjectsResponse.data);
        return subjectsResponse.data;
    };

    const fetchAndSetTopics = async (filter = null) => {
        let topicFilter = filter;
        if (!topicFilter) {
            topicFilter = makeTopicFilter();
        }
        const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate));
        if (topicsResponse === null) {
            return null;
        }
        setTopics(topicsResponse.data);
        return topicsResponse.data;
    };

    const onTopicCreate = async (newTopicId) => {
        const topicsResponse = await fetchAndSetTopics();
        const topicToAdd = topicsResponse.find(topic => (topic.id === newTopicId));
        if (topicToAdd) {
            setSelectedTopics([...selectedTopics, topicToAdd]);
        }
    }

    const onTagCreate = async (newTagId) => {
        const tagsResponse = await securedFetchTagsByFilter(null);
        const tagsResponseData = tagsResponse.data;
        setTags(tagsResponseData);
        const tagsToAdd = tagsResponseData.find(tag => (tag.id === newTagId));
        if (tagsToAdd) {
            setSelectedTags([...selectedTags, tagsToAdd]);
        }
    }

    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        setGrades(gradesResponse.data.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
        return gradesResponse.data;
    };

    useEffect(() => {
        (async () => {
            if (!isOpen) {
                return;
            }
            setIsLoading(true);
            let subjectsResponse = initialSubjects, gradesResponse = initialGrades;
            if (subjectsResponse && subjectsResponse.length > 0) {
                setSubjects(initialSubjects);
            } else {
                subjectsResponse = await fetchAndSetSubjects();
                if (subjectsResponse === null) {
                    return;
                }
            }
            if (allowedTypes) {
                setTypes(allowedTypes);
                setSelectedType(allowedTypes[0]);
            }
            if (gradesResponse && gradesResponse.length > 0) {
                setGrades(gradesResponse.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
            } else {
                gradesResponse = await fetchAndSetGrades();
                if (gradesResponse === null) {
                    return;
                }
            }

            const gradeIdsForTopics = initialSelectedGrade && initialSelectedGrade.id ? [initialSelectedGrade.id] : ((selectedGrade && selectedGrade.id) ? [selectedGrade.id] : gradesResponse.map(grade => grade.id));
            const subjectIdsForTopics = initialSelectedSubject && initialSelectedSubject.id ? [initialSelectedSubject.id] : ((selectedSubject && selectedSubject.id) ? [selectedSubject.id] : subjectsResponse.map(subject => subject.id));

            const topicsResponse = await fetchAndSetTopics({ subject_ids: subjectIdsForTopics, grades: gradeIdsForTopics });
            if (topicsResponse === null) {
                return;
            }
            setTopics(topicsResponse);
            const tagsResponse = await securedFetchTagsByFilter(null);
            if (tagsResponse === null) {
                return;
            }
            setTags(tagsResponse.data);
            if (initialSelectedSubject && initialSelectedSubject.id) {
                setSelectedSubject(initialSelectedSubject);
            }
            if (initialSelectedGrade && initialSelectedGrade.id) {
                setSelectedGrade(initialSelectedGrade);
            }
            const initialSelectedTopicIds = initialSelectedTopics && initialSelectedTopics.length > 0 ? initialSelectedTopics.map(topic => topic.id) : selectedTopics.map(topic => topic.id);
            setSelectedTopics(topicsResponse.filter(topic => initialSelectedTopicIds.includes(topic.id)));

            const initialSelectedTagIds = initialSelectedTags && initialSelectedTags.length > 0 ? initialSelectedTags.map(tag => tag.id) : selectedTags.map(tag => tag.id);
            setSelectedTags(tagsResponse.data.filter(tag => initialSelectedTagIds.includes(tag.id)));
            if (initialSelectedType && initialSelectedType.id) {
                setSelectedType(initialSelectedType);
            }
            setIsLoading(false);
        })();
    }, [isOpen]);

    const onSelectedSubjectChange = async (selection) => {
        const subjectId = selection ? selection.value : null;
        if (subjectId) {
            const topicFilter = makeTopicFilter();
            topicFilter["subject_ids"] = [subjectId]
            const topicsResponseData = await fetchAndSetTopics(topicFilter);
            if (topicsResponseData === null) {
                return;
            }
            setSelectedSubject({ id: subjectId, subject_name: selection.label });
        }
        setSelectedTopics([]);
    };

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection ? selection.value : null;
        if (gradeId) {
            const topicFilter = makeTopicFilter();
            topicFilter["grades"] = [gradeId]
            const topicsResponse = await fetchAndSetTopics(topicFilter);
            if (topicsResponse === null) {
                return;
            }
            setSelectedGrade({ id: gradeId, grade: gradeId.toString() });
        }
        setSelectedTopics([]);
    };

    const onSelectedTopicToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTopicsFromSelect = topics.filter(topic => selectedIds.includes(topic.id));
        setSelectedTopics(selectedTopicsFromSelect);
    };

    const onSelectedTagToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(selectedTagsFromSelect);
    };

    const onTypeChange = (selection) => {
        setSelectedType({ id: selection.value, name: selection.label });
    };

    const onSubmit = (event) => {
        event.preventDefault();
    };

    const isReadingMaterialDataValid = () => {
        return selectedTopics.length > 0;
    }

    const checkDisabled = (key) => {
        return hiddenFilters && hiddenFilters[key] === 'disable';
    }

    const renderReadingMaterialCreate = () => {
        if (isLoading) {
            return <Spinner />;
        }

        if (isSaving) {
            return <Spinner />;
        }

        return (
            <DialogPanel
                transition
                className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                        type="button"
                        onClick={onRequestClose}
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
                    >
                        <span className="sr-only">Close</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>

                <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                        Create New Reading Material
                    </DialogTitle>
                    <div className="mt-5 sm:mx-auto w-full">
                        <form className="space-y-4 overflow-y-auto" onSubmit={onSubmit} >

                            {(!hiddenFilters || (hiddenFilters.subjects !== 'hide' && hiddenFilters.topics !== 'hide')) && (
                                <div className="flex items-center justify-start gap-2 w-full">
                                    <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">{t('subject')}</label>
                                    <div className="flex space-x-4 px-1">
                                        {subjects.map((subject_option) => (
                                            <button
                                                key={subject_option.id}
                                                className={`rounded-md px-3 py-2 text-sm font-medium ${selectedSubject.id === subject_option.id ? 'bg-[var(--PrimaryColor)] text-white' : 'text-gray-500 hover:text-gray-700'} flex`}
                                                onClick={() => onSelectedSubjectChange({ value: subject_option.id, label: subject_option.subject_name })}
                                            >
                                                {subject_option.subject_name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {(!hiddenFilters || (hiddenFilters.grades !== 'hide' && hiddenFilters.topics !== 'hide')) && (
                                <div className="flex items-center justify-start gap-2 w-full">
                                    <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">{t('grade')}</label>
                                    <div className="flex space-x-4 px-1">
                                        {grades.map((grade_option) => (
                                            <button
                                                key={grade_option.id}
                                                className={`rounded-md px-3 py-2 text-sm font-medium ${selectedGrade.id === grade_option.id ? 'bg-[var(--PrimaryColor)] text-white' : 'text-gray-500 hover:text-gray-700'} flex`}
                                                onClick={() => onSelectedGradeChange({ value: grade_option.id, label: grade_option.id.toString() })}
                                            >
                                                {grade_option.id.toString()}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {(!hiddenFilters || (hiddenFilters.types !== 'hide')) && (
                                <div className="flex items-center justify-start gap-2 w-full">
                                    <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">{t('types')}</label>
                                    <Dropdown
                                        list={types}
                                        selected={selectedType}
                                        onSelectionChange={onTypeChange}
                                        nameField='name'
                                        valueField='id'
                                        // isSearchable={true}
                                        isDisabled={checkDisabled('types')}
                                        required={true}
                                        className='w-full flex items-center justify-center gap-2'
                                    />
                                </div>
                            )}

                            {/* {(!hiddenFilters || (hiddenFilters.types !== 'hide')) && (<SelectionDropdown className="readingMaterialSelectionDropdown" name="Type" itemList={types} selectedItem={selectedType} onSelectionChange={onTypeChange} nameField='name' valueField='id' isDisabled={checkDisabled('types')} />)} */}
                            {(!hiddenFilters || (hiddenFilters.topics !== 'hide')) && (
                                <div className="flex items-center justify-start gap-2 w-full">
                                    <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">{t('topics')}</label>
                                    <Dropdown
                                        list={topics}
                                        selected={selectedTopics}
                                        onSelectionChange={onSelectedTopicToggle}
                                        nameField='topic_name'
                                        valueField='id'
                                        multiple={true}
                                        isSearchable={true}
                                        isDisabled={checkDisabled('topics')}
                                        required={true}
                                        className='flex items-center justify-start gap-2 w-full'
                                    />
                                    <button id="add-btn" onClick={() => setTopicCreateModalOpen(true)}>
                                        <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                    </button>
                                </div>)}

                            {(!hiddenFilters || (hiddenFilters.tags !== 'hide')) && (
                                <div className="flex items-center justify-start gap-2 w-full">
                                    <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">{t('tags')}</label>
                                    <Dropdown
                                        list={tags}
                                        selected={selectedTags}
                                        onSelectionChange={onSelectedTagToggle}
                                        nameField='tag_name'
                                        valueField='id'
                                        multiple={true}
                                        isSearchable={true}
                                        isDisabled={checkDisabled('tags')}
                                        required={true}
                                        className='flex items-center justify-start gap-2 w-full'
                                    />
                                    <button id="add-btn" onClick={() => setTagCreateModalOpen(true)}>
                                        <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                    </button>
                                </div>)}

                            <div className="flex flex-col items-start sm:items-center justify-start gap-4 w-full">

                                <div className="flex items-center justify-start gap-2 w-full">
                                    <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 min-w-24 mr-1">{t('name')}</label>
                                    <textarea className="w-full h-auto text-sm py-1.5 pl-3 pr-10 text-[var(--textColor)] border border-gray-300 rounded-lg leading-5"
                                        placeholder="Enter..." onChange={(e) => setReadingMaterialName(e.target.value)} value={readingMaterialName} rows="1" required />
                                </div>

                                {/* PDF file Input */}

                                <PdfUpload title='Pdf' pdfs={pdfs} setPdfs={setPdfs} label={`pdf`} maxSize={1} setPdfFileNames={setPdfFileNames} />
                                <PdfPreview pdfs={pdfs} setImages={setPdfs} pdfFileNames={pdfFileNames} />

                                <div>
                                    <button className="mt-2 flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                                        id='submit' onClick={handleSubmit}>
                                        <span>{t('uploadCaps')}</span>
                                        <ArrowUpTrayIcon aria-hidden="true" className="h-4 w-4 text-white" />
                                    </button>
                                </div>
                            </div>
                        </form>

                        <TopicCreate isOpen={isTopicCreateModalOpen} onRequestClose={() => setTopicCreateModalOpen(false)} initialSubjects={subjects} initialGrades={grades} initialSubject={selectedSubject} initialGrade={selectedGrade} onTopicCreate={onTopicCreate} user={user} />
                        <TagCreate isOpen={isTagCreateModalOpen} onRequestClose={() => setTagCreateModalOpen(false)} allTags={tags} onTagCreate={onTagCreate} user={user} />

                    </div>

                </div>
            </DialogPanel>
        )
    }

    return (

        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-20">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    {renderReadingMaterialCreate()}
                </div>
            </div>
        </Dialog>

        // <Modal overlayClassName="customModal" className="readingMaterialModal flex" isOpen={isOpen} onRequestClose={onRequestClose} >
        // </Modal>

    );
}



export default ReadingMaterialCreate