export const resources = {
    en: {
        translation: {
            upcomingEvents: 'Upcoming Events',
            dashboard: 'Dashboard',
            syllabus: 'Syllabus',
            liveclass: 'Live Class',
            members: 'Members',
            tests: 'Tests',
            profile: 'Profile',
            question: 'Question',
            questions: 'Questions',
            batches: 'Batches',
            batch: 'Batch',
            analytics: 'Analytics',
            lectures: 'Lectures',
            yourProfile: 'Your Profile',
            changePassword: 'Change Password',
            signOut: 'Sign Out',
            feedback: 'Feedback',
            lecturesToday: "Today's Lecture",
            boostProductivity: 'Boost Your Productivity and Success with JEETA!',
            masterTopics: 'Master the Entire Topics in Syllabus with JEETA!',
            masterQuestionDatabase: 'Explore and Master Your Question Database',
            masterLectureDatabase: 'Explore and Master Your Lecture Database',
            masterReadingDatabase: 'Explore and Master Your Reading Material Database',
            masterVideoDatabase: 'Explore and Master Your Video Database',
            revolutionizeBatchManagement: 'Revolutionize Your Batch Management with JEETA!',
            upgradeMemberManagement: 'Upgrade Your Member Management to the Next Level with JEETA!',
            simplifyTestManagement: 'Simplify Your Test Management with JEETA!',
            selectTestType: 'Please select the type, the series and the name of test.',
            selectSubjects: 'Please select the subjects.',
            inCoaching: 'in Your Coaching',
            readings: 'Readings',
            videos: 'Videos',
            subjects: 'Subjects',
            subject: 'Subject',
            grades: 'Grades',
            grade: 'Grade',
            selectGradeBatch: 'Please select the grade and the batch.',
            mainTopics: 'Main Topics',
            topics: 'Topics',
            id: 'ID',
            tags: 'Tags',
            type: 'Type',
            difficulty: 'Difficulty',
            difficulties: 'Difficulties',
            text: 'Text',
            filter: 'Filter',
            create: 'Create',
            checkRequests: 'Check Requests',
            checkAccessRequests: 'Check Access Requests',
            approve: 'Approve',
            deny: 'Deny',
            requestMessage: 'Request Message',
            requestedBy: 'Requested By',
            approved: 'Approved',
            denied: 'Denied',
            pending: 'Pending',
            Physics: 'Physics',
            Chemistry: 'Chemistry',
            Mathematics: 'Mathematics',
            English: 'English',
            Korean: 'Korean',
            student: 'Student',
            students: 'Students',
            teacher: 'Teacher',
            parent: 'Parent',
            admin: 'Admin',
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            status: 'Status',
            edit: 'Edit',
            delete: 'Delete',
            cancel: 'Cancel',
            types: 'Types',
            series: 'Series',
            date: 'Date',
            time: 'Time',
            duration: 'Duration',
            addTestSubject: 'Add Test Subject',
            testTime: 'Test Time',
            testName: 'Test Name',
            backToQuestionsList: 'Back to Questions List',
            add: 'Add',
            hi: 'Hi!',
            formalNameSuffix: '',
            deactivateStudentConfirm: 'Are you sure to DEACTIVATE Student?',
            yes: 'Yes',
            no: 'No',
            newBatch: "New Batch",
            newBatchCreatedSuffix: "New batch has been created",
            newStudent: "New Student",
            registerNewStudent: 'Register New Student',
            newStudentRegisteredSuffix: 'New student has been registered.',
            submitCapitalized: 'SUBMIT',
            fullName: 'Full Name',
            emailAddress: 'E-mail Address',
            contactNumber: 'Contact Number',
            closeCapitalized: 'Close',
            newPassword: 'New Password',
            newPasswordSentSuffix: 'has been sent to your E-mail',
            filterStartCaps: 'Filter',
            newTeacher: 'New Teacher',
            registerNewTeacher: 'Register New Teacher',
            newTeacherRegisteredSuffix: 'New teacher has been registered.',
            deactivateTeacherConfirm: 'Are you sure to DEACTIVATE Teacher?',
            selectPlaceholder: 'Select...',
            password: 'Password',
            forgotPassword: 'Forgot Password',
            startYear: 'Start Year',
            rank: 'Rank',
            enter: 'Enter...',
            createNewBatch: 'Create New Batch',
            createCapitalized: 'CREATE',
            unattempted: 'Unattempted',
            difficult: 'Difficult',
            review: 'Review',
            incorrect: 'Incorrect',
            everyQuestionIs: 'Every Question is',
            aStepTowardsExcellence: 'a Step Towards Excellence',
            exploreAndMaster: 'Explore and Master',
            yourQuestionDatabase: 'Your Question Database',
            difficult: 'Difficult',
            review: 'Review',
            incorrect: 'Incorrect',
            or: 'or',
            everyLectureWatchedBridges: 'Every Lecture Watched Bridges',
            gapsInKnowledge: 'Gaps in Knowledge',
            yourLectureDatabase: 'Your Lecture Database',
            nameOfReadingMaterial: 'Name of Reading Material...',
            everyTheoryRevision: 'Every Theory Revision',
            strengthensYourFoundation: 'Strengthens Your Foundation',
            yourReadingMaterialDatabase: 'Your Reading Material Database',
            nameOfVideo: 'Name of Video...',
            everyVideoRevision: 'Every Video Revision',
            yourVideoDatabase: 'Your Video Database',
            searchText: 'Search Text',
            showHint: 'Show Hint',
            showSolution: 'Show Solution',
            hide: 'Hide',
            showNextHint: 'Show Next Hint',
            solutionIsNotProvidedForThisQuestion: 'Solution is not provided for this question.',
            hurrayYouGotTheRightAnswer: 'Hurray! You got the right answer!',
            sorryYourAnswerIsIncorrect: 'Sorry, your answer is incorrect.',
            pleaseTryAgain: 'Please try again.',
            responseSaved: 'Response saved!',
            responseUnchecked: 'Response Unchecked!',
            loading: 'Loading',
            stepCapitalized: 'STEP',
            uncheck: 'Uncheck',
            save: 'Save',
            check: 'Check',
            solutionCapitalized: 'SOLUTION',
            tests: 'Tests',
            jeetaQuest: 'JEETA Quest',
            jeetaHasPrepared: 'JEETA has prepared',
            newQuestionsForYouToday: 'new questions for you today!',
            noRecommendedQuestions: 'NO recommended questions',
            takeABreather: 'Take a breather! There are',
            fromJEETA: 'from JEETA today.',
            reviewYourProgress: 'Review your progress instead',
            topicRecommendation: 'Topic Recommendation',
            weak: 'Weak',
            lessSolved: 'Less Solved',
            recentlyLearned: 'Recently Learned',
            engageInteractAndSupportYourStudentsWithJEETA: 'Engage, Interact and Support Your Students with JEETA!',
            makeYourDreamComeTrueWithJEETA: 'Make Your Dream Come True with JEETA!',
            questionId: 'Question Id',
            standaloneAllTypes: 'Standalone (All Types)',
            standaloneSingleChoice: 'Standalone (Single Choice)',
            standaloneMultiChoice: 'Standalone (Multi Choice)',
            standaloneNumerical: 'Standalone (Numerical)',
            standaloneSubjective: 'Standalone (Subjective)',
            singleChoice: 'Single Choice',
            multipleChoice: 'Multiple Choice',
            multiChoice: 'Multi Choice',
            numerical: 'Numerical',
            subjective: 'Subjective',
            paragraph: 'Paragraph',
            highlight: 'Highlight',
            delete: 'Delete',
            undoHighlight: 'Undo Highlight',
            undoDelete: 'Undo Delete',
            engageAndLearnThroughClassroomInteractionWithJEETA: 'Engage and Learn through Classroom Interaction with JEETA!',
            transformClassroomsViaDynamicInteractionWithJEETA: 'Transform Classrooms via Dynamic Interaction with JEETA!',
            Mon: 'Mon',
            Tue: 'Tue',
            Wed: 'Wed',
            Thu: 'Thu',
            Fri: 'Fri',
            Sat: 'Sat',
            Sun: 'Sun',
            DDslMMslYY: 'DD/MM/YY',
            DDslMMslYYYY: 'DD/MM/YYYY',
            MMMMYYYY: 'MMMM YYYY',
            DMMMMYYYY: 'D MMMM YYYY',
            DMMM: 'D MMMM',
            MMYYYY: 'MM/YYYY',
            MMYY: 'MM/YY',
            DslMslYYYY: 'D/M/YYYY',
            DslMslYY: 'D/M/YY',
            DslMsl: 'D/M',
            DMMMM: 'D MMMM',
            DMMMYYYY: 'D MMMM YYYY',
            DDslMM: 'DD/MM',
            SuffixMMMMYYYY: 'MMMM YYYY',
            hcommA: 'h:mmA',
            hcommADDslMMslYY: 'h:mmA DD/MM/YY',
            hcommADDMMMMYYYY: 'h:mmA DD MMMM YYYY',
            perfectYourTechniquesByPracticingWithJEETA: 'Perfect Your Techniques by Practicing with JEETA!',
            official: 'Official',
            retake: 'Retake',
            practice: 'Practice',
            answered: 'Answered',
            notAnswered: 'Not Answered',
            notVisited: 'Not Visited',
            markedForReview: 'Marked for Review',
            answeredAndMarkedForReviewWillAlsoBeEvaluated: 'Answered & Marked for Review (will also be evaluated)',
            correct: 'Correct',
            incorrect: 'Incorrect',
            noResponse: 'No Response',
            exitingFullScreenModeWillAutomaticallySubmitYourTest: 'Exiting full screen mode will automatically submit your test.',
            whatActionWouldYouLikeToTake: 'What action would you like to take?',
            submitTestCapital: 'SUBMIT TEST',
            reEnterTestCapital: 'RE-ENTER TEST',

            //NTA Instructions
            instructions: 'Instructions',
            pleaseReadTheInstructionsCarefully: 'Please read the instructions carefully',
            generalInstructions: 'General Instructions',
            totalDurationOf: 'Total duration of',
            is: 'is',
            theClockWillBeSetAtTheServer: 'The clock will be set at the server. The countdown timer in the top right corner of the screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will not be required to end or submit your examination.',
            theCountdownTimerInTheTopRightCornerOfScreenWillDisplayTheRemainingTimeAvailableForYouToCompleteTheExamination: 'The countdown timer in the top right corner of the screen will display the remaining time available for you to complete the examination.',
            whenTheTimerReachesZeroTheExaminationWillEndByItself: 'When the timer reaches zero, the examination will end by itself.',
            youWillNotBeRequiredToEndOrSubmitYourExamination: 'You will not be required to end or submit your examination.',
            theQuestionsPaletteDisplayedOnTheRightSideOfScreenWillShowTheStatusOfEachQuestionUsingOneOfTheFollowingSymbols: 'The questions palette displayed on the right side of the screen will show the status of each question using one of the following symbols:',
            youHaveNotVisitedYet: 'You have not visited yet.',
            youHaveNotAnsweredTheQuestion: 'You have not answered the question.',
            youHaveAnsweredTheQuestion: 'You have answered the question.',
            youHaveNotAnsweredTheQuestionButHaveMarkedTheQuestionForReview: 'You have not answered the question but have marked the question for review.',
            theQuestionsAnsweredAndMarkedForReviewWillBeConsideredForEvalution: 'The questions answered and marked for review will be considered for evaluation.',
            clickOnTheQuestionNumberInTheQuestionPaletteAtTheRightOfYourScreenToGoToThatNumberedQuestionDirectly: 'Click on the question number in the question palette at the right of your screen to go to that numbered question directly.',
            noteThatUsingThisOptionDoesNotSaveYourAnswerToTheCurrentQuestion: 'Note that using this option does not save your answer to the current question.',
            clickOn: 'Click on',
            saveAndNext: 'Save and Next',
            markForReviewAndNext: 'Mark for Review and Next',
            toSaveYourAnswerForTheCurrentQuestionAndThenGoToTheNextQuestion: 'to save your answer for the current question and then go to the next question.',
            toSaveYourAnswerForTheCurrentQuestionMarkItForReviewAndThenGoToTheNextQuestion: 'to save your answer for the current question mark it for review and then go to the next question.',
            answeringAQuestion: 'Answering a Question',
            procedureForAnsweringAMultipleChoiceTypeQuestion: 'Procedure for answering a multiple choice type question',
            toSelectYourAnswerClickOnTheButtonOfOneOfTheOptions: 'To select your answer, click on the button of one of the options.',
            toDeselectYourChosenAnswerClickOnTheButtonOfTheChosenOptionAgainOrClickOnThe: 'To deselect your chosen answer, click on the button of the chosen option again or click on the',
            clearResponse: 'Clear Response',
            button: 'button',
            toChangeYourChosenAnswerClickOnTheButtonOfAnotherOption: 'To change your chosen answer, click on the button of another option.',
            toSaveYourAnswerYouMustClickOnThe: 'To save your answer, you must click on the',
            saveAndNext: 'Save and Next',
            button: 'button',
            toMarkTheQuestionForReviewClickOnThe: 'To mark the question for review, click on the',
            markForReview: 'Mark for Review',
            button: 'button',
            toChangeYourAnswerToAQuestionThatHasBeenAlreadyAnsweredSelectThatQuestionForAnsweringAndThenFollowTheProcedureForAnsweringThatTypeOfQuestion: 'To change your answer to a question that has been already answered, select that question for answering and then follow the procedure for answering that type of question.',
            navigatingThroughSections: 'Navigating through sections',
            sectionsInThisQuestionPaperAreDisplayedOnTheTopBarOfTheScreen: 'Sections in this question paper are displayed on the top bar of the screen.',
            questionsInASectionCanBeViewedByClickingOnTheSectionName: 'Questions in a section can be viewed by clicking on the section name.',
            theSectionYouAreCurrentlyViewingIsHighlighted: 'The section you are currently viewing is highlighted.',
            afterClickTheSaveAndNextButtonOnTheLastQuestionForASectionYouWillAutomaticallyBeTakenToTheFirstQuestionOfTheNextSection: 'After clicking the Save and Next button on the last question for a section, you will automatically be taken to the first question of the next section.',
            youCanShuffleBetweenSectionsAndQuestionsAnythingDuringTheExaminationAsPerYourConvenienceOnlyDuringTheTimeStipulated: 'You can shuffle between sections and questions anything during the examination as per your convenience only during the time stipulated.',
            candidateCanViewTheCorrespondingSectionSummaryAsPartOfTheLegendThatAppearsInEverySectionAboveTheQuestionPalette: 'Candidate can view the corresponding section summary as part of the legend that appears in every section above the question palette.',
            markForReviewAndNext: 'Mark for Review & Next',
            saveAndNext: 'Save & Next',
            markForReview: 'Mark for Review',
            pleaseClickOnTheSaveButton: 'Please click on the Save button',

            iHaveReadAndUnderstoodTheInstructionsAllComputerHardwareAllottedToMeAreInProperWorkingCondition: 'I have read and understood the instructions. All computer hardware allotted to me are in proper working condition.',
            iDeclareThatIMNotInPossessionOfAnyProhibitedGadgetLikeMobilePhoneBluetoothDevicesEtcAnyProhibitedMaterialWithMeIntoTheExaminationHall: 'I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall.',
            iAgreeThatInCaseOfNotAdheringToTheInstructionsIShallBeLiableToBeDebarredFromThisTestAndOrToDisciplinaryActionWhichMayIncludeABanFromFutureTestsExaminations: 'I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include a ban from future Tests / Examinations.',
            iAmReadyToBegin: 'I am ready to begin',
            info: 'Info',
            pleaseAcceptTermsAndConditionsBeforeProceeding: 'Please accept terms and conditions before proceeding.',
            okCapital: 'OK',

            //NTA Marking Scheme
            maximumMarks: 'Maximum Marks',
            thisSectionContains: 'This section contains',
            questionsSmall: 'questions',
            paragraphs: 'paragraphs',
            consistingOf: 'consisting of',
            inTotal: 'in total',
            answerToEachQuestionWillBeEvaluatedAccordingToFollowingMarkingScheme: 'Answer to each question will be evaluated according to following marking scheme:',
            fullMarks: 'Full Marks',
            only: 'only',
            partialMarks: 'Partial Marks',
            ifAllTheFourOptionsAreCorrectButOnlyThreeOptionsAreChosen: 'If all the four options are correct but only three options are chosen;',
            ifThreeOrMoreOptionsAreCorrectButOnlyTwoOptionsAreChosenBothOfWhichAreCorrect: 'If three or more options are correct but only two options are chosen, both of which are correct;',
            ifTwoOrMoreOptionsAreCorrectButOnlyOneOptionIsChosenAndItIsACorrectOption: 'If two or more options are correct but only one option is chosen and it is a correct option;',
            zeroMarks: 'Zero Marks',
            inAllOtherCases: 'in all other cases.',
            negativeMarks: 'Negative Marks',
            eachQuestionHas: 'Each question has',
            optionsABCD: 'options (A), (B), (C) and (D)',
            onlyOneOfTheseFourOptionsIsTheCorrectAnswer: 'only one of these four options is the correct answer.',
            forEachQuestion: 'For each question,',
            chooseTheOptionCorrespondingToTheCorrectAnswer: 'choose the option corresponding to the correct answer.',
            oneOrMoreThanOneOfTheseFourOptionsIsTheCorrectAnswer: 'one or more than one of these four options is the correct answer.',
            theAnswerToEachQuestionIsANumericalValue: 'The answer to each question is a numerical value.',
            enterTheCorrectNumericalValueOfTheAnswer: 'enter the correct numerical value of the answer.',
            ifTheNumericalAnswerHasMoreThanTwoDecimalPlacesTruncateRoundOffTheValueToTwoDecimalPlaces: 'If the numerical answer has more than two decimal places, truncate/round off the value to two decimal places.',
            ifCorrectlyAnswered: 'If correctly answered,',
            ifNoneOfTheOptionsAreCorrect: 'If none of the options are correct,',
            ifNoneOfTheOptionsIsChosen: 'If none of the options is chosen (i.e. the question is unanswered),',
            ifIncorrectlyAnswered: 'If incorrectly answered,',
            ifTheCorrectOptionIsChosen: 'If the correct option is chosen,',
            ifAllTheCorrectOptionsAreChosen: 'If all the correct options are chosen,',
            ifTheCorrectNumericalValueIsEntered: 'If the correct numerical value is entered,',
            submitCapitalStart: 'Submit',
            questionRelated: 'Question related',
            solutionRelated: 'Solution related',
            solutionNotProvidedForThisQuestion: 'Solution is not provided for this question.',
            questionType: 'Question Type',
            mixedPractice: 'Mixed(Practice)',
            timeLeft: 'Time Left',
            testHasNotStartedYet: 'Test has not started yet.',
            thereAreNoSectionsToShowInThisTest: 'There are no sections to show in this test.',
            onceYouSubmitYouWontBeAbleToAttemptTheTestAgain: 'Once you submit, you won\'t be able to attempt the test again.',
            areYouSureYouWantToSubmit: 'Are you sure you want to submit?',
            yesCapital: 'YES',
            noCapital: 'NO',
            onlyCapital: 'ONLY',

            //NTA Test Header
            viewPaper: 'View Paper',
            viewInstructions: 'View Instructions',
            inProgressCapital: 'IN PROGRESS',
            completedCapital: 'COMPLETED',
            readyCapital: 'READY',
            testReportCapital: 'TEST REPORT',
            startTestCapital: 'START TEST',
            refreshCapital: 'REFRESH',
            clickToStartTest: 'Click to start test',
            keepClickingRefreshButton: 'Keep clicking the refresh button',
            section: 'Section',
            paragraphRelated: 'Paragraph related',
            testHasFinished: 'Test has finished!',
            PHY: 'PHY',
            MATH: 'MATH',
            CHEM: 'CHEM',
            SEC: 'SEC',
            physicsAbbreviation: 'P',
            mathematicsAbbreviation: 'M',
            chemistryAbbreviation: 'C',
            scienceAbbreviation: 'S',
            goBack: 'GO BACK',
            backToTestsList: 'Back to Tests List',
            youHaveAlreadySubmittedTheTest: 'You have already submitted the test!',
            exitFullScreenModeWillSubmitTest: 'Exiting full screen mode will automatically submit your test.',
            whatActionWouldYouLikeToTake: 'What action would you like to take?',
            submitTest: 'Submit Test',
            reEnterTest: 'Re-Enter Test',
            createNewPracticeTest: 'Create New Practice Test',
            startCapital: 'START',
            completedCapital: 'COMPLETED',
            pleaseOpenTestInPCOrTablet: 'Please open test in PC or Tablet.',
            startedCapital: 'STARTED',
            marksObtained: 'Marks Obtained',
            timeSpent: 'Time Spent',
            studentName: 'Student Name',
            averageMarks: 'Average Marks',
            totalMarks: 'Total Marks',
            numberID: 'Number (ID)',
            chart: 'Chart',
            table: 'Table',
            response: 'Response',
            studentNameId: 'Student Name (ID)',
            totalMarksObtained: 'Total Marks Obtained',
            summaryCapital: 'SUMMARY',
            detailCapital: 'DETAIL',
            totalNumberOfQuestions: 'Total Number of Questions',
            totalNumberOfStudents: 'Total Number of Students',
            totalMarks: 'Total Marks',
            testInfoCapital: 'TEST INFO',
            upcomingCapital: 'UPCOMING',
            finishedCapital: 'FINISHED',
            startedCapital: 'STARTED',
            createNewTest: 'Create New Test',
            minutes: 'minutes',
            testTime: 'Test Time',
            minutesCapital: 'MINUTES',
            retakeTheTest: 'Retake the Test',
            batchName: 'Batch Name',
            수학: 'Mathematics',
            영어: 'English',
            물리: 'Physics',
            online: "Online",
            noLiveQuiz: 'No Live Quiz',
            noVideoUploaded: 'Video is not uploaded yet',
            endRecording: 'End Recording',
            uploadVideo: 'Upload Video',
            dragNDropFileHere: 'Drag \'n\' drop files here ...',
            clickToSelectFiles: 'Click to select files...',
            recordingEnded: 'Recording is ended!',
            recordingEndedError: 'Recording ended error!',
            videoUploading: 'Video is being uploaded...',
            videoUploadingComplete: 'You will be notified once the video upload is complete.',
            welcomeToLiveClassStreaming: 'Welcome to our Live Class Streaming!',
            joinLiveClass: 'Join Live Classroom',
            clickTheButton: 'Click the button',
            makeTheMostOutofTodayClass: 'Make the most out of today\'s class!',
            easy: 'E',
            medium: 'M',
            hard: 'H',
            uploadCaps: 'UPLOAD',
            createNewQuestion: 'Create New Question',
            createNewTest: 'Create New Test',
            editTest: 'Edit Test',
            chooseQuestionsAutomatically: 'Choose Questions Automatically',
            coverEntireSyllabus: 'Cover Entire Syllabus',
            excludePreviousTestQuestions: 'Exclude Previous Test Questions',
            requestSubmittedSuccessfully: 'Request submitted successfully!',
            accessRequestPendingApproval: 'Your access request is now pending approval.',
            noLectureToday: 'No lecture today!',
            reviewYourProgress: 'Review your progress instead',

            enterTheLecture: 'Enter the Lecture',
        }
    },
    ko: {
        translation: {
            upcomingEvents: '계획된 일정',
            dashboard: '대시보드',
            syllabus: '강의자료',
            liveclass: '라이브강의',
            members: '사용자',
            tests: '테스트',
            profile: '프로필',
            question: '문제',
            questions: '문제',
            batches: '강좌',
            batch: '강좌',
            analytics: '분석',
            lectures: '강의',
            yourProfile: '프로필',
            changePassword: '비밀번호 변경',
            signOut: '로그아웃',
            feedback: '피드백',
            lecturesToday: '오늘의 수업',
            boostProductivity: 'JEETA와 함께 생산성을 극대화하여 성공의 길을 열어보세요',
            masterTopics: 'JEETA와 함께 모든 개념과 유형을 완벽히 정복하세요',
            masterQuestionDatabase: '질문 데이터베이스 탐색 및 마스터!',
            masterLectureDatabase: '강의 데이터베이스 탐색 및 마스터!',
            masterReadingDatabase: '학습 데이터베이스 탐색 및 마스터!',
            masterVideoDatabase: '비디오 데이터베이스 탐색 및 마스터!',
            revolutionizeBatchManagement: 'JEETA와 함께 강좌 관리를 혁신적으로 개선하세요',
            upgradeMemberManagement: 'JEETA와 함께 학생 관리를 스마트하게 업그레이드하세요',
            selectTestType: '시험 유형, 시리즈 및 이름을 선택해주세요.',
            selectSubjects: '과목을 선택해주세요.',
            inCoaching: '학원에서',
            readings: '학습',
            videos: '비디오',
            subjects: '과목',
            subject: '과목',
            grades: '학년',
            grade: '학년',
            selectGradeBatch: '학년과 강좌를 선택해주세요.',
            mainTopics: '주요 주제',
            topics: '주제',
            id: 'ID',
            tags: '태그',
            type: '유형',
            difficulty: '난이도',
            difficulties: '난이도',
            text: '텍스트',
            filter: '필터',
            create: '신규 추가',
            checkRequests: '요청 확인',
            checkAccessRequests: '엑세스 요청 확인',
            approve: '승인',
            deny: '거절',
            requestMessage: '요청 메시지',
            requestedBy: '요청자',
            approved: '승인됨',
            denied: '거절됨',
            pending: '대기중',
            Physics: '물리',
            Chemistry: '화학',
            Mathematics: '수학',
            English: '영어',
            Korean: '한국어',
            student: '학생',
            students: '학생',
            teacher: '선생님',
            parent: '학부모',
            admin: '관리자',
            name: '이름',
            email: '이메일',
            phone: '전화번호',
            status: '상태',
            edit: '수정',
            delete: '삭제',
            cancel: '취소',
            types: '유형',
            series: '시리즈',
            date: '날짜',
            time: '시간',
            duration: '시간',
            minutes: '분',
            backToQuestionsList: '문제 목록으로 돌아가기',
            hi: '안녕하세요!',
            formalNameSuffix: '님',
            deactivateStudentConfirm: '학생을 비활성화하시겠습니까?',
            yes: '예',
            no: '아니오',
            newBatch: "신규 강좌",
            newBatchCreatedSuffix: "신규 강좌가 추가되었습니다.",
            newStudent: '신규 학생',
            registerNewStudent: '신규 학생 등록',
            newStudentRegisteredSuffix: '신규 학생이 등록되었습니다.',
            submitCapitalized: '제출',
            fullName: '이름',
            emailAddress: '이메일 주소',
            contactNumber: '전화번호',
            closeCapitalized: '닫기',
            newPassword: '새 비밀번호',
            newPasswordSentSuffix: '이메일로 전송되었습니다.',
            filterStartCaps: '필터',
            newTeacher: '신규 선생님',
            registerNewTeacher: '신규 선생님 등록',
            newTeacherRegisteredSuffix: '신규 선생님이 등록되었습니다.',
            deactivateTeacherConfirm: '선생님을 비활성화하시겠습니까?',
            selectPlaceholder: '선택...',
            password: '비밀번호',
            forgotPassword: '비밀번호 찾기',
            startYear: '시작 연도',
            rank: '순위',
            enter: '입력...',
            createNewBatch: '신규 강좌 추가',
            createCapitalized: '신규 추가',
            unattempted: '시도 안 한 문제',
            difficult: '난이도 높은 문제',
            review: '다시보기 문제',
            incorrect: '틀린 문제',
            everyQuestionIs: '모든 질문은',
            aStepTowardsExcellence: '성공의 한 계단',
            exploreAndMaster: '탐색 및 마스터',
            yourQuestionDatabase: '문제 은행',
            or: '또는',
            everyLectureWatchedBridges: '모든 강의 시청은',
            gapsInKnowledge: '지식의 격차를 메우는 한 계단',
            yourLectureDatabase: '강의 데이터베이스',
            nameOfReadingMaterial: '학습 자료 이름...',
            everyTheoryRevision: '모든 이론 리뷰',
            strengthensYourFoundation: '기초를 강화하는',
            yourReadingMaterialDatabase: '학습 자료 데이터베이스',
            nameOfVideo: '비디오 이름...',
            everyVideoRevision: '모든 비디오 리뷰',
            yourVideoDatabase: '비디오 데이터베이스',
            searchText: '검색 텍스트',
            showHint: '힌트 보기',
            showSolution: '해설 보기',
            solutionIsNotProvidedForThisQuestion: '이 문제에 대한 해설이 제공되지 않았습니다.',
            hurrayYouGotTheRightAnswer: '축하합니다! 정답을 맞추셨습니다. 훌륭합니다!',
            sorryYourAnswerIsIncorrect: '아쉽지만 정답이 아닙니다. 다시 도전해보세요!',
            pleaseTryAgain: '다시 시도해주세요.',
            responseSaved: '응답이 저장되었습니다!',
            responseUnchecked: '응답이 체크되지 않았습니다!',
            loading: '로딩중',
            stepCapitalized: '단계',
            uncheck: '선택 취소',
            save: '저장',
            check: '선택',
            solutionCapitalized: '해설',
            showNextHint: '다음 힌트 보기',
            hide: '숨기기',
            jeetaQuest: 'JEETA 퀘스트',
            jeetaHasPrepared: 'JEETA는',
            newQuestionsForYouToday: '오늘을 위해 새롭고 흥미로운 문제를 준비했어요!',
            noRecommendedQuestions: '추천할 문제가 없습니다.',
            takeABreather: '오늘은 잠시 쉬어가며 재충전하세요!',
            fromJEETA: 'JEETA에서 준비한',
            reviewYourProgress: '학습 진행 상황을 확인하세요',
            topicRecommendation: '추천 주제',
            weak: '취약한 개념',
            lessSolved: '적게 풀어본 개념',
            recentlyLearned: '최근에 학습한 개념',
            engageInteractAndSupportYourStudentsWithJEETA: 'JEETA로 학생들과 소통하고, 참여를 유도하며, 효과적으로 지원하세요',
            makeYourDreamComeTrueWithJEETA: 'JEETA로 꿈을 이루세요!',
            questionId: '질문 ID',
            standaloneAllTypes: '객관식 (모든 유형)',
            standaloneSingleChoice: '객관식 (단일 선택)',
            standaloneMultiChoice: '객관식 (다중 선택)',
            standaloneNumerical: '객관식 (수치형)',
            standaloneSubjective: '독립형 (주관식)',
            singleChoice: '단일 선택',
            multiChoice: '복수 선택',
            multipleChoice: '복수 선택',
            numerical: '수치형',
            subjective: '주관식',
            paragraph: '문단',
            highlight: '강조',
            delete: '삭제',
            undoHighlight: '하이라이트 취소',
            undoDelete: '삭제 취소',
            transformClassroomsViaDynamicInteractionWithJEETA: 'JEETA와 함께 실시간 상호작용으로 강의를 혁신하세요',
            engageAndLearnThroughClassroomInteractionWithJEETA: 'JEETA로 상호작용을 통해 적극적으로 참여하고 학습하세요',
            Mon: '월',
            Tue: '화',
            Wed: '수',
            Thu: '목',
            Fri: '금',
            Sat: '토',
            Sun: '일',
            D: 'DD일',
            MM: 'MM월',
            DDslMMslYY: 'YY/MM/DD',
            DDslMMslYYYY: 'YYYY/MM/DD',
            MMMMYYYY: 'YYYY년 MM월',
            DMMMMYYYY: 'YYYY년 MM월 DD일',
            DMMM: 'MM월 DD일',
            MMYYYY: 'YYYY/MM',
            MMYY: 'YY/MM',
            DslMslYYYY: 'YYYY/MM/DD',
            DslMslYY: 'YY/MM/DD',
            DslMsl: 'MM/DD',
            DDslMM: 'MM/DD',
            SuffixMMMMYYYY: 'YYYY년 MM월',
            hcommA: 'A h:mm',
            hcommADDslMMslYY: 'YY/MM/DD A h:mm',
            hcommADDMMMMYYYY: 'YY년 MM월 DD일 A h:mm',
            perfectYourTechniquesByPracticingWithJEETA: 'JEETA로 연습하며 기술을 완벽하게 다듬어보세요',
            official: '공식',
            retake: '재시험',
            practice: '연습',
            answered: '정답',
            notAnswered: '미응답',
            notVisited: '미방문',
            markedForReview: '리뷰 표시',
            answeredAndMarkedForReviewWillAlsoBeEvaluated: '정답 및 리뷰 표시 (평가에도 포함)',
            correct: '정답',
            incorrect: '오답',
            noResponse: '미응답',
            exitingFullScreenModeWillAutomaticallySubmitYourTest: '전체 화면 모드를 종료하면 자동으로 테스트가 제출됩니다.',
            whatActionWouldYouLikeToTake: '어떤 동작을 원하십니까?',
            submitTestCapital: '테스트 제출',
            reEnterTestCapital: '테스트 재입장',
            add: '추가',
            // NTA Instructions
            instructions: '시험 안내',
            pleaseReadTheInstructionsCarefully: '안내를 자세히 읽어주세요.',
            generalInstructions: '일반 지침',
            totalDurationOf: '총 기간',
            is: '은',
            theClockWillBeSetAtTheServer: '시계는 서버에 설정됩니다. 화면 오른쪽 상단에 있는 카운트다운 타이머는 남은 시간을 표시합니다. 타이머가 0이 되면 시험이 자동으로 종료됩니다. 시험을 종료하거나 제출할 필요가 없습니다.',
            theQuestionsPaletteDisplayedOnTheRightSideOfScreenWillShowTheStatusOfEachQuestionUsingOneOfTheFollowingSymbols: '화면 오른쪽에 표시되는 질문 팔레트는 각 질문의 상태를 다음 기호 중 하나로 표시합니다:',
            youHaveNotVisitedYet: '아직 방문하지 않은 상태입니다.',
            youHaveNotAnsweredTheQuestion: '질문에 답하지 않은 상태입니다.',
            youHaveAnsweredTheQuestion: '질문에 답한 상태입니다.',
            youHaveNotAnsweredTheQuestionButHaveMarkedTheQuestionForReview: '질문에 답하지 않았지만 질문을 리뷰로 표시했습니다.',
            theQuestionsAnsweredAndMarkedForReviewWillBeConsideredForEvalution: '질문에 답하고 리뷰로 표시한 질문은 평가에 포함됩니다.',
            clickOnTheQuestionNumberInTheQuestionPaletteAtTheRightOfYourScreenToGoToThatNumberedQuestionDirectly: '질문 팔레트의 질문 번호를 클릭하여 해당 번호의 질문으로 직접 이동하세요.',
            noteThatUsingThisOptionDoesNotSaveYourAnswerToTheCurrentQuestion: '이 옵션을 사용하면 현재 질문에 대한 응답을 저장하지 않습니다.',
            clickOnSaveAndNextToSaveYourAnswerForTheCurrentQuestionAndThenGoToTheNextQuestion: '저장 및 다음을 클릭하여 현재 질문에 대한 응답을 저장하고 다음 질문으로 이동하세요.',
            clickOnMarkForReviewAndNextToSaveYourAnswerForTheCurrentQuestionMarkItForReviewAndThenGoToTheNextQuestion: '리뷰 및 다음을 클릭하여 현재 질문에 대한 응답을 저장하고 리뷰로 표시하고 다음 질문으로 이동하세요.',
            answeringAQuestion: '질문 답하기',
            procedureForAnsweringAMultipleChoiceTypeQuestion: '다중 선택 유형 질문에 대한 절차',
            toSelectYourAnswerClickOnTheButtonOfOneOfTheOptions: '응답을 선택하려면 옵션 중 하나의 버튼을 클릭하세요.',
            toDeselectYourChosenAnswerClickOnTheButtonOfTheChosenOptionAgainOrClickOnThe: '응답을 선택 취소하려면 선택한 옵션의 버튼을 다시 클릭하거나 클릭하세요.',
            clearResponse: '응답 지우기',
            button: '버튼',
            toChangeYourChosenAnswerClickOnTheButtonOfAnotherOption: '응답을 변경하려면 다른 옵션의 버튼을 클릭하세요.',
            toSaveYourAnswerYouMustClickOnThe: '응답을 저장하려면 클릭하세요.',
            saveAndNext: '저장 및 다음',
            button: '버튼',
            toMarkTheQuestionForReviewClickOnThe: '리뷰를 위해 질문을 표시하려면 클릭하세요.',
            markForReview: '리뷰 표시',
            button: '버튼',
            toChangeYourAnswerToAQuestionThatHasBeenAlreadyAnsweredSelectThatQuestionForAnsweringAndThenFollowTheProcedureForAnsweringThatTypeOfQuestion: '이미 답변한 질문에 대한 응답을 변경하려면 해당 질문을 선택하고 해당 유형의 질문에 대한 절차를 따르세요.',
            navigatingThroughSections: '섹션 탐색',
            sectionsInThisQuestionPaperAreDisplayedOnTheTopBarOfTheScreen: '이 질문 지문의 섹션은 화면 상단에 표시됩니다.',
            questionsInASectionCanBeViewedByClickingOnTheSectionName: '섹션의 질문은 섹션 이름을 클릭하여 볼 수 있습니다.',
            theSectionYouAreCurrentlyViewingIsHighlighted: '현재 보고 있는 섹션은 강조 표시됩니다.',
            afterClickTheSaveAndNextButtonOnTheLastQuestionForASectionYouWillAutomaticallyBeTakenToTheFirstQuestionOfTheNextSection: '섹션의 마지막 질문에서 저장 및 다음 버튼을 클릭하면 자동으로 다음 섹션의 첫 번째 질문으로 이동합니다.',
            youCanShuffleBetweenSectionsAndQuestionsAnythingDuringTheExaminationAsPerYourConvenienceOnlyDuringTheTimeStipulated: '시험 중 섹션과 질문 사이를 쉽게 이동할 수 있습니다. 시간 제한 내에서만 가능합니다.',
            candidateCanViewTheCorrespondingSectionSummaryAsPartOfTheLegendThatAppearsInEverySectionAboveTheQuestionPalette: '각 섹션의 위에 표시되는 범례 부분에 해당 섹션의 요약을 볼 수 있습니다.',
            markForReviewAndNext: '리뷰 및 다음',
            saveAndNext: '저장 및 다음',
            markForReview: '리뷰 표시',
            pleaseClickOnTheSaveButton: '저장 버튼을 클릭하세요.',
            iHaveReadAndUnderstoodTheInstructionsAllComputerHardwareAllottedToMeAreInProperWorkingCondition: '지침을 읽고 이해했습니다. 할당된 모든 컴퓨터 하드웨어는 정상적으로 작동하고 있습니다.',
            iDeclareThatIMNotInPossessionOfAnyProhibitedGadgetLikeMobilePhoneBluetoothDevicesEtcAnyProhibitedMaterialWithMeIntoTheExaminationHall: '제가 소지하고 있지 않은 모든 금지된 기기 또는 재료를 소지하고 있지 않습니다.',
            iAgreeThatInCaseOfNotAdheringToTheInstructionsIShallBeLiableToBeDebarredFromThisTestAndOrToDisciplinaryActionWhichMayIncludeABanFromFutureTestsExaminations: '지침을 따르지 않을 경우 해당 시험에서 참여할 수 없으며, 이에 대한 처벌이 가능합니다.',
            iAmReadyToBegin: '시작하려면 클릭하세요.',
            info: '정보',
            pleaseAcceptTermsAndConditionsBeforeProceeding: '시작하기 전에 약관을 수락하세요.',
            okCapital: '확인',
            maximumMarks: '최대 점수',
            thisSectionContains: '이 섹션에는',
            questionsSmall: '질문',
            paragraphs: '문단',
            consistingOf: '이 문단은',
            inTotal: '이 문단은',
            answerToEachQuestionWillBeEvaluatedAccordingToFollowingMarkingScheme: '각 질문은',
            fullMarks: '최대 점수',
            only: '오직',
            partialMarks: '부분 점수',
            ifAllTheFourOptionsAreCorrectButOnlyThreeOptionsAreChosen: '모든 네 옵션이 정답이지만 세 옵션만 선택된 경우',
            ifThreeOrMoreOptionsAreCorrectButOnlyTwoOptionsAreChosenBothOfWhichAreCorrect: '세 개 이상의 옵션이 정답이지만 두 옵션만 선택되고 둘 다 정답인 경우',
            ifTwoOrMoreOptionsAreCorrectButOnlyOneOptionIsChosenAndItIsACorrectOption: '두 개 이상의 옵션이 정답이지만 하나의 옵션만 선택되고 그 옵션이 정답인 경우',
            zeroMarks: '0점',
            inAllOtherCases: '모든 다른 경우에는',
            negativeMarks: '음의 점수',
            eachQuestionHas: '각 질문은',
            optionsABCD: '옵션 (A), (B), (C) 및 (D)',
            onlyOneOfTheseFourOptionsIsTheCorrectAnswer: '하나의 옵션만 정답입니다.',
            forEachQuestion: '각 질문에 대해,',
            chooseTheOptionCorrespondingToTheCorrectAnswer: '정답에 해당하는 옵션을 선택하세요.',
            oneOrMoreThanOneOfTheseFourOptionsIsTheCorrectAnswer: '하나 이상의 옵션이 정답인 경우',
            theAnswerToEachQuestionIsANumericalValue: '각 질문의 정답은 수치형입니다.',
            enterTheCorrectNumericalValueOfTheAnswer: '정답의 수치형 값을 입력하세요.',
            ifTheNumericalAnswerHasMoreThanTwoDecimalPlacesTruncateRoundOffTheValueToTwoDecimalPlaces: '수치형 정답이 두 자리 이상의 소수점을 포함하는 경우, 소수점 둘째 자리까지 잘라내거나 반올림하세요.',
            ifCorrectlyAnswered: '정답을 맞추면,',
            ifIncorrectlyAnswered: '오답을 택하면,',
            ifNoneOfTheOptionsAreCorrect: '정답이 없는 경우,',
            ifNoneOfTheOptionsIsChosen: '답을 선택하지 않은 경우 (즉, 문제에 미응답한 경우),',
            ifTheCorrectOptionIsChosen: '정답 옵션을 선택하면,',
            ifAllTheCorrectOptionsAreChosen: '모든 정답 옵션을 선택하면,',
            ifTheCorrectNumericalValueIsEntered: '정답의 수치형 값을 입력하면,',
            submitCapitalStart: '제출',
            questionRelated: '질문 관련',
            solutionRelated: '해설 관련',
            solutionNotProvidedForThisQuestion: '이 질문에 대한 해설이 제공되지 않았습니다.',
            questionType: '질문 유형',
            mixedPractice: '혼합(연습)',
            timeLeft: '남은 시간',
            testHasNotStartedYet: '시험이 아직 시작되지 않았습니다.',
            thereAreNoSectionsToShowInThisTest: '이 시험에 보여줄 섹션이 없습니다.',
            onceYouSubmitYouWontBeAbleToAttemptTheTestAgain: '제출하면 다시 시험을 시작할 수 없습니다.',
            areYouSureYouWantToSubmit: '정말로 제출하시겠습니까?',
            yesCapital: '예',
            noCapital: '아니오',
            onlyCapital: '오직',
            viewPaper: '시험 보기',
            viewInstructions: '지침 보기',
            inProgressCapital: '진행중',
            completedCapital: '완료됨',
            readyCapital: '준비중',
            testReportCapital: '시험 보고서',
            startTestCapital: '시험 시작',
            refreshCapital: '새로고침',
            clickToStartTest: '시험 시작',
            keepClickingRefreshButton: '새로고침 버튼을 계속 클릭하세요.',
            section: '섹션',
            paragraphRelated: '문단 관련',
            testHasFinished: '시험이 종료되었습니다.',
            PHY: '물리',
            MATH: '수학',
            CHEM: '화학',
            physicsAbbreviation: '물',
            mathematicsAbbreviation: '수',
            chemistryAbbreviation: '화',
            scienceAbbreviation: '과',
            SEC: '섹션',
            goBack: '돌아가기',
            backToTestsList: '시험 목록으로 돌아가기',
            youHaveAlreadySubmittedTheTest: '이미 시험을 제출했습니다!',
            submitTest: '시험 제출',
            reEnterTest: '시험 재입장',
            exitFullScreenModeWillSubmitTest: '전체 화면 모드를 종료하면 자동으로 테스트가 제출됩니다.',
            whatActionWouldYouLikeToTake: '어떤 동작을 원하십니까?',
            createNewPracticeTest: '새로운 연습 시험 만들기',
            startCapital: '시작',
            completedCapital: '완료',
            pleaseOpenTestInPCOrTablet: '테스트를 PC 또는 태블릿에서 열어주세요.',
            startedCapital: '시작됨',
            marksObtained: '받은 점수',
            timeSpent: '지난 시간',
            studentName: '학생 이름',
            averageMarks: '평균 점수',
            totalMarks: '총 점수',
            numberID: '번호 (ID)',
            chart: '차트',
            table: '표',
            response: '응답',
            studentNameId: '학생 이름 (ID)',
            totalNumberOfQuestions: '총 질문 수',
            totalNumberOfStudents: '총 학생 수',
            totalMarks: '총 점수',
            totalMarksObtained: '총 점수 받은 점수',
            summaryCapital: '요약',
            detailCapital: '세부 정보',
            testInfoCapital: '시험 정보',
            createNewTest: '새로운 시험 만들기',
            upcomingCapital: '시작전',
            finishedCapital: '완료',
            minutes: '분',
            minutesCapital: '분',
            addTestSubject: '시험 과목 추가',
            retakeTheTest: '시험 재시작',
            batchName: '강좌명',
            online: '온라인',
            noLiveQuiz: '퀴즈가 없습니다',
            noVideoUploaded: '비디오가 아직 업로드 되지 않았습니다',
            endRecording: '녹화 종료',
            uploadVideo: '비디오 업로드',
            dragNDropFileHere: '파일을 여기에 드래그앤드롭',
            clickToSelectFiles: '클릭하여 파일 선택',
            recordingEnded: '녹화가 종료되었습니다.',
            recordingEndedError: '녹화 종료 오류!',
            videoUploading: '비디오 업로드 중입니다...',
            videoUploadingComplete: '비디오 업로드가 완료되면 알려드리겠습니다.',
            upload: '업로드',
            uploadCapital: '업로드',
            welcomeToLiveClassStreaming: '환영합니다! 오늘의 라이브 수업에 참여해볼까요?',
            joinLiveClass: '라이브 수업 참여하기',
            clickTheButton: '버튼을 클릭하세요.',
            makeTheMostOutofTodayClass: '오늘 수업에 적극적으로 참여해보세요!',
            easy: '하',
            medium: '중',
            hard: '상',
            createNewQuestion: '새로운 문제 만들기',
            createNewTest: '새로운 시험 만들기',
            editTest: '시험 수정하기',
            chooseQuestionsAutomatically: '문제 자동 선택',
            coverEntireSyllabus: '전 범위에서 출제',
            excludePreviousTestQuestions: '이전 시험 문제 제외',
            testName: '시험 이름',
            requestSubmittedSuccessfully: '액세스 요청이 제출되었습니다!',
            accessRequestPendingApproval: '액세스 요청이 승인을 기다리고 있습니다.',
            noLectureToday: '오늘 수업이 없습니다!',
            lecturesToday: '오늘 수업',
            reviewYourProgress: '복습을 해보세요',

            enterTheLecture: '수업 들어가기',
        }
    },
}