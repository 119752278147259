import React from 'react';

import { CheckCircleIcon, XMarkIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/20/solid'


const Notification = ({ isOpen = true,
    message = "Successfully uploaded",
    description = "",
    buttonText1 = "",
    buttonText2 = "",
    type = "green",
    onClose = null,
    onButton1Click = null,
    onButton2Click = null }) => {

    console.log(isOpen);
    if (!isOpen) {
        return <></>;
    }

    switch (type) {
        case 'green':
            return (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-20 w-11/12 max-w-md shadow-lg">
                    <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                            <div className="shrink-0">
                                <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-green-800"> {message} </p>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50 focus:outline-hidden"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon aria-hidden="true" className="size-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 'yellow':
            return (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-20 w-11/12 max-w-md shadow-lg">
                    <div className="rounded-md bg-yellow-50 p-4">
                        <div className="flex">
                            <div className="shrink-0">
                                <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-yellow-400" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-yellow-700"> {message} </p>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:outline-hidden"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon aria-hidden="true" className="size-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 'red':
            return (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-20 w-11/12 max-w-md shadow-lg">
                    <div className="rounded-md bg-red-50 p-4">
                        <div className="flex">
                            <div className="shrink-0">
                                <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-red-800"> {message} </p>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50 focus:outline-hidden"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon aria-hidden="true" className="size-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 'global-green':
            return (
                // {/* Global notification live region, render this permanently at the end of the document */ }
                <div
                    aria-live="assertive"
                    className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-20"
                >
                    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white ring-1 shadow-lg ring-black/5 transition data-closed:opacity-0 data-enter:transform data-enter:duration-300 data-enter:ease-out data-closed:data-enter:translate-y-2 data-leave:duration-100 data-leave:ease-in data-closed:data-enter:sm:translate-x-2 data-closed:data-enter:sm:translate-y-0">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="shrink-0">
                                        <CheckCircleIcon aria-hidden="true" className="size-6 text-green-400" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1">
                                        <p className="text-sm font-medium text-gray-900">{message}</p>
                                        <p className="mt-1 text-sm text-gray-500">{description}</p>

                                        {(buttonText1 || buttonText2) &&
                                            <div className="mt-4 flex justify-end">
                                                {buttonText1 &&
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold  shadow-xs hover:bg-green-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                                        onClick={onButton1Click}
                                                    >
                                                        {buttonText1}
                                                    </button>}
                                                {buttonText2 &&
                                                    <button
                                                        type="button"
                                                        className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50"
                                                        onClick={onButton2Click}
                                                    >
                                                        {buttonText2}
                                                    </button>}
                                            </div>
                                        }
                                    </div>

                                    <div className="ml-4 flex shrink-0">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:outline-hidden"
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon aria-hidden="true" className="size-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 'global-yellow':
            return (
                // {/* Global notification live region, render this permanently at the end of the document */ }
                <div
                    aria-live="assertive"
                    className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-20"
                >
                    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white ring-1 shadow-lg ring-black/5 transition data-closed:opacity-0 data-enter:transform data-enter:duration-300 data-enter:ease-out data-closed:data-enter:translate-y-2 data-leave:duration-100 data-leave:ease-in data-closed:data-enter:sm:translate-x-2 data-closed:data-enter:sm:translate-y-0">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="shrink-0">
                                        <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-yellow-400" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1">
                                        <p className="text-sm font-medium text-gray-900">{message}</p>
                                        <p className="mt-1 text-sm text-gray-500">{description}</p>

                                        {(buttonText1 || buttonText2) &&
                                            <div className="mt-4 flex justify-end gap-4">
                                                {buttonText1 &&
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center rounded-md bg-yellow-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-xs hover:bg-yellow-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                                        onClick={onButton1Click}
                                                    >
                                                        {buttonText1}
                                                    </button>}
                                                {buttonText2 &&
                                                    <button
                                                        type="button"
                                                        className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50"
                                                        onClick={onButton2Click}
                                                    >
                                                        {buttonText2}
                                                    </button>}
                                            </div>
                                        }
                                    </div>

                                    <div className="ml-4 flex shrink-0">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:outline-hidden"
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon aria-hidden="true" className="size-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 'global-red':
            return (
                // {/* Global notification live region, render this permanently at the end of the document */ }
                <div
                    aria-live="assertive"
                    className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-20"
                >
                    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white ring-1 shadow-lg ring-black/5 transition data-closed:opacity-0 data-enter:transform data-enter:duration-300 data-enter:ease-out data-closed:data-enter:translate-y-2 data-leave:duration-100 data-leave:ease-in data-closed:data-enter:sm:translate-x-2 data-closed:data-enter:sm:translate-y-0">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="shrink-0">
                                        <XCircleIcon aria-hidden="true" className="size-6 text-red-400" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1">
                                        <p className="text-sm font-medium text-gray-900">{message}</p>
                                        <p className="mt-1 text-sm text-gray-500">{description}</p>

                                        {(buttonText1 || buttonText2) &&
                                            <div className="mt-4 flex justify-end">
                                                {buttonText1 &&
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-xs hover:bg-red-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                                        onClick={onButton1Click}
                                                    >
                                                        {buttonText1}
                                                    </button>}
                                                {buttonText2 &&
                                                    <button
                                                        type="button"
                                                        className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50"
                                                        onClick={onButton2Click}
                                                    >
                                                        {buttonText2}
                                                    </button>}
                                            </div>
                                        }
                                    </div>

                                    <div className="ml-4 flex shrink-0">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:outline-hidden"
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon aria-hidden="true" className="size-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        default:
            return null;
    }
}
export default Notification;