import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import iconImage from '../../../Assets/Icon-sprite.png';
import NTAQuestionSlide from '../NTA/NTAQuestionSlide';
import NTAMarkingScheme from './NTAMarkingScheme';
import NTAQuestionBox from './NTAQuestionBox';
import { useNTATest } from './NTATestContext';
import { STUDENT_USER_TYPE } from '../../../services/UserService';
import { TEST_CREATE_TYPE_PRACTICE, canShowSectionMarks } from '../../../services/TestService';

const NTASectionView = ({ startQuestionIndex, onSectionNext = null, onSectionPrevious, handleSubmitTest, user = STUDENT_USER_TYPE }) => {

    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        questionIndex,
        setQuestionIndex,
        getSectionName,
        testCreateType
    } = useNTATest();

    const questions = testQuestions.filter(testQuestion => testQuestion.test_section_id === activeSection.id);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [expandQuestion, setExpandQuestion] = useState(false);
    const navigate = useNavigate();

    const minSwipeDistance = (window.innerWidth > 500) ? (window.innerWidth / 4) : 100;

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            next();
        } else if (isRightSwipe) {
            prev();
        }
    }

    const prev = () => {
        let index = (questionIndex === 0 ? questions.length - 1 : questionIndex - 1);

        setQuestionIndex(index);
    }

    const next = () => {
        let index = (questionIndex === questions.length - 1 ? 0 : questionIndex + 1);

        setQuestionIndex(index);
    }

    const onTestQuestionResponseChange = (questionId, event) => {
        const previousResponse = questionStyleById.find(questionStatus => questionStatus.question_id === questionId);
        const previousExceptQuestion = questionStyleById.filter(questionStatus => questionStatus.question_id !== questionId);
        if (!previousResponse && event === 'VISIT') {
            setQuestionStyleById([...previousExceptQuestion, { question_id: questionId, status: event }]);
            return;
        }
        if (event === 'VISIT') {
            return;
        }
        setQuestionStyleById([...previousExceptQuestion, { question_id: questionId, status: event }]);
    }

    const onQuestionNext = () => {
        if (questionIndex < questions.length - 1) {
            handleClickId(questionIndex + 1);
            return;
        }
        if (onSectionNext) {
            onSectionNext();
        }
    }

    const onQuestionPrevious = () => {
        if (questionIndex > 0) {
            handleClickId(questionIndex - 1);
            return;
        }
        if (onSectionPrevious) {
            onSectionPrevious();
        }
    }

    useEffect(() => {
        (async () => {
            if (questions.length > 0) {
                const index = startQuestionIndex < 0 ? questions.length + startQuestionIndex : startQuestionIndex;
                setQuestionIndex(index);
            }
        })();
    }, [testStatus, activeSection]);

    const renderQuestionSlide = () => {

        if (questionIndex === null) {
            return <></>;
        }

        const questionData = questions[questionIndex].question;

        return (
            <>
                <NTAQuestionSlide
                    basicInfo={questionData.basicInfo ? questionData.basicInfo : null}
                    basicImages={questionData.basicImages ? questionData.basicImages : null}
                    paragraphId={questionData.paragraphId ? questionData.paragraphId : null}
                    questionId={questionData.id}
                    questionIndex={questionIndex + 1}
                    questionsInSection={questions.length}
                    inputQuestionData={questionData}
                    user={user}
                    onTestQuestionResponseChange={onTestQuestionResponseChange}
                    onQuestionNext={onQuestionNext}
                    onQuestionPrevious={onQuestionPrevious}
                    handleSubmitTest={handleSubmitTest}
                />
            </>
        );
    }

    const renderQuestionHeader = () => {
        if (questionIndex === null) {
            return <></>;
        }

        return (<div className="NTAQuestionNumber roboto-bold">Question {questionIndex + 1}{testCreateType === TEST_CREATE_TYPE_PRACTICE ? ` (${questions[questionIndex].question.question_subtype})` : ''}</div>)
    }

    const handleClickId = async (index) => {
        setQuestionIndex(index);
    };

    return (
        <>

            <div className="flex flex-col grow h-full box-border justify-start items-center overflow-y-auto"
                id={expandQuestion ? "expandQuestion" : "shrinkQuestion"} >
                <div
                    style={{ backgroundImage: `url(${iconImage})` }}
                    className="bg-no-repeat w-[17px] h-[48px] cursor-pointer inline absolute right-0 top-[53%] z-[100]"
                    id={expandQuestion ? "showExpandIcon" : "hideExpandIcon"}
                    onClick={() => setExpandQuestion(false)}></div>

                {canShowSectionMarks(testData.test_type) && (
                    <NTAMarkingScheme sectionId={activeSection.id} />
                )}

                {renderQuestionHeader()}

                {renderQuestionSlide()}
            </div>

            <div className="relative w-80 h-full flex-shrink-0 border-2 border-blackCustom box-border bg-icon-sprite bg-instruction-icon bg-no-repeat"
                id={expandQuestion ? "hideQuestionBox" : "showQuestionBox"} >
                <span className="collapse_icon" id={expandQuestion ? "showCollapseIcon" : "hideCollapseIcon"} onClick={() => setExpandQuestion(true)}></span>
                <NTAQuestionBox
                    user={user}
                    handleSubmitTest={handleSubmitTest}
                />
            </div>

        </>
    )
};

export default NTASectionView;
