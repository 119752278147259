import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

import VideoImg from '../../Assets/Video.png';
import VideoUpload from '../Files/Video/VideoUpload';
import VideoPreview from '../Files/Video/VideoPreview';
import VideoViewer from '../Files/Video/VideoViewer';
import MessagePage from '../Common/Tailwind/Page/MessagePage';
import Notification from '../Common/Tailwind/Notification/Notification';
import SlotRecordedLectureVideos from './SlotRecordedLectureVideos';
import Spinner from '../Common/Tailwind/Spinner';
import { hasSlotEndAuthority } from '../../services/TestService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { securedUpdateSlotById, securedUpdateSlotByIdClassLectureVideoId, securedUpdateSlotByIdEndTime } from '../../services/TimetableService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { BsRecordCircle, BsUpload } from 'react-icons/bs';
import { securedCreateClassLectureVideo, securedCreateVideo, securedFetchClassLectureVideoById, securedUploadVideoFile } from '../../services/VideoService';
import TailwindTabs from '../Common/Tailwind/Navigation/Tabs';
import { useAuth } from '../Authentication/AuthContext';
import { convertToHumanReadable } from '../../services/DateService';
import { CloudArrowUpIcon, VideoCameraIcon } from '@heroicons/react/24/outline';

const SlotLectureVideo = ({ lectureData, user = ADMIN_USER_TYPE }) => {
    const [showRecordingEndSuccess, setShowRecordingEndSuccess] = useState(false);
    const navigate = useNavigate();
    const [videoTabs, setVideoTabs] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [videos, setVideos] = useState([]);
    const [videoFileNames, setVideoFileNames] = useState([]);
    const [isUploaded, setIsUploaded] = useState(false);
    const [popupNotification, setPopupNotification] = useState(null);
    const { t } = useTranslation();

    const {
        config
    } = useAuth();


    const onRecordingEnd = async () => {
        const onError = {
            serverErrorCallback: () => {
                setPopupNotification({ message: t('recordingEndedError'), type: 'red' });
            },
            authErrorCallback: authErrorCallbackNavigate(navigate)
        }
        const response = await securedUpdateSlotByIdEndTime(lectureData.id, onError);
        if (response !== null) {
            setShowRecordingEndSuccess(true);
        }
    };

    const renderLectureVideo = () => {
        const videoTitle = `Lecture by ${lectureData.teacher.user_data.first_name} of ${lectureData.subject.subject_name} taken on ${convertToHumanReadable(lectureData.start_time)}`
        return (<VideoViewer videoType='class_lecture' videoId={lectureData.class_lecture_video_id} user={user} videoTitle={videoTitle} defaultApproverId={lectureData.teacher_id} />);
    }
    const renderRecordedVideos = () => {

        return (<SlotRecordedLectureVideos lectureData={lectureData} user={user} />);
    }

    const getRenderByTabs = () => {
        const renderbyTabs = {};
        renderbyTabs['Class Lecture'] = renderLectureVideo;
        renderbyTabs['Related Videos'] = renderRecordedVideos;
        return renderbyTabs;
    }


    const onUploadClick = async () => {
        const videoResponse = await securedUploadVideoFile(videos[0], { title: `Class Recording for lecture of ${lectureData.subject.subject_name} on ${convertToHumanReadable(lectureData.start_time)}` }, { onSuccess: onUploadVideoSuccess }, navigateCallbackOptions(navigate));

        setPopupNotification({ message: "You will be notified once the video upload is complete.", type: 'yellow' });

        // alert("You will be notified once the video upload is complete.")
        setIsUploaded(true);
    }

    const onUploadVideoSuccess = async (url) => {
        const data = { url: url, title: `Class Recording for lecture of ${lectureData.subject.subject_name} on ${convertToHumanReadable(lectureData.start_time)}` };
        const videoCreateResponse = await securedCreateVideo(data, navigateCallbackOptions(navigate));
        if (videoCreateResponse === null) {
            return;
        }
        const classLectureData = { video_id: videoCreateResponse.video_id };
        const classLectureCreateResponse = await securedCreateClassLectureVideo(classLectureData, navigateCallbackOptions(navigate));
        const timetableSlotOpenResponse = await securedUpdateSlotByIdClassLectureVideoId(lectureData.id, { class_lecture_video_id: classLectureCreateResponse.class_lecture_video_id }, navigateCallbackOptions(navigate));
        alert('Video uploading completed!');
    }

    useEffect(() => {
        if (!config) {
            return;
        }
        let allowedTabs = [];
        if (config.DISPLAY_LECTURE_VIDEO_TYPES.includes('class_lecture') || user === ADMIN_USER_TYPE) {
            allowedTabs.push('Class Lecture');
        }
        if (config.DISPLAY_LECTURE_VIDEO_TYPES.includes('recorded_lecture') || user === ADMIN_USER_TYPE) {
            allowedTabs.push('Related Videos');
        }
        setVideoTabs(allowedTabs);
        setSelectedTab(allowedTabs.length > 0 ? allowedTabs[0] : null);
    }, [config]);

    if (videoTabs === null) {
        return <Spinner />
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">

            <div className="relative flex flex-col justify-center items-center gap-2 sm:gap-3 md:gap-6 p-2 sm:p-4 md:p-6 lg:p-8">

                {popupNotification && (
                    <Notification message={popupNotification.message} type={popupNotification.type} onClose={() => setPopupNotification(null)} />
                )}

                {isUploaded && lectureData.class_lecture_video_id === null && (
                    <MessagePage imgsrc={VideoImg} title={t('videoUploading')} message1={""} message2={""} message3={""} />
                )}

                {lectureData.class_lecture_video_id ? (
                    <TailwindTabs
                        tabs={videoTabs}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        renderByTabs={getRenderByTabs()} />
                ) : (
                    !isUploaded && (
                        <div>

                            <MessagePage imgsrc={VideoImg} title={t('noVideoUploaded')} message1={""} message2={""} message3={""} />

                            {
                                hasSlotEndAuthority(user) && (
                                    <>
                                        <button
                                            className="absolute top-0 right-0 flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                                            id="record" onClick={onRecordingEnd} >
                                            <span>{t('endRecording')}</span>
                                            <VideoCameraIcon className="w-5 h-5" />
                                        </button>

                                        <VideoUpload title='Video' videos={videos} setVideos={setVideos} label={`video`} maxSize={1} setVideoFileNames={setVideoFileNames} showTitle={false} />
                                        <VideoPreview videos={videos} setVideos={setVideos} videoFileNames={videoFileNames} />

                                        {videos.length > 0 && (
                                            <button
                                                className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                                                onClick={onUploadClick} >
                                                <span>{t('uploadVideo')}</span>
                                                <CloudArrowUpIcon className="w-5 h-5" />
                                            </button>
                                        )}

                                        {showRecordingEndSuccess && (
                                            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                                <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                                                    <p className="text-lg font-semibold text-gray-800 mb-4">
                                                        {t('recordingEnded')}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )
                            }
                        </div >
                    )
                )}

            </div>
        </div >
    );
};

export default SlotLectureVideo;
