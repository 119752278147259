import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../Common/Tailwind/SelectMenus/Dropdown';
import ReadingMaterialCreate from '../ReadingMaterialCreate/ReadingMaterialCreate';

import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { hasGradeFetchAuthority, securedFetchGrades } from '../../../services/BatchService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';

import { BiFilterAlt, BiMessageSquareAdd } from 'react-icons/bi'
import { securedFetchTagsByFilter } from '../../../services/TagService';
import { READING_MATERIAL_UPLOAD_TYPES, hasReadingMaterialCreateAuthority } from '../../../services/ReadingMaterialService';
import { FunnelIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

const ReadingMaterialCardListFilter = ({ syllabusFilter = null, onSubmitFilter = null, hiddenFilters = null, user = ADMIN_USER_TYPE }) => {

    const [isReadingMaterialCreateModalOpen, setReadingMaterialCreateModalOpen] = useState(false);
    const [nameSubstring, setNameSubstring] = useState('');
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const onFilterClick = async () => {
        const filter = {
            ...syllabusFilter,
            selectedTags,
            selectedTypes,
            nameSubstring
        };
        if (onSubmitFilter) {
            await onSubmitFilter(filter);
        }
    }

    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 780);

    useEffect(() => {
        const handleResize = () => {
            setIsNarrowScreen(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!hiddenFilters || hiddenFilters.tags !== 'hide') {
                const tagFilter = user === ADMIN_USER_TYPE ? null : { is_hidden: false }
                const tagsResponse = await securedFetchTagsByFilter(tagFilter, navigateCallbackOptions(navigate));
                if (tagsResponse === null) {
                    return;
                }
                setTags(tagsResponse.data);
            }
        })();
    }, []);

    const onSelectedTagToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(selectedTagsFromSelect);
    };

    const onSelectedTypeToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTypesFromSelect = READING_MATERIAL_UPLOAD_TYPES.filter(type => selectedIds.includes(type.id));
        setSelectedTypes(selectedTypesFromSelect);
    };

    const renderDetailedReadingMaterialFilter = () => {
        return (
            <div className="flex flex-col justify-center items-center w-full gap-2 md:gap-4" >
                <div className="w-full flex flex-col md:flex-row gap-2 md:gap-4">
                    {(!hiddenFilters || hiddenFilters.tags !== 'hide') &&
                        (<Dropdown
                            className='flex items-center justify-center gap-2 w-full'
                            multiple={true}
                            label={t('tags')}
                            list={tags}
                            selected={selectedTags}
                            onSelectionChange={onSelectedTagToggle}
                            nameField='tag_name'
                            valueField='id'
                            isSearchable={true} />)}

                    {(!hiddenFilters || hiddenFilters.types !== 'hide') &&
                        (<Dropdown
                            className='flex items-center justify-center gap-2 w-full'
                            multiple={true}
                            label={t('types')}
                            list={READING_MATERIAL_UPLOAD_TYPES}
                            selected={selectedTypes}
                            onSelectionChange={onSelectedTypeToggle}
                            nameField='name'
                            valueField='id' />)}

                    {(!hiddenFilters || hiddenFilters.nameSubstring !== 'hide') &&
                        (<div className="flex w-full items-center gap-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                                {t('name')}
                            </label>
                            <input type="text"
                                className="block flex-1 rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                value={nameSubstring}
                                onChange={e => setNameSubstring(e.target.value)}
                                placeholder={t('nameOfReadingMaterial')} />
                        </div>)}                </div>
            </div>
        )
    }

    const renderFilterButtons = () => {
        return (
            <div className="absolute right-4 flex justify-center items-center gap-4">
                <button className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                    onClick={onFilterClick}>
                    <span>{t('filter')}</span>
                    <FunnelIcon aria-hidden="true" className="h-4 w-4" />
                </button>
                {!hasReadingMaterialCreateAuthority(user) ? (<></>) : (
                    <>
                        <span>{t('or')}</span>
                        <button className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                            onClick={() => setReadingMaterialCreateModalOpen(true)} >
                            <span>{t('createCapitalized')}</span>
                            <PlusCircleIcon aria-hidden="true" className="h-4 w-4" />
                        </button>
                    </>
                )}
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-center items-center w-full gap-2 md:gap-4" >

            {/* Filter */}
            {renderDetailedReadingMaterialFilter()}

            <div className="relative px-2 py-4 mb-4 w-full flex flex-col md:flex-row items-center gap-2 md:gap-4">
                {/* Filter Button */}
                {renderFilterButtons()}
            </div>

            <ReadingMaterialCreate
                isOpen={isReadingMaterialCreateModalOpen}
                onRequestClose={() => setReadingMaterialCreateModalOpen(false)}
                user={user}
                initialSubjects={syllabusFilter.subjects}
                initialSelectedSubject={syllabusFilter.selectedSubjects.length > 0 ? syllabusFilter.selectedSubjects[0] : {}}
                initialGrades={syllabusFilter.grades}
                initialSelectedGrade={syllabusFilter.selectedGrades.length > 0 ? syllabusFilter.selectedGrades[0] : {}}
                initialSelectedTopics={syllabusFilter.selectedTopics}
            />

        </div>
    );
};

export default ReadingMaterialCardListFilter;