import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ADMIN_USER_TYPE } from '../../services/UserService';
import RecordedVideoCardList from '../Videos/VideoCard/RecordedVideoCardList';

const SyllabusRecordedVideos = ({ syllabusFilter, user = ADMIN_USER_TYPE }) => {

    const [videoSearchFilter, setVideoSearchFilter] = useState(null);
    const [page, setPage] = useState(1);
    const { t } = useTranslation();

    const makeSearchFilter = () => {
        let selectedTopics = [];
        if (!syllabusFilter.topics || !syllabusFilter.mainTopics) {
            return {};
        }
        if (syllabusFilter.selectedTopics.length > 0) {
            selectedTopics = selectedTopics.concat(syllabusFilter.selectedTopics);
        } else if (syllabusFilter.selectedMainTopics.length > 0) {
            selectedTopics = selectedTopics.concat(syllabusFilter.selectedMainTopics);
        }
        return {
            subjects: syllabusFilter.subjects,
            selectedSubjects: syllabusFilter.selectedSubjects,
            grades: syllabusFilter.grades,
            selectedGrades: syllabusFilter.selectedGrades,
            topics: syllabusFilter.topics.concat(syllabusFilter.mainTopics),
            selectedTopics: selectedTopics
        }
    }

    useEffect(() => {
        setVideoSearchFilter(makeSearchFilter());
    }, [syllabusFilter]);
    if (!videoSearchFilter) {
        return;
    }

    return (
        <RecordedVideoCardList
            syllabusFilter={videoSearchFilter}
            user={user}
            page={page}
            setPage={setPage} />
    );
};

export default SyllabusRecordedVideos;
