import React, { useEffect, useState, useRef } from 'react';

import './Video.css';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import VideoViewer from './VideoViewer'


const VideoViewerPopup = ({ isOpen, onRequestClose, videoType, videoId, defaultApproverId = null, inputVideoData = null, startSegmentId = null, videoTitle = '', user = ADMIN_USER_TYPE }) => {

    return (
        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform translate-y-8 rounded-lg bg-white px-4 pb-2 pt-2 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg md:max-w-2xl lg:max-w-4xl sm:px-4 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        style={{ maxHeight: '85vh', overflowY: 'auto' }}

                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        <VideoViewer
                            videoType={videoType}
                            videoId={videoId}
                            videoTitle={videoTitle}
                            defaultApproverId={defaultApproverId}
                            inputVideoData={inputVideoData}
                            startSegmentId={startSegmentId}
                            user={user}
                            onRequestClose={onRequestClose} />

                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    );
};

export default VideoViewerPopup;