import React from 'react'
import { useState, useEffect } from "react";

import { STUDENT_USER_TYPE } from '../../services/UserService';
import { useParams } from 'react-router-dom';
import NTATestView from '../../Components/Test/NTA/NTATestView';
import { TEST_CREATE_TYPE_VIRTUAL } from '../../services/TestService';

const StudentVirtualTest = () => {
    const { testId } = useParams();
    return (
        <>
            <NTATestView id={parseInt(testId)} user={STUDENT_USER_TYPE} testCreateType={TEST_CREATE_TYPE_VIRTUAL} />
            {/* <Footer user={STUDENT_USER_TYPE} /> */}
        </>
    )
}

export default StudentVirtualTest;