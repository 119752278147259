import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Latex from 'react-latex';

import { ChevronLeft, ChevronRight } from "react-feather";
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';


import QuestionSlide from './QuestionSlide';
import ParagraphSlide from './ParagraphSlide';

import { securedFetchQuestionById } from '../../../../services/QuestionService';
import { UnauthorizedError } from '../../../../services/Errors';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';

const QuestionSlider = ({ type, questionTypes = null, questionIds, questionIdIndex = 0, setQuestionIdIndex, onQuestionSlideEnd, showImages = false, showAnswers = false, user = ADMIN_USER_TYPE, onQuestionDelete = null }) => {

    const [curr, setCurr] = useState(questionIdIndex);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // Minimum distance (in pixels) to consider a swipe gesture
    const minSwipeDistance = (window.innerWidth > 500) ? (window.innerWidth / 4) : 100;

    const onTouchStart = (e) => {
        setTouchEnd(null); // Reset touch end to null on every new touch start
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            next();
        } else if (isRightSwipe) {
            prev();
        }
    }

    const prev = () =>
        setCurr((curr) => (curr === 0 ? questionIds.length - 1 : curr - 1));
    const next = () =>
        setCurr((curr) => (curr === questionIds.length - 1 ? 0 : curr + 1));

    const renderQuestionSlide = (id, index) => {
        const currentType = questionTypes ? questionTypes[index] : type;
        if (currentType === 'standalone') {
            return (<QuestionSlide questionId={id} showImages={showImages} showAnswers={showAnswers} user={user} onQuestionDelete={onQuestionDelete} />);
        }
        return (<ParagraphSlide paragraphId={id} showImages={showImages} showAnswers={showAnswers} user={user} onParagraphDelete={onQuestionDelete} />);
    }

    return (

        <div className="flex flex-col justify-center items-center gap-2 w-full p-2">

            {questionIds.map((id, index) => (
                curr === index && (
                    <div key={index}
                        className="w-full relative bg-[var(--whiteColor)] rounded-xl border-1 border-blue-500 pt-4 px-16 pb-12 
                            flex flex-col justify-center items-center transition-transform ease-out duration-500"
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}>

                        <div>
                            {/* Slider Controls */}
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full flex items-center justify-between">

                                {/* Previous Button */}
                                <button className="rounded-full border-0 p-4 bg-white text-blue-500 transition-colors duration-300 hover:bg-green-50 hover:text-green-500 cursor-pointer"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        prev();
                                    }}
                                >
                                    <ChevronLeftIcon className="w-8 h-8 md:w-10 md:h-10 pr-1" />
                                </button>
                                <button className="rounded-full border-0 p-4 bg-white text-blue-500 transition-colors duration-300 hover:bg-green-50 hover:text-green-500 cursor-pointer"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        next();
                                    }}
                                >
                                    <ChevronRightIcon className="w-8 h-8 md:w-10 md:h-10 pl-1" />
                                </button>
                            </div>

                            <div className="absolute w-full bottom-4 left-0 right-0">
                                <div className="flex items-center justify-center gap-1">
                                    {questionIds.map((_, i) => (
                                        <div key={i}
                                            className={`transition-all duration-300 ease w-2 h-2 bg-[var(--PrimaryColor)] rounded-full opacity-50 ${curr === i ? "p-[2px] opacity-100" : ""} `} />
                                    ))}
                                </div>
                            </div>
                        </div>

                        {renderQuestionSlide(id, index)}
                    </div>
                )
            ))
            }

        </div >
    );
};

export default QuestionSlider;
