import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { UnauthorizedError } from '../../services/Errors';
import { securedFetchBatchesByFilter, securedFetchBatchesByGrade, securedFetchGrades } from '../../services/BatchService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { getUniqueValues } from "../../services/CommonService";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import Dropdown from '../Common/Tailwind/SelectMenus/Dropdown';

const MultiGradeBatchFilter = ({ grades, selectedGrades, batches, selectedBatches,
    setGrades, setSelectedGrades, setBatches, setSelectedBatches,
    onSelectedBatchesToggle, prefillGrades = false, user = ADMIN_USER_TYPE,
    setCreateUserOpen, showTitle = false }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        return gradesResponse.data;
    };


    useEffect(() => {
        (async () => {
            const fetchedGrades = await fetchAndSetGrades();
            if (fetchedGrades === null) {
                return;
            }
            const parsedGrades = fetchedGrades.map((item) => ({ id: item.grade, grade_name: String(item.grade) }))
            setGrades(parsedGrades);
            const batchFilter = {};
            if (selectedGrades.length > 0) {
                batchFilter['grades'] = selectedGrades.map(grade => grade.id);
                setSelectedGrades(parsedGrades.filter(grade => batchFilter['grades'].includes(grade.id)));
            }
            const allBatches = await securedFetchBatchesByFilter(batchFilter, navigateCallbackOptions(navigate));
            if (allBatches === null) {
                return;
            }
            setBatches(allBatches.data);
            const selectedBatchIds = selectedBatches.map(batch => batch.id);
            const currentSelectedBatches = allBatches.data.filter(batch => selectedBatchIds.includes(batch.id))
            setSelectedBatches(currentSelectedBatches);
            if (selectedGrades.length === 0 && prefillGrades) {
                const currentBatchGradeIds = getUniqueValues(currentSelectedBatches.map(batch => batch.batch_grade));
                setSelectedGrades(parsedGrades.filter(grade => currentBatchGradeIds.includes(grade.id)));
                const batchesResponse = await securedFetchBatchesByFilter({ grades: currentBatchGradeIds }, navigateCallbackOptions(navigate));
                if (batchesResponse === null) {
                    return;
                }
                setBatches(batchesResponse.data);
            }
        })();
    }, []);

    const onSelectedGradesToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedGradesFromSelect = grades.filter(grade => selectedIds.includes(grade.id));
        const batchesResponse = await securedFetchBatchesByFilter({ grades: selectedGradesFromSelect.map(grade => grade.id) }, navigateCallbackOptions(navigate));
        if (batchesResponse === null) {
            return;
        }
        const previousSelectedBatchIds = selectedBatches.map(batch => batch.id);
        setBatches(batchesResponse.data);
        setSelectedBatches(batchesResponse.data.filter(batch => previousSelectedBatchIds.includes(batch.id)));
        setSelectedGrades(selectedGradesFromSelect);
    };

    return (

        <div className="w-full flex flex-col justify-center items-center gap-2 md:gap-4 z-2">

            {showTitle &&
                <h3 className="mt-2 text-lg font-semibold leading-6 text-[var(--SecondaryColor)]">
                    {t('selectGradeBatch')}
                </h3>
            }

            <div className="mt-2 flex flex-col sm:flex-row items-center justify-center gap-4 md:gap-6 lg:gap-8 w-full">

                {/* Select Grade */}
                <Dropdown
                    list={grades}
                    selected={selectedGrades}
                    onSelectionChange={onSelectedGradesToggle}
                    label={t('grade')}
                    nameField='grade_name'
                    valueField='id'
                    required={true}
                    multiple={true}
                    // isAddable={true}
                    className='flex items-center justify-center gap-2 min-w-48'
                />

                {/* Select Batch */}
                <Dropdown
                    list={batches}
                    selected={selectedBatches}
                    onSelectionChange={onSelectedBatchesToggle}
                    label={t('batch')}
                    nameField='batch_name'
                    valueField='id'
                    required={true}
                    multiple={true}
                    isAddable={true}
                    className='flex items-center justify-center gap-2 min-w-48'
                />

                {/* Create Student*/}
                <button onClick={() => setCreateUserOpen(true)}
                    data-tooltip-id="tooltip-create-new-user"
                    data-tooltip-content={t('registerNewStudent')}
                    data-tooltip-place="right">
                    <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                </button>
            </div>
        </div>
    )
};

export default MultiGradeBatchFilter;