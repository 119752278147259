import React from 'react';

import { TrashIcon } from '@heroicons/react/24/outline'

const ImagePreview = ({ images, selectImages, imageFileNames }) => {

    if (images.length < 1) return;

    const onDelete = (image) => (event) => {
        selectImages(images.filter((e) => e !== image));
    }

    return (
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8" value={images} >
            {images &&
                images.map((Img, Idx) => {
                    return (
                        <li key={Img} className="relative">
                            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">

                                <img className="pointer-events-none object-contain group-hover:opacity-80" src={Img} alt="upload" />
                                <button className="absolute inset-0 opacity-0 group-hover:opacity-100 focus:outline-none flex items-center justify-center" onClick={onDelete(Img)}>
                                    <TrashIcon className="h-7 w-7 text-[var(--PrimaryColor)]" />
                                </button>
                            </div>

                            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{Idx + 1} </p>

                            {imageFileNames.length > 0 && (
                                <p className="pointer-events-none block text-sm font-medium text-gray-500 truncate">{imageFileNames[Idx].name}</p>
                            )}
                        </li>
                    );
                })}
        </ul >
    )
}

export default ImagePreview;
