import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'
import Modal from "react-modal";

import Spinner from '../../Common/Tailwind/Spinner';
import TestSectionDelete from './TestSection/TestSectionDelete';
import SelectionDropdown from '../../Common/SelectionDropdown';
import TestSectionUpsert from './TestSection/TestSectionUpsert';
import TestSectionRender from './TestSection/TestSectionRender';
import { useTest } from '../TestContext';

import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { canCreateTestSection, canCreateTestSubject, securedAddTestQuestions, securedCreateTestSectionForTestId, securedDeleteTestQuestion, securedDeleteTestSection, securedDeleteTestSubject, securedFetchTestById, securedFetchTestSectionById, securedFetchTestSectionsByTestId, securedFetchTestSubjectById, securedUpdateTestSections, securedUpdateTestSubjects } from '../../../services/TestService';
import { securedFetchQuestionById, securedFetchQuestions } from '../../../services/QuestionService';
import { securedFetchSubjects } from '../../../services/SyllabusService';
import { UnauthorizedError } from '../../../services/Errors';

import { MdAddCircleOutline } from 'react-icons/md';
import { AiOutlineLeft, AiOutlineRight, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { ChevronLeftIcon, ChevronRightIcon, TrashIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

const TestSubjectRender = ({ testId, testStatus, user = ADMIN_USER_TYPE, subjects, testSubject, testType }) => {
    const {
        testSubjects,
        setTestSubjects,
        testSections,
        setTestSections,
        testQuestions,
        setTestQuestions,
        testParagraphs,
        setTestParagraphs,
    } = useTest();
    const [isTestSectionDeleteOpen, setTestSectionDeleteOpen] = useState(false);
    const [isCreateTestSection, setCreateTestSection] = useState(false);
    const [isEditTestSection, setEditTestSection] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const tabsRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        setActiveSection(testSections.find(testSection => testSection.test_subject_id === testSubject.id) || null);
    }, [testSubject.id])


    const sectionsForSubject = testSections.filter((testSection) => (testSection.test_subject_id === testSubject.id));

    const getNextSectionOrder = (subjectId) => {
        return testSections.filter((testSection) => (testSection.test_subject_id === subjectId)).length + 1;
    }

    const addSection = async (testSubjectId, newSectionId) => {
        const newSection = await securedFetchTestSectionById(testId, testSubjectId, newSectionId, navigateCallbackOptions(navigate));
        if (newSection === null) {
            return;
        }
        newSection.questions = null;
        newSection.paragraphs = null;
        setTestSections([...testSections, newSection]);
        setCreateTestSection(false);
        setActiveSection(newSection);
    };

    const deleteSection = async (testSectionId) => {
        const testSubjectId = testSections.find((testSection) => testSection.id === testSectionId).test_subject_id;
        const updatedSections = testSections.filter((testSection) => (testSection.id !== testSectionId))
        await updateTestSectionOrder(testId, testSubjectId, updatedSections);
        const orderedUpdatedSections = updatedSections.map((entry, index) => ({ ...entry, section_order: index }));
        setTestSections(updatedSections);
        setTestQuestions(testQuestions.filter((testQuestion) => (testQuestion.test_section_id !== testSectionId)));
        setTestParagraphs(testParagraphs.filter((testParagraph) => (testParagraph.test_section_id !== testSectionId)));
        setTestSectionDeleteOpen(false);
        setActiveSection(null);
    }

    const updateTestSectionOrder = async (testId, testSubjectId, updatedTestSections) => {
        const orderList = updatedTestSections.map((testSection, index) => ({ id: testSection.id, section_order: index }));
        const response = await securedUpdateTestSections(testId, testSubjectId, orderList, navigateCallbackOptions(navigate));
    }

    const onEditSection = async () => {
        const testSectionRespone = await securedFetchTestSectionById(testId, activeSection.test_subject_id, activeSection.id, navigateCallbackOptions(navigate));
        if (testSectionRespone === null) {
            return;
        }
        setTestSections([...testSections.filter(testSectionEntry => testSectionEntry.id !== activeSection.id), testSectionRespone].sort((a, b) => a.section_order - b.section_order));
        setEditTestSection(false);
    }

    const renderTestSection = (testType) => {

        {/* Create Section */ }
        if (isCreateTestSection) {
            return (
                <TestSectionUpsert
                    onUpsertSection={async (sectionId) => await addSection(testSubject.id, sectionId)}
                    testId={testId}
                    getOrder={() => getNextSectionOrder(testSubject.id)}
                    testSubjectId={testSubject.id}
                    handleCancel={setCreateTestSection} />
            );
        }

        if (activeSection === null && testStatus === "NOT STARTED") {
            return (
                <div className="testSection flex">
                    <h3 className="sectionTitle">Add Section</h3>
                </div>
            );
        }

        {/* Edit Section */ }
        if (isEditTestSection) {
            return (
                <TestSectionUpsert
                    testId={testId}
                    testSubjectId={activeSection.test_subject_id}
                    testSectionData={activeSection}
                    onUpsertSection={onEditSection}
                    handleCancel={setEditTestSection} />
            );
        }
        return (
            <div className="testSection flex" draggable>
                <TestSectionRender
                    testId={testId}
                    testStatus={testStatus}
                    user={user}
                    subjects={subjects}
                    testSection={activeSection}
                    testType={testType} />
            </div>
        );
    };

    const handleTabClick = (testSectionId) => {
        if (testSectionId === null) {
            setActiveSection(null)
        } else {
            setActiveSection(testSections.find(testSection => testSection.id === testSectionId) || null);
        }
    };

    const scrollTabs = (direction) => {
        if (tabsRef.current) {
            const { current } = tabsRef;
            const scrollAmount = direction === 'left' ? -current.offsetWidth / 3 : current.offsetWidth / 3;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className="w-full flex flex-col">
            <div className="flex justify-between items-center rounded-md overflow-hidden">
                <button className="text-white bg-[var(--PrimaryBkg)] flex justify-center items-center border-0 gap-1"
                    onClick={() => scrollTabs('left')}>
                    <ChevronLeftIcon className="w-7 h-7 p-1" />
                </button>
                <div className="flex flex-1 flex-start items-center overflow-x-auto scrollbar-hide h-7 bg-[var(--PrimaryBkg)]"
                    draggable ref={tabsRef}>
                    {/* Render Subject */}
                    {testSections.filter(testSection => testSection.test_subject_id === testSubject.id).map((testSection, index) => (
                        // Render Section
                        <button
                            className={`text-white flex justify-center items-center border-0 gap-1 h-7 px-2 py-1
                                        ${(activeSection !== null && activeSection.id === testSection.id) ? 'bg-[var(--PrimaryColor)]' : 'bg-[var(--PrimaryBkg)]'
                                }`}
                            key={testSection.subject_id}
                            onClick={() => handleTabClick(testSection.id)}
                        >
                            {'SEC' + (index + 1)}
                            {canCreateTestSection(testType) && testStatus === 'NOT STARTED' && (
                                <>
                                    {/* Delete Section */}
                                    <AiOutlineDelete
                                        className="icon delete-section"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent click from bubbling to the button element
                                            setTestSectionDeleteOpen(true);
                                        }}
                                        data-tooltip-id="tooltip-delete"
                                        data-tooltip-content="Delete the section"
                                        data-tooltip-place="right"
                                    />
                                    {isTestSectionDeleteOpen && (
                                        <TestSectionDelete
                                            isOpen={isTestSectionDeleteOpen}
                                            onRequestClose={() => setTestSectionDeleteOpen(false)}
                                            onDeleteSection={deleteSection}
                                            testId={testId}
                                            testSubjectId={testSection.test_subject_id}
                                            testSectionId={testSection.id} />
                                        // <Modal className="deletePopup flex">
                                        //     <p className="msg">Are you sure to DELETE section?</p>
                                        //     <div className="buttons flex">
                                        //         <button className="btn flex" onClick={async () => {
                                        //             await deleteSection(testSection.id);
                                        //             togglePopup(); // Optionally hide popup after action
                                        //         }}>Yes</button>
                                        //         <button className="btn flex" onClick={togglePopup}>Cancel</button>
                                        //     </div>
                                        // </Modal>
                                    )}
                                    {/* Edit Section */}
                                    <AiOutlineEdit
                                        className="icon edit-section"
                                        onClick={() => {
                                            setEditTestSection(true); // Assume this toggles some editing state
                                        }}
                                        data-tooltip-id="tooltip-edit"
                                        data-tooltip-content="Edit the section"
                                        data-tooltip-place="right"
                                    />
                                </>
                            )}
                        </button>
                    ))}
                    {/* Create Subject */}
                    {canCreateTestSection(testType) && testStatus === 'NOT STARTED' &&
                        <button className={activeSection === null ? "tabItem selected" : "tabItem"}
                            onClick={() => (
                                setCreateTestSection(true),
                                handleTabClick(null)
                            )} >
                            <PlusCircleIcon
                                className="w-7 h-7 p-1"
                                data-tooltip-id="tooltip-edit-test-section"
                                data-tooltip-content="Add the section"
                                data-tooltip-place="right" />
                        </button>
                    }
                    <Tooltip id="tooltip-edit-test-section" />
                </div>

                <button className="text-white bg-[var(--PrimaryBkg)] flex justify-center items-center border-0 gap-1"
                    onClick={() => scrollTabs('right')}>
                    <ChevronRightIcon className="w-7 h-7 p-1" />
                </button>
            </div >

            <div className="flex flex-col justify-start items-start w-full h-full">

                {/* Render Section */}
                {renderTestSection(testType)}

            </div>
        </div>
    )
}

export default TestSubjectRender