import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import './testSubject.css'

import { useTest } from '../TestContext';
import SelectionDropdown from '../../Common/SelectionDropdown';
import { UnauthorizedError } from '../../../services/Errors';
import { securedCreateTestSections, securedCreateTestSubjects } from '../../../services/TestService';
import { securedFetchSubjects } from '../../../services/SyllabusService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { BiMessageSquareAdd } from 'react-icons/bi';
import { render } from '@fullcalendar/core/preact.js';
import Dropdown from '../../Common/Tailwind/SelectMenus/Dropdown';

const AutoTestSubjectCreate = ({ isOpen, onRequestClose, allowedSubjects, onAddSubject = null, user = ADMIN_USER_TYPE }) => {
    const [selectedSubject, setSelectedSubject] = useState({});
    const { t } = useTranslation();

    const onSelectedSubjectChange = (selection) => {
        const subjectId = selection ? selection.value : null;
        if (subjectId) {
            setSelectedSubject({ id: subjectId, subject_name: selection.label });
        }
    }

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        const allowedSubjectIds = allowedSubjects.map(subject => subject.id);
        if (selectedSubject.id && allowedSubjectIds.includes(selectedSubject.id)) {
            return;
        }
        setSelectedSubject({});
    }, [isOpen]);

    const handleSubmit = () => {
        onAddSubject(selectedSubject);
        onRequestClose();
    }

    return (
        <>

            {isOpen &&

                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onRequestClose={onRequestClose}>

                    <div className="bg-white rounded-lg shadow-lg w-full max-w-md flex flex-col justify-center items-center p-6 gap-4">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">
                            {t('addTestSubject')}
                        </h3>

                        <div className="flex items-center justify-start gap-2 w-full">
                            <label className="flex justify-center items-center text-sm font-medium leading-6 text-gray-900 p-2 mr-1">
                                {t('subject')}
                            </label>
                            <Dropdown
                                className='flex items-center justify-start gap-2 w-full'
                                list={allowedSubjects}
                                selected={selectedSubject}
                                onSelectionChange={onSelectedSubjectChange}
                                valueField="id"
                                nameField="subject_name"
                                required={true}
                            />

                        </div>

                        <div className="flex justify-end space-x-4">
                            <button
                                className="px-4 py-2 bg-[var(--PrimaryColor)] text-white rounded hover:bg-[var(--SecondaryColor)]"
                                onClick={handleSubmit}>
                                {t('add')}
                            </button>
                            <button
                                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                                onClick={onRequestClose}>
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </div >
            }

        </>
    );
}

export default AutoTestSubjectCreate;
