import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import BatchTimetableFullCalendar from './BatchTimetableFullCalendar';

import { seureAdminFetchSlotsByBatchBetween, securedFetchSlots } from '../../services/BatchService';
import { ServercError, UnauthorizedError } from '../../services/Errors';
import { getDateString, getEndDateOfNextMonth, getStartDateOfLastMonth } from '../../services/DateService';
import { getEventsListFromTimetable } from '../../services/TimetableService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';

const BatchView = ({ user = STUDENT_USER_TYPE }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [eventsList, setEventsList] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: searchParams.get('startDate') || null,
        endDate: searchParams.get('endDate') || null
    });
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const fetchEventsList = async () => {
        if (!dateRange.startDate || !dateRange.endDate) {
            return;
        }
        const startTime = `${dateRange.startDate} 00:00:00`;
        const endTime = `${dateRange.endDate} 23:59:59`;
        const filter = {
            start_time: startTime,
            end_time: endTime
        }
        const response = await securedFetchSlots(filter, navigateCallbackOptions(navigate));
        if (response !== null) {
            setEventsList(getEventsListFromTimetable(response.data));
        }
    }
    useEffect(() => {
        (async () => {
            await fetchEventsList();
        })();
    }, [dateRange]);

    const onScheduleChange = async () => {
        await fetchEventsList();
    }
    useEffect(() => {
        (async () => {
            const searchStartDate = searchParams.get('startDate');
            const searchEndDate = searchParams.get('endDate');
            if (dateRange.startDate === searchStartDate && dateRange.endDate === searchEndDate) {
                return;
            }
            if (searchStartDate !== dateRange.startDate || searchEndDate !== dateRange.endDate) {
                setDateRange({
                    startDate: searchStartDate,
                    endDate: searchEndDate
                });
            }
        })();
    }, [location]);

    return (

        <div id="main-page-root" className="relative w-full h-full flex flex-col items-center gap-4 text-[var(--textColor)] animate-fadeUp">

            {/* Page Header */}
            {/* {user === STUDENT_USER_TYPE ? (
                <PageHeader pageHeaderTexr={t('engageAndLearnThroughClassroomInteractionWithJEETA')} />
            ) : (
                <PageHeader pageHeaderTex={t('transformClassroomsViaDynamicInteractionWithJEETA')} />
            )} */}

            <BatchTimetableFullCalendar eventsList={eventsList} user={user} dateRange={dateRange} setDateRange={setDateRange} onScheduleCreate={onScheduleChange} />

        </div>
    )
}

export default BatchView;