import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './Timer.css'; // Make sure to create this CSS file
import { hasTestEditAuthority } from '../../services/TestService';

function Timer({ editEnable, status, setTestStatus, setDuration, start, duration, remainingTime, timerType }) {
    const [isActive, setActive] = useState(false);
    const [seconds, setSeconds] = useState(0); // Let's assume a 5-minute timer for example
    const { t } = useTranslation();

    useEffect(() => {
        let interval = null;

        if (status === 'STARTED') {
            setActive(true);
            setSeconds(remainingTime);
            // setInputTime(duration * 60);

        } else {
            setActive(false);
            setSeconds(duration * 60);
        }

        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
                if (seconds === 1) {
                    setTestStatus('FINISHED');
                }
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, remainingTime]);

    const formatTime = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor(seconds % 3600 / 60);
        const secondsLeft = seconds % 60;
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`;
    };

    const progress100Percentage = () => {
        const percentage = Math.floor((seconds / duration * 60) * 100);

        if (percentage > 50) return "";
        else {
            if (percentage > 10) return "half";
            else return "little";
        }
    }

    const ProgressRing = (progress = 100) => {
        const radius = 100;
        const strokeWidth = 100;
        // const progress = 75;
        const strokeColor = 'green-400';

        const normalizedRadius = radius - strokeWidth / 2;
        const circumference = normalizedRadius * 2 * Math.PI;
        const strokeDashoffset = circumference - (progress / 100) * circumference;

        return (
            <svg
                // height={radius * 2}
                // width={radius * 2}
                viewBox="0 0 200 200"
                className="flex justify-center items-center w-full h-full transform -rotate-90 origin-center"
            >
                <circle
                    cx={radius}
                    cy={radius}
                    r={normalizedRadius}
                    className="fill-transparent transition-[stroke-dashoffset] duration-[350ms]"
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                />
            </svg>
        );
    };

    return (
        <div>
            {timerType === "NTA" ? (
                <div className={`${progress100Percentage()} == "half" ? text-orange-500 
                : ${progress100Percentage()} == "little" ? text-red-500 
                : text-gray-500`}>
                    {formatTime()}
                </div>
            ) : (
                <div className={`flex flex-col items-center justify-center 
                ${status === 'STARTED' ? "bg-white border-0 shadow-lg rounded-full fixed top-16 right-1 z-20" : ""}`}>
                    <div className="relative w-32 h-32 flex justify-center items-center">
                        <ProgressRing progress={progress100Percentage()} />
                        <svg className="flex justify-center items-center" width="100%" height="100%" viewBox="0 0 200 200">
                            <circle
                                className={`progress-ring__circle ${progress100Percentage()}`}
                                r="90"
                                cx="100"
                                cy="100"
                                style={{
                                    strokeDasharray: `565.48`,
                                    strokeDashoffset: status === 'STARTED' ? `${565.48 - (565.48 * seconds) / (duration * 60)}` : 0
                                }}
                            />
                        </svg>
                        {(editEnable && status === 'NOT STARTED') ? (
                            <div className="mt-1 gap-1 flex flex-col items-center justify-center absolute text-sm text-[var(--PrimaryColor)]">
                                <input name="inputTime"
                                    className="w-16 px-2 py-1 border-1 border-gray-100 rounded-md"
                                    type='number'
                                    placeholder={duration ? `${duration} ${t('minutes')}` : t('minutesCapital')}
                                    value={duration}
                                    onChange={(event) => { event.target.value !== '' ? setDuration(Math.max(0, event.target.value)) : setDuration('') }}
                                />

                                <button
                                    className="bg-red-300 hover:bg-orange-400 text-sm text-white font-semibold px-2 py-1 border-0 rounded-md cursor-pointer"
                                    onClick={start}>{t('startCapital')}
                                </button>
                            </div>
                        ) : (
                            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-base font-semibold text-[var(--textColor)]">{formatTime()}</div>
                        )}
                    </div>
                </div >
            )
            }
        </div>
    );
}

export default Timer;

