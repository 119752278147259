import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ADMIN_USER_TYPE } from '../../services/UserService';
import Spinner from '../Common/Tailwind/Spinner';
import MessagePage from '../Common/Tailwind/Page/MessagePage';
import LiveClassImg from '../../Assets/OnlineClass.png';
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';

const SlotLiveLectureVideo = ({ lectureData, meetingStatus, user = ADMIN_USER_TYPE }) => {

    const location = useLocation();
    const { t } = useTranslation();

    const handleOpenInNewTab = () => {
        const currentPath = location.pathname;
        let newRelativePath = `${currentPath}/live-stream`
        if (lectureData.class_type === 'online') {
            newRelativePath = `/zoom${currentPath}/meeting`;
        }
        const newUrl = `${window.location.origin}${newRelativePath}`;
        window.open(newUrl, '_blank');
    };

    const startMeeting = () => {
        handleOpenInNewTab();
    };

    if (lectureData == null || meetingStatus == null) {
        return <Spinner />;
    }

    if (meetingStatus === 'waiting' && lectureData.status === 'finished') {
        return (
            <div className="slotLectureVideo">
                <div className="videoPlayer flex">
                    <p className="no-video-msg"> Video has not been uploaded yet. </p>
                </div>
            </div>);
    }
    return (
        <div className="px-4 sm:px-6 lg:px-8">

            <div className="relative w-full flex flex-col justify-center items-center gap-2 sm:gap-3 md:gap-6 p-2 sm:p-4 md:p-6 lg:p-8">

                <MessagePage imgsrc={LiveClassImg} title={t('welcomeToLiveClassStreaming')} message1={""} message2={t('clickTheButton')} message3={""} />

                {/* <p className="live-class-msg">Live Class Streaming</p> */}
                <button
                    className="absolute top-0 right-0 flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                    onClick={(e) => {
                        e.preventDefault();
                        startMeeting();
                    }}
                >
                    <span>{t('joinLiveClass')}</span>
                    <ArrowRightStartOnRectangleIcon className="w-5 h-5" />
                </button>


                <div className="meetingSDKElement"></div>


            </div>

        </div>
    );
};

export default SlotLiveLectureVideo;
