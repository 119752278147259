import React, { useEffect, useRef, useState } from 'react';

import './testSubject.css'

import SelectionDropdown from '../../Common/SelectionDropdown';
import { ADMIN_USER_TYPE, securedFetchUsers } from '../../../services/UserService';

import { AiOutlineDelete, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { QUESTION_FILTER_SUBTYPES, QUESTION_UPLOAD_TYPES } from '../../../services/QuestionService';
import TestSectionMarkingSchemeUpsert from './TestSection/TestSectionMarkingSchemeUpsert';
import { MdAddCircleOutline } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { updateArray } from '../../../services/CommonService';

const AutoTestSectionsCreate = ({ activeSubjectId, sectionsData, setSectionsData, user = ADMIN_USER_TYPE }) => {

    const [activeSectionIndex, setActiveSectionIndex] = useState(null);
    const tabsRef = useRef(null);

    useEffect(() => {
        if (activeSubjectId != null) {
            setActiveSectionIndex(sectionsData.find(sectionData => sectionData.subject_id === activeSubjectId)?.index || null);
        }
    }, [activeSubjectId]);

    const handleTabClick = (e, sectionIndex) => {
        e.preventDefault();
        setActiveSectionIndex(sectionsData.find(sectionData => sectionData.index === sectionIndex)?.index || null);
    }

    const scrollTabs = (e, direction) => {
        e.preventDefault();
        if (tabsRef.current) {
            const { current } = tabsRef;
            const scrollAmount = direction === 'left' ? -current.offsetWidth / 3 : current.offsetWidth / 3;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const onAddSectionData = (e) => {
        e.preventDefault();
        const sectionIndex = sectionsData.length === 0 ? 1 : sectionsData[sectionsData.length - 1].index + 1;
        setSectionsData(prevSectionsData => [...prevSectionsData, { type: QUESTION_UPLOAD_TYPES[0], sub_type: QUESTION_FILTER_SUBTYPES[0], number_of_questions: 0, subject_id: activeSubjectId, full_marks: 0, negative_marks: 0, partial_marks: 0, index: sectionIndex }]);
        if (activeSectionIndex === null) {
            setActiveSectionIndex(sectionIndex);
        }
    }

    const onDeleteSectionData = (e, dataIndex) => {
        e.preventDefault();
        const index = sectionsData.findIndex(sectionData => sectionData.index === dataIndex);
        setSectionsData(prevSectionsData => [...prevSectionsData.slice(0, index), ...prevSectionsData.slice(index + 1, prevSectionsData.length)]);
        if (index === activeSectionIndex) {
            setActiveSectionIndex(null);
        }
    }

    const onSelectedTypeChange = (selection, dataIndex) => {
        const index = sectionsData.findIndex(sectionData => sectionData.index === dataIndex);
        const currentData = sectionsData[index];
        setSectionsData(prevSectionsData => updateArray(prevSectionsData, index, { ...currentData, type: { id: selection.value, name: selection.label } }));
    }

    const onSelectedSubTypeChange = (selection, dataIndex) => {
        const index = sectionsData.findIndex(sectionData => sectionData.index === dataIndex);
        const currentData = sectionsData[index];
        setSectionsData(prevSectionsData => updateArray(prevSectionsData, index, { ...currentData, sub_type: { id: selection.value, name: selection.label } }));
    }

    const onTextChange = (value, fieldName, dataIndex) => {
        const index = sectionsData.findIndex(sectionData => sectionData.index === dataIndex);
        const currentData = sectionsData[index];
        currentData[fieldName] = value;
        setSectionsData(prevSectionsData => updateArray(prevSectionsData, index, currentData));
    }

    const isPartialMarksEnabled = (sectionData) => {
        if (!sectionData) {
            return false;
        }
        return sectionData.type.id === 'multi choice' || (sectionData.type.id === 'paragraph' && sectionData.sub_type.id === 'multi choice')
    }

    const renderSection = (data) => {
        if (activeSectionIndex === null || data == null) {
            return (
                <div className="emptySubject flex">
                    <h3 className="sectionTitle">Add Section</h3>
                </div>
            );
        }
        return (
            <div className="autoTestSectionCreateContentsContainer flex">

                <div className="autoTestSectionInfo flex">
                    <div className="testInfoInput flex">
                        <label className="title">Number of Questions </label>
                        <input type="text" className="text" value={data.number_of_questions} onChange={(e) => onTextChange(e.target.value, 'number_of_questions', data.index)} placeholder="Number of Questions" />
                    </div>

                    <SelectionDropdown className="testSelectionDropdown" name="Question Type" itemList={QUESTION_UPLOAD_TYPES} selectedItem={data.type} onSelectionChange={(selection) => onSelectedTypeChange(selection, data.index)} nameField='name' valueField='id' />
                    {data.type && data.type.id === 'paragraph' && (
                        <SelectionDropdown className="testSelectionDropdown" name="Question Sub-Type" itemList={QUESTION_FILTER_SUBTYPES} selectedItem={data.sub_type} onSelectionChange={(selection) => onSelectedSubTypeChange(selection, data.index)} nameField='name' valueField='id' />)}
                </div>

                <TestSectionMarkingSchemeUpsert fullMarks={data.full_marks} setFullMarks={(input) => onTextChange(input, 'full_marks', data.index)} negativeMarks={data.negative_marks}
                    setNegativeMarks={(input) => onTextChange(input, 'negative_marks', data.index)} partialMarks={data.partial_marks} setPartialMarks={(input) => onTextChange(input, 'partial_marks', data.index)} enablePartialMarks={isPartialMarksEnabled(data)} user={user} />
            </div>
        );
    }

    return (
        <div className='testSectionsCreate'>
            <div className="testSectionCreateTabsContainer flex">
                <button className="left-btn" onClick={(e) => scrollTabs(e, 'left')}>
                    <AiOutlineLeft className="icon" />
                </button>
                <div className="testSectionTabs flex" draggable ref={tabsRef}>
                    {sectionsData.filter(section => section.subject_id === activeSubjectId).map((sectionData, index) => (
                        <button
                            className={(activeSectionIndex !== null && activeSectionIndex === sectionData.index) ? "tabItem selected" : "tabItem"}
                            key={sectionData.index}
                            onClick={(e) => handleTabClick(e, sectionData.index)}
                        >
                            {'SEC' + (index + 1)}
                            <AiOutlineDelete
                                className="icon delete-section"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent click from bubbling to the button element
                                    onDeleteSectionData(e, sectionData.index);
                                }}
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Delete this section"
                                data-tooltip-place="right"
                            />
                        </button>
                    ))}
                    <button className={activeSectionIndex === null ? "tabItem selected" : "tabItem"}
                        onClick={(e) => {
                            onAddSectionData(e);
                        }
                        } >
                        <MdAddCircleOutline
                            className="icon add-section"
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Add section"
                            data-tooltip-place="right" />
                    </button>

                    <Tooltip id="tooltip" />
                </div>

                <button className="right-btn" onClick={(e) => scrollTabs(e, 'right')}>
                    <AiOutlineRight className="icon" />
                </button>
            </div >

            <div className="testSectionCreateContentsContainer flex">

                {renderSection(sectionsData.find(sectionData => sectionData.index === activeSectionIndex))}

            </div>
        </div>);
}

export default AutoTestSectionsCreate;