import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

import Dropdown from '../../Common/Tailwind/SelectMenus/Dropdown';
import QuestionCreate from '../QuestionCreate/QuestionCreate';

import { QUESTION_DIFFICULTIES, QUESTION_FILTER_DROPDOWN_TYPES, QUESTION_UPLOAD_TYPES, hasQuestionCreateAuthority, hasQuestionResponses } from '../../../services/QuestionService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { hasGradeFetchAuthority, securedFetchGrades } from '../../../services/BatchService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';

import { securedFetchTagsByFilter } from '../../../services/TagService';
import { CheckCircleIcon, EyeSlashIcon, FunnelIcon, PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';

const QuestionCardListFilter = ({ syllabusFilter = null, searchFilter = null, testId = null, setSearchFilter, onSubmitFilter, allowedTypes = null, user = ADMIN_USER_TYPE, hiddenFilters = null, refreshKey = 0 }) => {

    const [isQuestionCreateModalOpen, setQuestionCreateModalOpen] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [types, setTypes] = useState(allowedTypes ? QUESTION_FILTER_DROPDOWN_TYPES.filter(qType => allowedTypes.map(aType => aType.id).includes(qType.id)) : QUESTION_FILTER_DROPDOWN_TYPES);
    const [selectedType, setSelectedType] = useState(allowedTypes ? QUESTION_FILTER_DROPDOWN_TYPES.find(qType => qType.id === allowedTypes[0].id) : QUESTION_FILTER_DROPDOWN_TYPES[0]);
    const [selectedDifficulties, setSelectedDifficulties] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [showUnattemptedOnly, setShowUnattemptedOnly] = useState(false);
    const [showIncorrectOnly, setShowIncorrectOnly] = useState(false);
    const [showStarredOnly, setShowStarredOnly] = useState(false);
    const [showMarkedForReviewOnly, setShowMarkedForReviewOnly] = useState(false);
    const [questionId, setQuestionId] = useState();
    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 780);
    const navigate = useNavigate();
    const { t } = useTranslation();


    const fetchSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        return subjectsResponse.data;
    };

    const fetchGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        return gradesResponse.data;
    };

    const onSelectedTopicToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTopicsFromSelect = topics.filter(topic => selectedIds.includes(topic.id));
        setSelectedTopics(selectedTopicsFromSelect);
    };

    const onSelectedTagToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(selectedTagsFromSelect);
    };

    const updateStatesFromFilter = async (filter) => {
        if (filter === null) {
            return;
        }
        if (filter.selectedSubjects.length > 0) {
            setSelectedSubjects(filter.selectedSubjects);
        }
        if (filter.selectedGrades.length > 0) {
            setSelectedGrades(filter.selectedGrades);
        }
        if (filter.topics.length > 0) {
            setTopics(filter.topics);
            if (filter.selectedTopics.length > 0) {
                setSelectedTopics(filter.selectedTopics);
            }
        }
        if (filter.selectedTags.length > 0) {
            setSelectedTags(filter.selectedTags);
        }
        if (filter.difficulties && filter.difficulties.length > 0) {
            setSelectedDifficulties(filter.selectedDifficulties);
        }
        if (filter.selectedType && filter.selectedType.id) {
            setSelectedType(filter.selectedType);
        }
        if (filter.queryString) {
            setQueryString(filter.queryString);
        }
        if (filter.showUnattemptedOnly) {
            setShowUnattemptedOnly(filter.showUnattemptedOnly);
        }
        if (filter.showStarredOnly) {
            setShowStarredOnly(filter.showStarredOnly);
        }
        if (filter.showMarkedForReviewOnly) {
            setShowMarkedForReviewOnly(filter.showMarkedForReviewOnly);
        }
        if (filter.showIncorrectOnly) {
            setShowIncorrectOnly(filter.showIncorrectOnly);
        }
        if (filter.questionId) {
            setQuestionId(filter.questionId);
        }
    }

    const updateFilterNullValues = async (lowerFilter, upperFilter) => {
        let filter = {};
        if (lowerFilter) {
            filter = { ...filter, ...lowerFilter };
        }
        if (upperFilter) {
            filter = { ...filter, ...upperFilter };
        }
        if (!filter.subjects) {
            filter["subjects"] = subjects;
        }
        if (!filter.selectedSubjects) {
            filter["selectedSubjects"] = selectedSubjects;
        }
        if (!filter.grades) {
            filter['grades'] = grades;
        }
        if (!filter.selectedGrades) {
            filter["selectedGrades"] = selectedGrades;
        }
        if (!filter.topics) {
            if (filter['selectedSubjects'].length > 0 || filter['selectedGrades'].length > 0) {
                const topicFilter = {};
                if (filter["selectedGrades"].length > 0) {
                    topicFilter["grades"] = filter["selectedGrades"].map(grade => grade.id);
                }
                if (filter["selectedSubjects"].length > 0) {
                    topicFilter["subject_ids"] = filter["selectedSubjects"].map(subject => subject.id);
                }
                const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate))
                if (topicsResponse === null) {
                    filter['topics'] = [];
                } else {
                    filter['topics'] = topicsResponse.data;
                }
            } else {
                filter["topics"] = topics;
            }
        }
        if (!filter.tags) {
            filter['tags'] = tags;
        }
        if (!filter.selectedTags) {
            filter['selectedTags'] = selectedTags;
        }
        if (!filter.selectedTopics) {
            filter["selectedTopics"] = selectedTopics;
        }
        if (!filter.difficulties) {
            filter["difficulties"] = QUESTION_DIFFICULTIES;
        }
        if (!filter.selectedDifficulties) {
            filter["selectedDifficulties"] = selectedDifficulties;
        }
        if (!filter.types) {
            filter["types"] = QUESTION_UPLOAD_TYPES;
        }
        if (!filter.selectedType) {
            filter['selectedType'] = selectedType;
        }
        if (!filter.queryString) {
            filter['queryString'] = queryString;
        }
        if (!filter.questionId >= 0) {
            filter['questionId'] = questionId;
        }
        if (!filter.showMarkedForReviewOnly) {
            filter['showMarkedForReviewOnly'] = showMarkedForReviewOnly;
        }
        if (!filter.showStarredOnly) {
            filter['showStarredOnly'] = showStarredOnly;
        }
        if (!filter.showUnattemptedOnly) {
            filter['showUnattemptedOnly'] = showUnattemptedOnly;
        }
        if (!filter.showIncorrectOnly) {
            filter['showIncorrect'] = showIncorrectOnly;
        }
        return filter;
    }

    const makeFilterFromStates = () => {
        return {
            subjects: subjects,
            selectedSubjects: selectedSubjects,
            grades: grades,
            selectedGrades: selectedGrades,
            topics: topics,
            selectedTopics: selectedTopics,
            tags: tags,
            selectedTags: selectedTags,
            difficulties: QUESTION_DIFFICULTIES,
            selectedDifficulties: selectedDifficulties,
            types: QUESTION_FILTER_DROPDOWN_TYPES,
            selectedType: selectedType,
            queryString: queryString,
            showUnattemptedOnly: showUnattemptedOnly,
            showIncorrectOnly: showIncorrectOnly,
            showStarredOnly: showStarredOnly,
            showMarkedForReviewOnly: showMarkedForReviewOnly,
            questionId: questionId,
        }
    }

    const makeTopicFilter = () => {
        const filter = {};
        if (selectedGrades.length > 0) {
            filter['grades'] = selectedGrades.map(grade => grade.id);
        } else {
            filter['grades'] = grades.map(grade => grade.id);
        }

        if (selectedSubjects.length > 0) {
            filter['subject_ids'] = selectedSubjects.map(subject => subject.id);
        } else {
            filter['subject_ids'] = subjects.map(subject => subject.id);
        }
        return filter;
    }

    const onSelectedDifficultyToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedDifficultiesFromSelect = QUESTION_DIFFICULTIES.filter(difficulty => selectedIds.includes(difficulty.id));
        setSelectedDifficulties(selectedDifficultiesFromSelect);
    };

    const onSelectedTypeChange = (selection) => {
        const typeId = selection ? selection.value : null;
        if (typeId) {
            setSelectedType(QUESTION_FILTER_DROPDOWN_TYPES.find(qType => qType.id === typeId));
        }
    }

    const onSelectedSubjectChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedSubjectsFromSelect = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjects(selectedSubjectsFromSelect);
        const topicFilter = makeTopicFilter();
        topicFilter['subject_ids'] = selectedSubjectsFromSelect.map(subject => subject.id);
        const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate));
        if (topicsResponse === null) {
            return;
        }
        setTopics(topicsResponse.data);
        const updatedSelectedTopics = selectedTopics.filter(selectedTopic =>
            topicsResponse.data.some(responseTopic => responseTopic.id === selectedTopic.id));

        setSelectedTopics(updatedSelectedTopics);
    };

    const onSelectedGradeChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedGradesFromSelect = grades.filter(grade => selectedIds.includes(grade.id));
        setSelectedGrades(selectedGradesFromSelect);
        const topicFilter = makeTopicFilter();
        topicFilter['grades'] = selectedGradesFromSelect.map(grade => grade.id);
        const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate));
        if (topicsResponse === null) {
            return;
        }
        setTopics(topicsResponse.data);
        const updatedSelectedTopics = selectedTopics.filter(selectedTopic =>
            topicsResponse.data.some(responseTopic => responseTopic.id === selectedTopic.id));
        setSelectedTopics(updatedSelectedTopics);
    };

    const handleSubmit = async () => {
        const externalFilter = makeFilterFromStates();
        setSearchFilter(externalFilter);
        await onSubmitFilter(false, externalFilter);
    }

    const handleShowAttemptedCheckChange = () => {
        if (!showUnattemptedOnly) {
            setShowIncorrectOnly(false);
        }
        setShowUnattemptedOnly(!showUnattemptedOnly);
    }

    const handleShowStarredCheckChange = () => {
        setShowStarredOnly(!showStarredOnly);
    }

    const handleShowMarkedForReviewCheckChange = () => {
        setShowMarkedForReviewOnly(!showMarkedForReviewOnly);
    }

    const handleShowIncorrectCheckChange = () => {
        if (!showIncorrectOnly) {
            setShowUnattemptedOnly(false);
        }
        setShowIncorrectOnly(prev => !prev);

    }

    const checkDisabled = (key) => {
        return hiddenFilters && hiddenFilters[key] === 'disable';
    }

    const updateBySyllabusFilter = () => {
        if (syllabusFilter === null) {
            return;
        }
        if (syllabusFilter.subjects && syllabusFilter.subjects.length > 0) {
            setSubjects(syllabusFilter.subjects);
            setSelectedSubjects(syllabusFilter.selectedSubjects);
        }
        if (syllabusFilter.grades && syllabusFilter.grades.length > 0) {
            setGrades(syllabusFilter.grades);
            setSelectedGrades(syllabusFilter.selectedGrades);
        }
        if (syllabusFilter.topics && syllabusFilter.topics.length > 0) {
            setTopics(syllabusFilter.topics);
            setSelectedTopics(syllabusFilter.selectedTopics);
        }

    }

    useEffect(() => {
        (async () => {
            if (!hiddenFilters || hiddenFilters.topics !== 'hide') {
                const subjectsResponse = await fetchSubjects();
                if (subjectsResponse === null) {
                    return;
                }
                setSubjects(subjectsResponse);

                const gradesResponse = await fetchGrades();
                if (gradesResponse === null) {
                    return;
                }
                setGrades(gradesResponse);
                const topicsResponse = await securedFetchTopicsByFilter({}, navigateCallbackOptions(navigate));
                if (topicsResponse === null) {
                    return;
                }
                setTopics(topicsResponse.data);

            }
            const tagFilter = user === ADMIN_USER_TYPE ? null : { is_hidden: false };
            const tagsResponse = await securedFetchTagsByFilter(tagFilter, navigateCallbackOptions(navigate));
            if (tagsResponse === null) {
                return;
            }
            setTags(tagsResponse.data);
            const currentSearchFilter = makeFilterFromStates();
            if (searchFilter === null && syllabusFilter === null) {
                const success = await onSubmitFilter(true, currentSearchFilter);
                if (!success) {
                    return;
                }
                setSearchFilter(currentSearchFilter);
            }
            else {
                const updatedSearchFilter = await updateFilterNullValues(searchFilter, syllabusFilter);
                await updateStatesFromFilter(updatedSearchFilter);
                const success = await onSubmitFilter(true, updatedSearchFilter);
                if (!success) {
                    return;
                }
                setSearchFilter(updatedSearchFilter);
            }
        })();
    }, []);


    useEffect(() => {
        updateBySyllabusFilter();
    }, [syllabusFilter]);

    useEffect(() => {
        const handleResize = () => {
            setIsNarrowScreen(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const renderDetailedQuestionFilter = () => {
        return (
            <div className="flex flex-col justify-center items-center w-full gap-2 md:gap-4" >
                <div className="w-full flex flex-col md:flex-row gap-2 md:gap-4">
                    {(!hiddenFilters || hiddenFilters.id !== 'hide') && (
                        <div className="flex w-full items-center gap-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                                {t('id')}
                            </label>
                            <input
                                className="block flex-1 rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                type="number"
                                value={questionId}
                                onChange={e => setQuestionId(e.target.value)}
                                placeholder={`${t('questionId')}...`}
                                disabled={checkDisabled('id')} />
                        </div >
                    )}
                    {(!hiddenFilters || hiddenFilters.types !== 'hide') && (
                        <Dropdown
                            className='flex items-center justify-center gap-2 w-full'
                            list={types}
                            selected={selectedType}
                            onSelectionChange={onSelectedTypeChange}
                            label={t('types')}
                            nameField='name'
                            valueField='id'
                            isDisabled={checkDisabled('types')} />
                        // <SelectionDropdown
                        //     className='filterSelect'
                        //     name={t('types')}
                        //     itemList={types}
                        //     onSelectionChange={onSelectedTypeChange}
                        //     selectedItem={selectedType}
                        //     nameField='name'
                        //     valueField='id'
                        //     isDisabled={checkDisabled('types')} />
                    )}

                    {(!hiddenFilters || hiddenFilters.difficulties !== 'hide') && (
                        <Dropdown
                            className='flex items-center justify-center gap-2 w-full'
                            list={QUESTION_DIFFICULTIES}
                            selected={selectedDifficulties}
                            onSelectionChange={onSelectedDifficultyToggle}
                            label={t('difficulties')}
                            nameField='name'
                            valueField='id'
                            isSearchable={true}
                            multiple={true}
                            isDisabled={checkDisabled('difficulties')} />

                        // <SelectionDropdownMultiple
                        //     className='filterSelect'
                        //     name={t('difficulties')}
                        //     itemList={QUESTION_DIFFICULTIES}
                        //     selectedItems={selectedDifficulties}
                        //     onSelectionToggle={onSelectedDifficultyToggle}
                        //     nameField='name'
                        //     valueField='id'
                        //     isDisabled={checkDisabled('difficulties')} />
                    )}
                </div>

                {(!hiddenFilters || (hiddenFilters.subjects !== 'hide' && hiddenFilters.topics !== 'hide')) && (
                    <Dropdown
                        className='flex items-center justify-center gap-2 w-full'
                        list={subjects}
                        selected={selectedSubjects}
                        onSelectionChange={onSelectedSubjectChange}
                        label={t('subjects')}
                        nameField='subject_name'
                        valueField='id'
                        isDisabled={checkDisabled('subjects')}
                    />
                )}

                {(!hiddenFilters || (hiddenFilters.grades !== 'hide' && hiddenFilters.topics !== 'hide')) && (
                    <Dropdown
                        className='flex items-center justify-center gap-2 w-full'
                        list={grades}
                        selected={selectedGrades}
                        onSelectionChange={onSelectedGradeChange}
                        label={t('grades')}
                        nameField='grade_name'
                        valueField='id'
                        multiple={true}
                        isDisabled={checkDisabled('grades')}
                    />
                )}

                {(!hiddenFilters || hiddenFilters.topics !== 'hide') && (
                    <Dropdown
                        className='flex items-center justify-center gap-2 w-full'
                        list={topics}
                        selected={selectedTopics}
                        onSelectionChange={onSelectedTopicToggle}
                        label={t('topics')}
                        nameField='topic_name'
                        valueField='id'
                        isSearchable={true}
                        multiple={true}
                        isDisabled={checkDisabled('topics')}
                    />
                )}

                {(!hiddenFilters || hiddenFilters.tags !== 'hide') && (
                    <Dropdown
                        className='flex items-center justify-center gap-2 w-full'
                        list={tags}
                        selected={selectedTags}
                        onSelectionChange={onSelectedTagToggle}
                        label={t('tags')}
                        nameField='tag_name'
                        valueField='id'
                        isSearchable={true}
                        multiple={true}
                        isDisabled={checkDisabled('tags')} />
                )}

                {(!hiddenFilters || hiddenFilters.text !== 'hide') && (
                    <div className="flex w-full items-center gap-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                            {t('text')}
                        </label>
                        <input type="text"
                            className="block flex-1 rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                            value={queryString}
                            onChange={e => setQueryString(e.target.value)}
                            placeholder="Question Text..."
                            disabled={checkDisabled('text')} />
                    </div>
                )}
            </div>
        )
    }

    const renderQuestionResponseFilter = () => {

        if (!hasQuestionResponses) {
            return null;
        }

        return (
            <fieldset className="absolute left-4 flex flex-col md:flex-row justify-start items-center gap-2 md:gap-4" >

                {!showIncorrectOnly && (
                    <div className="flex items-center gap-2">
                        <label className="block flex gap-1 items-center text-sm font-medium leading-6 text-green-500 whitespace-nowrap">
                            <span>{t('unattempted')}</span>
                            <EyeSlashIcon className="w-4 h-4" />
                        </label>
                        <input
                            className="w-4 h-4 rounded-sm border border-gray-300 bg-white checked:border-green-600 checked:bg-green-600 indeterminate:border-green-600 indeterminate:bg-green-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            type="checkbox"
                            checked={showUnattemptedOnly}
                            onChange={handleShowAttemptedCheckChange}
                        />
                    </div>)}

                <div className="flex items-center gap-2">
                    <label className="block flex gap-1 items-center text-sm font-medium leading-6 text-blue-500 whitespace-nowrap">
                        <span>{t('difficult')}</span>
                        <StarIcon className="w-4 h-4" />
                    </label>
                    <input
                        className="w-4 h-4 rounded-sm border border-gray-300 bg-white checked:border-blue-600 checked:bg-blue-600 indeterminate:border-blue-600 indeterminate:bg-blue-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                        type="checkbox"
                        checked={showStarredOnly}
                        onChange={handleShowStarredCheckChange}
                    />
                </div>

                <div className="flex items-center gap-2">
                    <label className="block flex gap-1 items-center text-sm font-medium leading-6 text-purple-500 whitespace-nowrap">
                        <span>{t('review')}</span>
                        <CheckCircleIcon className="w-4 h-4" />
                    </label>
                    <input
                        className="w-4 h-4 rounded-sm border border-gray-300 bg-white checked:border-purple-600 checked:bg-purple-600 indeterminate:border-purple-600 indeterminate:bg-purple-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                        type="checkbox"
                        checked={showMarkedForReviewOnly}
                        onChange={handleShowMarkedForReviewCheckChange}
                    />
                </div>

                {!showUnattemptedOnly && (
                    <div className="flex items-center gap-2">
                        <label className="block flex gap-1 items-center text-sm font-medium leading-6 text-red-500 whitespace-nowrap">
                            <span>{t('incorrect')}</span>
                            <XCircleIcon className="w-4 h-4" />
                        </label>
                        <input
                            className="w-4 h-4 rounded-sm border border-gray-300 bg-white checked:border-red-600 checked:bg-red-600 indeterminate:border-red-600 indeterminate:bg-red-600 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                            type="checkbox"
                            checked={showIncorrectOnly}
                            onChange={handleShowIncorrectCheckChange}
                        />
                    </div>)}
            </fieldset>
        )
    }

    const renderFilterButtons = () => {
        return (
            <div className="absolute right-4 flex justify-center items-center gap-4">
                <button className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                    onClick={handleSubmit}>
                    <span>{t('filter')}</span>
                    <FunnelIcon aria-hidden="true" className="h-4 w-4" />
                </button>
                {!hasQuestionCreateAuthority(user) || testId !== null ? (<></>) : (
                    <>
                        <span>{t('or')}</span>
                        <button className="flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                            onClick={() => setQuestionCreateModalOpen(true)} >
                            <span>{t('createCapitalized')}</span>
                            <PlusCircleIcon aria-hidden="true" className="h-4 w-4" />
                        </button>
                    </>
                )}
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-center items-center w-full gap-2 md:gap-4" >

            {/* Filter */}
            {renderDetailedQuestionFilter()}

            <div className="relative px-2 py-4 mb-4 w-full flex flex-col md:flex-row items-center gap-2 md:gap-4">
                {/* Question Response (Student Only) */}
                {renderQuestionResponseFilter()}

                {/* Filter Button */}
                {renderFilterButtons()}
            </div>

            {testId === null && (
                <QuestionCreate
                    isOpen={isQuestionCreateModalOpen}
                    onRequestClose={() => setQuestionCreateModalOpen(false)}
                    user={user}
                    initialSubjects={subjects}
                    initialSelectedSubject={selectedSubjects.length > 0 ? selectedSubjects[0] : {}}
                    initialGrades={grades}
                    initialSelectedGrade={selectedGrades.length > 0 ? selectedGrades[0] : {}}
                    initialSelectedTopics={selectedTopics}
                    initialSelectedType={QUESTION_UPLOAD_TYPES.find(uType => uType.id === selectedType.id) || QUESTION_UPLOAD_TYPES[0]}
                />)}

        </div >
    );
};

export default QuestionCardListFilter;