import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

import Spinner from '../../Common/Tailwind/Spinner';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import RecordedVideoCard from './RecordedVideoCard';
import RecordedVideoCardListFilter from './RecordedVideoCardListFilter';
import { needsVideoRequestApproval, securedFetchRecordedVideoByFilter, securedFetchVideoAccessRequestsByFilter } from '../../../services/VideoService';
import VideoViewer from '../../Files/Video/VideoViewer';
import VideoViewerPopup from '../../Files/Video/VideoViewerPopup';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const RecordedVideoCardList = ({ syllabusFilter = null, user = ADMIN_USER_TYPE, page = 1, setPage }) => {

    const [recordedVideos, setRecordedVideos] = useState([]);
    const [totalVideoCount, setTotalVideoCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [videoViewData, setVideoViewData] = useState(null);
    const [isVideoViewOpen, setVideoViewOpen] = useState(false);
    const [isNotificationOpen, setNotificationOpen] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const makeFilter = (currentPage, externalFilter = null) => {
        const currentSearchFilter = externalFilter ? externalFilter : syllabusFilter;
        const filter = { skip: (currentPage - 1) * 12, limit: 12 }
        if (!currentSearchFilter) {
            return filter;
        }
        if (currentSearchFilter.selectedTopics && currentSearchFilter.selectedTopics.length > 0) {
            filter['topic_ids'] = currentSearchFilter.selectedTopics.map((item) => (item.id));
        }
        else if ((currentSearchFilter.selectedSubjects && currentSearchFilter.selectedSubjects.length > 0) ||
            (currentSearchFilter.selectedGrades && currentSearchFilter.selectedGrades.length > 0)) {
            filter['topic_ids'] = currentSearchFilter.topics.map((item) => (item.id));
        }
        if (currentSearchFilter.queryString && currentSearchFilter.queryString.length > 0) {
            filter['query_string'] = currentSearchFilter.queryString;
        }
        if (currentSearchFilter.nameSubstring && currentSearchFilter.nameSubstring.length > 0) {
            filter['title_substring'] = currentSearchFilter.nameSubstring;
        }
        return filter;
    }

    const onCardClick = (videoData) => {
        setVideoViewData(videoData);
        setVideoViewOpen(true);
    }

    const renderVideoCard = (recordedVideoData) => {
        return <RecordedVideoCard
            recordedVideoData={recordedVideoData}
            onCardClick={onCardClick}
            user={user} />;
    }

    const renderVideoCards = () => {
        return (
            <>
                {recordedVideos.map(videoData => (
                    renderVideoCard(videoData)
                ))}
            </>
        )
    }

    const fetchData = async (hasFilterChanged = false, externalFilter = null, externalPage = null) => {
        let currentPage = externalPage == null ? page : externalPage;
        if (hasFilterChanged) {
            setPage(1);
            currentPage = 1;
        }
        setLoading(true);
        const currentSearchFilter = externalFilter ? externalFilter : syllabusFilter;
        const response = await securedFetchRecordedVideoByFilter(makeFilter(currentPage, currentSearchFilter), navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }

        if (!needsVideoRequestApproval(user)) {
            setTotalVideoCount(response.count);
            setRecordedVideos(response.data.map(r => ({ ...r, approval_status: 'approve' })));
            setLoading(false);
            return;
        }

        setTotalVideoCount(response.count);
        setRecordedVideos(response.data.map(r => {
            return { ...r, approval_status: ((needsVideoRequestApproval(user) && r.is_access_restricted) ? 'none' : 'approve') };
        }));
        setLoading(false);
    };

    const onPageChange = async (changeUnit) => {
        const numberOfPages = Math.ceil(totalVideoCount / 12);
        if (page + changeUnit < 1 || page + changeUnit > numberOfPages || changeUnit === 0) {
            return;
        }
        setPage(page + changeUnit);
        await fetchData(false, null, page + changeUnit)
    }

    const displayPageButtons = () => {
        const numberOfButtonsAfterPage = Math.max(0, Math.ceil((totalVideoCount - page * 12) / 12));
        const startIndex = Math.max(page - 5, 1);
        const endIndex = Math.min(startIndex + 10, page + numberOfButtonsAfterPage);

        return Array.from({ length: endIndex - startIndex + 1 }, (_, index) => (

            <button button key={startIndex + index}
                className={startIndex + index === page
                    ? "flex items-center justify-center w-7 h-7 rounded-full border-none text-white text-sm outline-none bg-[var(--SecondaryColor)] hover:bg-[var(--PrimaryColor)]"
                    : "flex items-center justify-center w-7 h-7 rounded-full border-none text-white text-sm outline-none bg-[var(--SecondaryBkg)] hover:bg-[var(--PrimaryColor)]"}
                onClick={() => onPageChange(startIndex + index - page)} >
                {startIndex + index}
            </button>

        ));
    }

    const onSubmitFilter = async (externalFilter = null) => {
        await fetchData(true, externalFilter);
    }

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, []);

    const renderCardList = () => {
        return (
            <div className="flex flex-col justify-center items-center gap-2 w-full p-2">

                <RecordedVideoCardListFilter
                    onSubmitFilter={onSubmitFilter}
                    syllabusFilter={syllabusFilter}
                    user={user} />

                {loading ? (
                    <Spinner />
                ) : (
                    <div className="w-full flex flex-col justify-center items-center gap-2 mx-auto">

                        {/* Card List Grid */}
                        <div className="-mx-px w-full grid grid-cols-2 border border-gray-200 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
                            {recordedVideos && renderVideoCards()}
                        </div>

                        {/* Card List Button Slider */}
                        <div className="w-full flex justify-center items-center gap-2 p-2">
                            <button className="bg-transparent text-[var(--PrimaryColor)] hover:text-[var(--SecondaryColor)]"
                                onClick={async () => await onPageChange(-1)} hidden={page === 1}>
                                <ChevronLeftIcon className="w-8 h-8" />
                            </button>

                            {displayPageButtons(totalVideoCount)}

                            <button className="bg-transparent text-[var(--PrimaryColor)] hover:text-[var(--SecondaryColor)]"
                                onClick={async () => await onPageChange(1)}>
                                <ChevronRightIcon className="w-8 h-8" />
                            </button>
                        </div>

                    </div>)}

                <VideoViewerPopup
                    isOpen={isVideoViewOpen}
                    isNotificationOpen={isNotificationOpen}
                    setNotificationOpen={setNotificationOpen}
                    onRequestClose={() => setVideoViewOpen(false)}
                    videoId={videoViewData?.id || null}
                    defaultApproverId={videoViewData?.upserted_user_id || null}
                    videoTitle={`${videoViewData?.title || ''}`}
                    user={user} />

            </div>
        )
    }

    return (
        <>
            {renderCardList()}
        </>
    );
};

export default RecordedVideoCardList;