import React from 'react';
import { useTranslation } from "react-i18next";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import VideoRequests from './VideoRequests';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { LockClosedIcon } from '@heroicons/react/24/outline';


const VideoRequestsPopup = ({ isOpen, onRequestClose, zIndex = 10, types = null, refresh = true, user = ADMIN_USER_TYPE }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onClose={onRequestClose} className={`relative z-${zIndex}`}>

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className={`fixed inset-0 z-${zIndex} w-screen overflow-y-auto`}>
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        transition
                        className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg md:max-w-2xl lg:max-w-3xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                <LockClosedIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    {t('checkAccessRequests')}
                                </DialogTitle>

                                <VideoRequests types={types} refresh={refresh} user={user} />
                            </div>
                        </div>

                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    );
};

export default VideoRequestsPopup;
