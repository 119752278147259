const MessagePage = ({
    imgsrc = "",
    title = "Page not found",
    message1 = "404",
    message2 = "Sorry, we couldn't find the page you're looking for",
    message3 = "Back to home" }) => {
    return (

        <div className="relative isolate flex flex-1 flex-col w-full h-full items-center justify-center">

            {/* Background Image */}
            <div
                style={{ backgroundImage: `url(${imgsrc})` }}
                className="absolute 
                    left-1/2 top-1/3 
                    transform -translate-x-1/2 -translate-y-1/2 
                    inset-0 w-full md:max-w-lg bg-center bg-cover bg-contain bg-no-repeat opacity-40 -z-10"
            />
            <div className="mx-auto max-w-7xl px-6 flex flex-col gap-4 text-center sm:py-20 lg:px-8">
                <p className="text-base font-semibold leading-8 text-gray-500">{message1}</p>
                <h1 className="mt-4 text-2xl font-bold tracking-tight text-gray-700 sm:text-4xl">{title}</h1>
                <p className="mt-4 text-base text-gray-500 sm:mt-6">{message2}</p>
                <div className="mt-10 flex justify-center">
                    {message3 &&
                        <a href="#" className="text-sm font-semibold leading-7 text-gray-600">
                            <span aria-hidden="true">&larr;</span> {message3}
                        </a>
                    }
                </div>
            </div>
        </div>
    )
}

export default MessagePage;
